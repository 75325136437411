<template>
  <v-app style="background-color: #f5f8ff" class="app">
    <v-container class="justify-center text-center">
      <v-card
        class="mx-auto my-12 pa-10 pt-0 pb-0"
        max-width="475"
        :loading="loadingLogin"
        light
        color="white"
      >
        <v-col v-if="cardIdx != 0">
          <v-card-title
              class="justify-center"
            >
              <v-btn
                fab
                text
                small
                style="margin-left: -40px"
                v-on:click="decrementCard"
              >
                <v-icon dark> mdi-arrow-left </v-icon>
              </v-btn>
              <v-img
                contain
                :src="require('../assets/Truce_Black.png')"
                max-height="50"
              ></v-img>
          </v-card-title>
        </v-col>

        <template v-if="cards[cardIdx] == 'initial'">
          <br />
          <v-card-title
            class="justify-center text-center text-h4 font-weight-bold mt-10"
          >
            <v-img
              contain
              :src="require('../assets/Truce_Black.png')"
              max-height="120"
            ></v-img>
          </v-card-title>
          <v-card-actions>
            <v-col cols="12">
              <LoginScreen
                @emailUpdate="handleEmailEmission"
                @passwordUpdate="handlePasswordEmission"
                @forgotPassword="handleForgotPasswordEmission"
                @triggerLogin="signIn"
              >
              </LoginScreen>
              <v-btn block @click="signIn" class="login-button"
                >Login</v-btn
              >
              <p class="link" style="color: '#545454'">
                Don't have an account yet? Contact us at <a>contact@truce.io</a> to sign up.
              </p>

              <br />
              <br />
              <br />
              <br />

              <span
                class="clickable link"
                @click="moveToContact"
                style="color: '#545454'"
              >
                Contact Us
              </span>
            </v-col>
          </v-card-actions>
        </template>

        <template v-if="cards[cardIdx] == 'forgot'">
          <v-card-title
            class="justify-center text-center text-h5"
          >
            Reset Password
          </v-card-title>
          <v-card-text class="py-5">
            <ForgotPassword
                @emailUpdate="handleEmailEmission"
                @error="handleForgotPasswordError"
                @emailSent="handleEmailSent"
              >
              </ForgotPassword>
          </v-card-text>
        </template>

        <template v-else-if="cards[cardIdx] == 'signup'">
          <v-card-title
            class="justify-center text-center text-h5"
          >
            Enter Your Information
          </v-card-title>
          <v-card-text class="py-4">
            <BasicInformation
                  @firstNameUpdate="handleFirstNameEmission"
                  @lastNameUpdate="handleLastNameEmission"
                  @phoneNumberUpdate="handlePhoneNumberEmission"
                  @roleUpdate="handleRoleEmission"
                  @selectedUpdate="handleCompanyEmission"
                >
                </BasicInformation>
          </v-card-text>
          <v-card-actions class="pt-6">
            <v-row class="pb-5 pt-4">
                <v-spacer></v-spacer>
                <v-btn
                  v-on:click="verifyBasicInformation"
                  outlined
                  color="blue"
                >
                  Next
                </v-btn>
            </v-row>
          </v-card-actions>
        </template>

        <template v-else-if="cards[cardIdx] == 'reset'">
          <v-card-title
            class="justify-center text-center text-h5"
          >
            Set Your Account Password
          </v-card-title>
          <v-card-text class="py-4">
            <SetPassword
                @setPasswordUpdate="handleSetPasswordEmission"
                @confirmPasswordUpdate="handleConfirmPasswordEmission"
                @creationRuleUpdate="handleCreationRuleStatus"
                @tokenUpdate="handleTokenEmission"
                @triggerLogin="signUp"
                :token_reset="token_reset"
              >
              </SetPassword>
          </v-card-text>
          <v-card-actions>
            <v-row class="pb-5 pt-4">
                <v-spacer></v-spacer>
                <v-btn
                  v-on:click="signUp"
                  outlined
                  color="blue"
                >
                  Login
                </v-btn>
            </v-row>
          </v-card-actions>
        </template>

        <template v-else-if="cards[cardIdx] == 'dummysignup'">
          <v-card-title
            class="justify-center text-center text-h5"
          >
            Sign Up
          </v-card-title>
          <v-card-actions>
            <v-col cols="12">
              We're super excited that you want to join our platform!
              Unfortunately, as of right now, we only allow sign up through an
              administrator. Contact us at
              <a href="mailto:contact@truce.io">contact@truce.io</a> to join!
            </v-col>
          </v-card-actions>
          <br />
          <br />
        </template>

        <template v-else-if="cards[cardIdx] == 'contact'">
          <v-card-title
            class="justify-center text-center text-h5"
          >
            Contact Us
          </v-card-title>
          <v-card-actions>
            <v-col cols="12">
              If you have questions or concerns, you can contact us at any
              time!<br />
              <br />
              Email: <a href="mailto:contact@truce.io">contact@truce.io</a> <br />
            </v-col>
          </v-card-actions>
          <br />
          <br />
        </template>

        <template v-else-if="cards[cardIdx] == 'expired'">
          <v-card-actions v-if="!resendSuccess">
            <v-row>
              <v-row>
              <v-col cols="12">
                Your temporary password has expired. Select the button below to receive a new password.
              </v-col>
              </v-row>
              <v-row class="justify-center pt-4">
                <v-btn @click="resendTemporaryPassword" color="blue" dark :disabled="resendSuccess">Resend Password</v-btn>
              </v-row>
            </v-row>
          </v-card-actions>
          <v-row class="pt-4"  v-if="resendSuccess">
            <v-col cols="12">
              A new email with your temporary password has been sent. If you do not receive an email please contact us at <a href="mailto:contact@truce.io">contact@truce.io</a>.
            </v-col>
          </v-row>
          <br />
          <br />
        </template>
      </v-card>
      <v-snackbar v-model="snackbar" color="red" top timeout="2500">
        {{ errorText }}
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script>
import * as stateAPI from "../stateAPI";
import { Auth, API } from "aws-amplify";
import * as user_analytics from "../analytics/sendAnalyticsEvent";
import LoginScreen from "../authentication/auth_components/LoginScreen";
import SetPassword from "../authentication/auth_components/SetPassword";
import BasicInformation from "../authentication/auth_components/BasicInformation";
import ForgotPassword from "../authentication/auth_components/ForgotPassword";
import admin_emails from "../authentication/admins";
import { isProdEnv, isDevEnv } from "../utils";
import * as fetchAccountDetails from "../fetchAccountDetails";

export default {
  name: "Login",

  components: {
    LoginScreen,
    SetPassword,
    BasicInformation,
    ForgotPassword,
  },

  computed: {
    theme() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light';
    }
  },


  methods: {
    async signIn() {
      try {
        this.loadingLogin = true;
        const user = await Auth.signIn(this.email, this.password);
        if (user["challengeName"] == "NEW_PASSWORD_REQUIRED") {
          // INITIATE SIGN UP PROCESS
          this.username = this.email;
          this.loadingLogin = false;
          this.incrementCard();
        } else {
          this.email = user.attributes["email"];
          this.set_password = this.password;
          this.full_name = user.attributes["name"];
          this.phone_number = user.attributes["phone_number"];
          this.role = user.attributes["custom:role"];
          this.company = user.attributes["custom:company"];
          this.idToken = user["signInUserSession"]["idToken"]["jwtToken"];

          this.navigate();
        }
      } catch (error) {
        this.loadingLogin = false;
        if (error != null && error.code == 'NotAuthorizedException' && error.message == 'Temporary password has expired and must be reset by an administrator.') {
          this.cardIdx = this.cards.indexOf("expired");
        } else {
          this.snackbar = true;
          this.errorText = "Incorrect email or password.";
        }
      }
    },

    async signUp() {
      // Creation Rule Status must be met
      if (!this.creation_rule_status) {
        this.snackbar = true;
        this.errorText = "All creation rules must be met.";
        return;
      }

      // Password must match confirm password
      if (this.set_password != this.confirm_password) {
        this.snackbar = true;
        this.errorText = "Password must match confirm password.";
        return;
      }

      this.loadingLogin = true;

      // Resetting password
      if (this.token_reset) {
        Auth.forgotPasswordSubmit(this.email, this.token, this.set_password)
          .then((data) => {
            this.cardIdx = 0;
            this.email = "";
            this.password = "";
          })
          .catch((err) => {
            this.errorText =
              "There was a problem. Please try again or contact an adminstator.";
            this.snackbar = true;
            this.loadingLogin = false;
          });

        return;
      }

      var phoneString = "+1" + this.phone_number.replace(/\D/g, "");

      var nameString = this.first_name + " " + this.last_name;

      // Manually replace admin role and company
      if (admin_emails.includes(this.email)) {
        this.role = "admin";
        this.company = "Truce";
      }

      Auth.signIn(this.username, this.password)
        .then((user) => {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            const { requiredAttributes } = user.challengeParam;
            Auth.completeNewPassword(
              user, // the Cognito User Object
              this.set_password, // the new password

              // OPTIONAL, the required attributes
              {
                phone_number: phoneString,
                name: nameString
              }
            )
              .then((user) => {
                // at this time the user is logged in if no MFA required
                this.full_name = nameString;
                this.idToken = user["signInUserSession"]["idToken"]["jwtToken"];
                const aggStartDate =  (new Date(new Date(new Date(new Date().getFullYear(), 0, 1).setHours(5, 0, 0)) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10).replace(/\s+/g, '');

                // insert account details into db
                const params = {
                  first_name: this.first_name.toLowerCase().replace(/[^a-zA-Z 0-9]\s+/g,'').replace(/\s+/g, ''),
                  last_name: this.last_name.toLowerCase().replace(/[^a-zA-Z 0-9]+/g,'').replace(/\s+/g, ''),
                  email: this.email.toLowerCase().replace(/[^a-zA-Z 0-9@+.]+/g,'').replace(/\s+/g, ''),
                  phone_number: this.phone_number.replace(/\D/g, "").replace(/[^0-9]+/g,'').replace(/\s+/g, ''),
                };
                fetchAccountDetails.insertAccountDetails(params).then(result=>{
                  if (result != undefined && result.status == 200) {
                    this.navigate();
                  } else {
                    throw "Error occured during sign up";
                  }
                })


              })
              .catch((e) => {
                console.log(e);
                this.loadingLogin = false;
              });
          } else {
            // other situations
            this.loadingLogin = false;
          }
        })
        .catch((e) => {
          this.snackbar = true;
          this.errorText = "There was an error. Contact your administrator.";
          this.loadingLogin = false;
        });
    },

    verifyBasicInformation() {
      // First and last name cannot be empty.
      if (this.first_name == "" || this.last_name == "") {
        this.snackbar = true;
        this.errorText = "First and last name cannot be empty.";
        return;
      }

      // First and last name can only be letters
      const alphaPattern = /^[a-zA-Z\s]*$/;
      if (!this.first_name.match(alphaPattern) || !this.last_name.match(alphaPattern)) {
        this.snackbar = true;
        this.errorText = "First and last name can only contain letters.";
        return;
      }

      // First and last name cannot contain more than 40 characters
      const maxLength = 40;
      if (!this.first_name.length >= maxLength || !this.last_name.length >= maxLength) {
        this.snackbar = true;
        this.errorText = `First and last name cannot contain more than ${maxLength} characters.`;
        return;
      }

      // Phone number cannot be empty
      if (this.phone_number == null) {
        this.snackbar = true;
        this.errorText = "Phone number cannot be empty.";
        return;
      }

      // Phone number must have exactly 10 digits
      var count = (this.phone_number.match(/\d/g) || []).length;
      if (count != 10) {
        this.snackbar = true;
        this.errorText = "Phone number must have exactly 10 digits";
        return;
      }

      this.incrementCard();
    },

    async navigate() {
      const apiName = "SBUAccess";

      const currentUrl = window.location.href;
      var path;
      if (isProdEnv()) {
        path = "/getshipperbusinessunit";
      } else if (
        isDevEnv()
      ) {
        path = "/getshipperbusinessunitdev";
      }

      const myInit = {
        // OPTIONAL
        headers: { Authorization: this.idToken }, // OPTIONAL
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        queryStringParameters: {
          // OPTIONAL
          email: this.email,
        },
      };

      await API.get(apiName, path, myInit)
        .then(async (response) => {
          stateAPI.setStateProperty(
            this,
            "user_id",
            response["data"]["body"]["shipper_business_unit"]
          );

          if (this.company == undefined){
            this.company = response["data"]["body"]["company"]
          }

          await fetchAccountDetails.getAccountDetails({email:this.email}).then(result=>{
            var data = null;

            if (result != undefined && result.status == 200) {
              data = JSON.parse(result.data.records[0].config);
            }

            if (data != null && data.aggregationStartDate) {
              const dateSplit = data.aggregationStartDate.split("-");
              const start = new Date(new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2]).setHours(5,0,0));
              stateAPI.setStateProperty(this, "startDate", start);
              const today = new Date(new Date().setHours(5, 0, 0));
              const timePeriodToStore = ((today.getTime() - start.getTime()) / (24 * 60 * 60 * 1000)).toFixed(0)
              stateAPI.setStateProperty(this, "storeTimePeriod", timePeriodToStore);
            } else {
              stateAPI.setStateProperty(this, "startDate", new Date(new Date(new Date().getFullYear(), 0, 1).setHours(5, 0, 0)));
              stateAPI.setStateProperty(this, "storeTimePeriod",
                (
                  (new Date(new Date().setHours(5, 0, 0)).getTime() -
                    new Date(new Date(new Date().getFullYear(), 0, 1).setHours(5, 0, 0)).getTime()) /
                    (24 * 60 * 60 * 1000)
                ).toFixed(0)
              );
            }

            if (data != null && data.defaultProjectionDate) {
              stateAPI.setStateProperty(this, "defaultProjectionDate", data.defaultProjectionDate);
            } else {
              stateAPI.setStateProperty(this, "defaultProjectionDate",`${new Date().getFullYear()}-12-31`);
            }

            if (data != null && data.favoriteLanes) {
              stateAPI.setStateProperty(this, "favoriteLanes", data.favoriteLanes);
            } else {
              stateAPI.setStateProperty(this, "favoriteLanes", new Map());
            }

            if (data != null && data.darkMode != null) {
              stateAPI.setStateProperty(this, "darkMode", data.darkMode);
            } else {
              stateAPI.setStateProperty(this, "darkMode", false);
            }

            this.$vuetify.theme.dark = stateAPI.getStateProperty(this, "darkMode");
          })

          stateAPI.setStateProperty(this, "equipment_type_list", [
            "Dry Van",
            "Reefer",
            "Flatbed",
            "Power Only",
            "Straight Truck",
          ]);

          // reset crumbs
          stateAPI.setStateProperty(this, "crumbs", [])
          stateAPI.setStateProperty(this, "crumbIds", ['dashboard'])

          // reset filters for persitence
          stateAPI.setStateProperty(
            this,
            "storeEndDate",
            new Date(new Date().setHours(5, 0, 0))
          );

          stateAPI.setStateProperty(this, "storeVolumeThreshold", 0);
          stateAPI.setStateProperty(this, "storeDates", null);
          stateAPI.setStateProperty(this, "storeTimeSlider", 5);
          stateAPI.setStateProperty(this, "storeIsComparisonEnabled", false);
          stateAPI.setStateProperty(this, "storeIsTimePeriodDisabled", false);
          stateAPI.setStateProperty(this, "storeKeyMetricsToggle", null);

          var usn = this.email.split("@")[0];
          stateAPI.setStateProperty(this, "username", usn);
          stateAPI.setStateProperty(this, "email", this.email);
          stateAPI.setStateProperty(this, "name", this.full_name);
          stateAPI.setStateProperty(this, "phone_number", this.phone_number);
          stateAPI.setStateProperty(this, "company", this.company);
          const calc_enabled = [
              "BlueGrace Logistics",
          ]
          stateAPI.setStateProperty(this, "calc_enabled", (this.role !== "broker" || calc_enabled.includes(this.company)));
          stateAPI.setStateProperty(this, "role", this.role);
          stateAPI.setStateProperty(this, "first_load", true);

          // Analytics login event
          const email = stateAPI.getStateProperty(this, "email");
          const company = stateAPI.getStateProperty(this, "company");
          const role = stateAPI.getStateProperty(this, "role");
          user_analytics.sendEvent(email, company, role, "login");


          let searchParams = new URLSearchParams(window.location.search);
          if (searchParams.has("redirect")) {
            this.$router.replace(`${searchParams.get("redirect")}`);
          } else {
            if (this.role != "broker") {
              this.$router.push({
                name: "broker-dashboard",
                params: { prop_usn: "Roop Pal", prop_toggle: "left" },
              });
            } else {
              this.$router.push({
                name: "shipper-dashboard",
                params: { prop_usn: "Roop Pal", prop_toggle: "left" },
              });
            }
          }
        })
        .catch((error) => {
          this.errorText =
            "There was a problem. Please try again or contact an adminstator.";
          this.snackbar = true;
          this.loadingLogin = false;
        });
    },

    incrementCard() {
      this.cardIdx++;
    },

    decrementCard() {
      if (this.resetDecrement || this.cardIdx == this.cards.indexOf("expired")) {
        this.cardIdx = 0;
        this.resetDecrement = false;
        this.resetDecrement_forgot = false;
      } else if (this.resetDecrement_forgot) {
        this.cardIdx = 3;
        this.resetDecrement = true;
        this.resetDecrement_forgot = false;
      } else {
        this.cardIdx--;
      }

      if (this.cardIdx == 0) {
        this.email = "";
        this.password = "";
      }
    },

    moveToPasswordReset() {
      if (this.email_sent) {
        this.cardIdx = 2;
        this.resetDecrement = false;
        this.resetDecrement_forgot = true;
        this.token_reset = true;
      } else {
        this.errorText = "Please send a reset email.";
        this.snackbar = true;
      }
    },

    moveToDummySignUp() {
      this.cardIdx = 4;
      this.resetDecrement = true;
    },

    moveToContact() {
      this.cardIdx = 5;
      this.resetDecrement = true;
    },

    handleEmailEmission(email) {
      this.email = email;
    },
    handlePasswordEmission(password) {
      this.password = password;
    },
    handleSetPasswordEmission(set_password) {
      this.set_password = set_password;
    },
    handleConfirmPasswordEmission(confirm_password) {
      this.confirm_password = confirm_password;
    },
    handleFirstNameEmission(first_name) {
      this.first_name = first_name;
    },
    handleLastNameEmission(last_name) {
      this.last_name = last_name;
    },
    handlePhoneNumberEmission(phone_number) {
      this.phone_number = phone_number;
    },

    handleRoleEmission(role_type) {
      this.role = role_type;
      if (role_type == "admin") {
        this.company = "Truce";
      }
    },

    handleCompanyEmission(company) {
      this.company = company;
    },

    handleCreationRuleStatus(creation_rule_status) {
      this.creation_rule_status = creation_rule_status;
    },

    handleForgotPasswordEmission() {
      this.cardIdx = 3;
      this.resetDecrement = true;
    },

    handleForgotPasswordError() {
      this.errorText = "Please enter a valid email.";
      this.snackbar = true;
    },

    handleEmailSent() {
      this.email_sent = true;
      this.moveToPasswordReset();
    },

    handleTokenEmission(token) {
      this.token = token;
    },

    async resendTemporaryPassword() {
      this.loadingLogin = true;
      var path;
      const currentUrl = window.location.href;
      if (isProdEnv()) {
        path = "/resendtemporarypassword";
      } else if (isDevEnv()) {
        path = "/resendtemporarypassworddev";
      }

      const myInit = {
        // OPTIONAL
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        queryStringParameters: {
          // OPTIONAL
          email: this.email,
        },
      };

      await API.get("ResendTempPassword", path, myInit)
        .then((response) => {
          const statusCode = response.data.statusCode;
          if (statusCode == 200) {
            this.resendSuccess = true;
          } else {
            this.errorText = "An error occurred while regenerating your temporary password. Please contact a system administrator";
            this.snackbar = true;
          }
          this.loadingLogin = false;
        })
    }
  },

  data: function () {
    return {
      cards: ["initial", "signup", "reset", "forgot", "dummysignup", "contact", "expired"],
      cardIdx: 0,

      //User Info
      email: "",
      username: "",
      password: "",
      setPassword: "",
      confirm_password: "",
      creation_rule_status: "",
      first_name: "",
      last_name: "",
      full_name: "",
      phone_number: "",
      role: "shipper",
      company: "",
      resetDecrement: false,
      resetDecrement_forgot: false,
      email_sent: false,
      token_reset: false,
      token: null,

      snackbar: false,
      errorText: "",
      idToken: "",
      loadingLogin: false,
      resendSuccess: false
    };
  },
};
</script>

<style>
.app {
  background: url("../assets/roads.jpg") no-repeat center center fixed !important;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
}

.login-button {
  background: linear-gradient(90deg, #0091ff, #0091ff) !important;
  color: white !important;
  border-radius: 100px !important;
  margin-bottom: 10px;
}

.clickable {
  cursor: pointer;
}
</style>
