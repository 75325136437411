<template>
    <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <v-main>
        <v-container fluid style="width: 91%" dense>
            <v-row>
                <v-col cols="8" class="pt-8">
                    <div class="text-h2">Price Estimation Form</div>
                    <v-form 
                        v-model="valid" 
                        class="pt-4" 
                        ref="form"
                        @input="inputEvent"
                        @submit="submitEvent"
                    >
                        <v-container ref="formContainer" class="formContainer">
                            <v-row class="pb-6">
                                <v-col>
                                    <v-card>
                                        <v-card-title>
                                            Origin
                                        </v-card-title>
                                        <v-card-text>
                                            <v-text-field
                                                v-model="origin_city"
                                                :rules="cityRules"
                                                label="City *"
                                            ></v-text-field>
                                            <v-autocomplete
                                                v-model="origin_state"
                                                :filter="stateFilter"
                                                :rules="required"
                                                :items="states"
                                                item-text="name"
                                                item-value="abbr"
                                                label="State *"
                                            >
                                                <template #item="{ item }">
                                                    <div class="selectorItem"> {{ item.name }}, {{ item.abbr }}</div>
                                                </template>
                                            </v-autocomplete>
                                            <v-select
                                                v-model="origin_country"
                                                :items="countries"
                                                item-text="name"
                                                item-value="abbr"
                                                label="Country"
                                                disabled
                                            ></v-select>
                                            <v-text-field
                                                v-model="origin_zip"
                                                :rules="zipRules"
                                                label="Zip *"
                                            ></v-text-field>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-card class="timePickerCard mr-1" width="50%" color="#546A4D">
                                                <v-card-title>
                                                    Open<span style="font-size:1rem; padding-left: 4px">*</span>
                                                </v-card-title>
                                                <v-card-actions class="d-flex flex-column">
                                                    <v-menu :close-on-content-click="false">
                                                        <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="origin_fields.open.date"
                                                            :rules="required"
                                                            style="width:115px;"
                                                            label=""
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            dense
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="origin_fields.open.date"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                    <TimePicker
                                                        v-model="origin_fields.open.time"
                                                    ></TimePicker>
                                                </v-card-actions>
                                            </v-card>
                                            <v-card class="timePickerCard ml-1" width="50%" color="#8C032C">
                                                <v-card-title>
                                                    Close<span style="font-size:1rem; padding-left: 4px">*</span>
                                                </v-card-title>
                                                <v-card-actions class="d-flex flex-column">
                                                    <v-menu :close-on-content-click="false">
                                                        <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="origin_fields.close.date"
                                                            :rules="required"
                                                            style="width:115px;"
                                                            label=""
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            dense
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="origin_fields.close.date"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                    <TimePicker
                                                        v-model="origin_fields.close.time"
                                                    ></TimePicker>
                                                </v-card-actions>
                                            </v-card>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                                <v-col>
                                    <v-card>
                                        <v-card-title>
                                            Destination
                                        </v-card-title>
                                        <v-card-text>
                                            <v-text-field
                                                v-model="destination_city"
                                                :rules="cityRules"
                                                label="City *"
                                            ></v-text-field>
                                            <v-autocomplete
                                                v-model="destination_state"
                                                :filter="stateFilter"
                                                :rules="required"
                                                :items="states"
                                                item-text="name"
                                                item-value="abbr"
                                                label="State *"
                                            >
                                                <template #item="{ item }">
                                                    <div class="selectorItem"> {{ item.name }}, {{ item.abbr }}</div>
                                                </template>
                                            </v-autocomplete>
                                            <v-select
                                                v-model="destination_country"
                                                :items="countries"
                                                item-text="name"
                                                item-value="abbr"
                                                label="Country"
                                                disabled
                                            ></v-select>
                                            <v-text-field
                                                v-model="destination_zip"
                                                :rules="zipRules"
                                                label="Zip *"
                                            ></v-text-field>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-card class="timePickerCard mr-1" width="50%" color="#546A4D">
                                                <v-card-title>
                                                    Open<span style="font-size:1rem; padding-left: 4px">*</span>
                                                </v-card-title>
                                                <v-card-actions class="d-flex flex-column">
                                                    <v-menu :close-on-content-click="false">
                                                        <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="dest_fields.open.date"
                                                            :rules="required"
                                                            style="width:115px;"
                                                            label=""
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            dense
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="dest_fields.open.date"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                    <TimePicker
                                                        v-model="dest_fields.open.time"
                                                    ></TimePicker>
                                                </v-card-actions>
                                            </v-card>
                                            <v-card class="timePickerCard ml-1" width="50%" color="#8C032C">
                                                <v-card-title>
                                                    Close<span style="font-size:1rem; padding-left: 4px">*</span>
                                                </v-card-title>
                                                <v-card-actions class="d-flex flex-column">
                                                    <v-menu :close-on-content-click="false">
                                                        <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="dest_fields.close.date"
                                                            :rules="required"
                                                            style="width:115px;"
                                                            label=""
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            dense
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="dest_fields.close.date"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                    <TimePicker
                                                        v-model="dest_fields.close.time"
                                                    ></TimePicker>
                                                </v-card-actions>
                                            </v-card>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-select
                                        v-model="equipment_type"
                                        label="Equipment Type"
                                        :items="equipment_types"
                                        item-text="name"
                                        item-value="abbr"
                                        disabled
                                    ></v-select>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        v-model="weight"
                                        :rules="weightRules"
                                        label="Weight in pounds"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            
                        </v-container>
                    </v-form>
                </v-col>
                <v-col cols="4" class="d-flex flex-column justify-center align-items-center">
                    <v-btn
                        flat depressed
                        @click="fetchPrediction"
                        style="width:fit-content; align-self:center"
                    >Estimate</v-btn>
                    <Counter
                        class="no-highlight"
                        :targetNum="priceEstimate.toString()"
                    ></Counter>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
    </v-app>
</template>
<script>
import * as user_analytics from "../../analytics/sendAnalyticsEvent";
import TimePicker from '../components/TimePicker';
import Counter from '../components/Counter';
import { abbreviationsLand } from "../../stateConstants";
import axios from "axios";
import aws4 from "aws4";

export default {
    name: "PricingForm",
    props: [],

    components: {
        Counter,
        TimePicker
    },

    computed:{
        theme() {
            return (this.$vuetify.theme.dark) ? 'dark' : 'light';
        },
        origin_open() {
            return this.origin_fields.open.date?.toString() + "T" + this.origin_fields.open.time[0]?.toString() + ":" + this.origin_fields.open.time[1]?.toString() + ":00"
        },
        origin_close() {
            return this.origin_fields.close.date?.toString() + "T" + this.origin_fields.close.time[0]?.toString() + ":" + this.origin_fields.close.time[1]?.toString() + ":00"
        },
        dest_open() {
            return this.dest_fields.open.date?.toString() + "T" + this.dest_fields.open.time[0]?.toString() + ":" + this.dest_fields.open.time[1]?.toString() + ":00"
        },
        dest_close() {
            return this.dest_fields.close.date?.toString() + "T" + this.dest_fields.close.time[0]?.toString() + ":" + this.dest_fields.close.time[1]?.toString() + ":00"
        },
    },

    watch: {
        valid(val) {
            if (val) {
                this.$refs.formContainer.classList.add('valid');
            } else {
                this.$refs.formContainer.classList.remove('valid');
            }
        }
    },

    methods: {
        fetchPrediction()   {
            console.log(this.$refs.form.validate())
            this.$refs.form.validate()
            if (this.valid) {
                let query_object = {
                    "origin_city": this.origin_city,
                    "origin_state": this.origin_state,
                    "origin_country": this.origin_country,
                    "origin_zip": this.origin_zip,
                    "destination_city": this.destination_city,
                    "destination_state": this.destination_state,
                    "destination_country": this.destination_country,
                    "destination_zip": this.destination_zip,
                    "origin_open_time": this.origin_open,
                    "origin_close_time": this.origin_close,
                    "destination_open_time": this.dest_open,
                    "destination_close_time": this.dest_close,
                    "equipment_type": this.equipment_type,
                    "weight": this.weight,
                }
    
                console.log(query_object)
    
                // Make request with query
                let request = {
                    host: "x2hmfsw1xg.execute-api.us-east-1.amazonaws.com",
                    method: "POST",
                    url:
                        "https://x2hmfsw1xg.execute-api.us-east-1.amazonaws.com/beta-1/price",
                    data: query_object
                };
    
                let signedRequest = aws4.sign(request, {
                    secretAccessKey: "",
                    accessKeyId: "",    
                });
    
                delete signedRequest.headers["Host"];
                delete signedRequest.headers["Content-Length"];
                let response = axios(signedRequest).then((result) => {
                    let body = JSON.parse(result.data.body).body
                    this.priceEstimate = Math.round(body.value).toString()
                    return result;
                });
            }
        },

        stateFilter(item, queryText, itemText) {
            return (itemText.toLocaleLowerCase() + item.abbr.toLocaleLowerCase()).indexOf(queryText.toLocaleLowerCase()) > -1
        },

        inputEvent(event) {console.log('inputevent', event)},
        submitEvent(event) {console.log('submit', event)},

    },

    data: function () {
        return {
            valid: false,

            origin_city: null,
            origin_state: null,
            origin_country: "US",
            origin_zip: null,
            destination_city: null,
            destination_state: null,
            destination_country: "US",
            destination_zip: null,
            equipment_type: "Dry Van",
            weight: null,

            // Dropdown field items
            countries: [
                {
                    name: "United States",
                    abbr: "US"
                }
            ],
            states: Object.entries(abbreviationsLand).map(x => { return {name: x[1], abbr: x[0] } }),
            equipment_types: [ "Dry Van", "Flat Bed", "Reefer" ],

            // Date and time fields
            origin_fields: {
                open: {
                    date: null,
                    time: ["00", "00"],
                },
                close: {
                    date: null,
                    time: ["00", "00"],
                }
            },
            dest_fields: {
                open: {
                    date: null,
                    time: ["00", "00"],
                },
                close: {
                    date: null,
                    time: ["00", "00"],
                }
            },

            // Validation rules
            zipRules: [
                v => !!v || 'Required field',
                v => /\d+/.test(v) || 'Can only contain digits', 
                v => (v && v.length <= 9) || 'Cannot exceed 9 digits',
            ],
            weightRules: [
                v => /\d+/.test(v) || !v ||'Can only contain digits',
            ],
            required: [
                v => !!v || 'Required field',
            ],
            cityRules: [
                v => !!v || 'Required field',
            ],

            priceEstimate: "?",
        };
    },
};
</script>
<style>
.timePickerCard {
    width: min-content;
}
.timePickerCard .col {
    margin-bottom: 10px;
    padding-top: 2px;
}
.formContainer:not(.valid) {
    background-color: rgb(69, 27, 27);
    border-radius: 10px;
}
.formContainer.valid {
    background-color: rgb(52, 68, 46);
    border-radius: 10px;
}
.roller .roller__char li{
    user-select: none !important;
    -webkit-user-select: none !important;
}
</style>