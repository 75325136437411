<template>
    <v-row justify-content="flex-end">
        <v-col cols="4" class="my-auto pl-6 pt-5">{{ tableType }} Table</v-col>
        <v-col cols="2"></v-col>
        <v-col cols="6" align-self="end">
            <template v-if="!showSelect">
                <v-row class="pr-6" style="justify-content: flex-end;">
                <v-text-field
                    v-model="searchTable"
                    :label="
                        isLaneData
                        ? 'Search origin or destination'
                        : `Search ${tableType}s`
                    "
                    hide-details
                    single-line
                    rounded
                    dense
                    flat
                    solo
                    :background-color="(this.$vuetify.theme.dark) ? '#212121' : '#42A5F5'"
                    multiple
                    class="pr-2 pb-2 elevation-0 tableSearch"
                    style="margin-top: -1px; max-width: 660px;"
                    clearable
                    @change="filterTable"
                    @click:clear="clearTable"
                    @keyup.enter.prevent="filterTable"
                    >
                    <template v-slot:prepend>
                        <template v-if="focusFieldsStore.length > 0">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        :color="focusLaneButtonColor"
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="clearFocus()"
                                    >
                                        <v-icon>
                                        mdi-restore
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Clear Focus</span>
                            </v-tooltip>
                        </template>
                        <v-menu v-if="isLaneData" v-model="focusMenu" offset-y left :close-on-content-click="false" :close-on-click="false" content-class="elevation-4">
                            <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
                                        <v-btn
                                            id="focusBtn"
                                            icon
                                            v-bind="{ ...attrsMenu, ...attrsTooltip }"
                                            v-on="{ ...onMenu, ...onTooltip }"
                                            @click="storeFocusFields()"
                                            :color="focusLaneButtonColor"
                                        >
                                            <v-icon>mdi-focus-field</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Focus Lane Filter</span>
                                </v-tooltip>
                            </template>
                            <v-card class="px-2 pt-2">
                                <v-radio-group v-model="focusFieldModel">
                                    <v-radio
                                        v-for="(item, i) in focusFields"
                                        :key="`item-${i}`"
                                        :label="item"
                                        :value="item"
                                    ></v-radio>
                                </v-radio-group>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="blue accent-4" class="pa-2 ma-1" @click="focusLaneCancel()">Cancel</v-btn>
                                    <v-btn text color="blue accent-4" class="pa-2 ma-1" @click="focusLanesToggle()" :disabled="focusFieldModel == undefined || focusFieldModel.length == 0">Ok</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu>
                        <v-tooltip top>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn
                                    icon
                                    v-if="isLaneData && !isDashboard && isCalcEnabled"
                                    @click="repriceNetworkSelection()"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon :color="theme == 'light' ? '#757575' : 'white'">$calc</v-icon>
                                </v-btn>
                            </template>
                            <span>Calculator</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn
                                    :color="isFavoritesApplied ? 'orange' : 'inherit'"
                                    icon
                                    v-if="isLaneData && !isDashboard"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="getFavoriteLanes()"
                                >
                                    <v-icon>mdi-star-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Favorites</span>
                        </v-tooltip>
                        <v-menu
                            offset-y
                            offset-overflow
                            left
                            :close-on-content-click="false"
                            content-class="elevation-4"
                        >
                        <template v-slot:activator="{ on:onMenu, attrs:attrsMenu }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on:onTooltip, attrs:attrsTooltip }">
                                    <v-btn
                                        v-bind="{ ...attrsMenu, ...attrsTooltip }"
                                        v-on="{ ...onMenu, ...onTooltip }"
                                        icon
                                        class="pr-4 pt-1"
                                    >
                                        <v-icon>mdi-dots-grid</v-icon>
                                    </v-btn>
                                </template>
                                <span>Table Menu</span>
                            </v-tooltip>
                        </template>
                        <v-list>
                            <v-list-item>
                            <v-list-item-action>
                                <v-switch
                                    color="#FF6F00"
                                    inset
                                    @change="handleAccessorial()"
                                    v-model="includeAcc"
                                ><template v-slot:label><span class="pl-3">
                                Accessorials
                                </span></template></v-switch>
                            </v-list-item-action>
                            </v-list-item>
                            <v-list-item v-if="isLaneData">
                            <v-list-item-action>
                                <v-switch
                                    color="#0091ff"
                                    inset
                                    @change="$emit('toggleAgg', $event)"
                                    v-model="isWeeklyView"
                                ><template v-slot:label><span class="pl-3">
                                    Weekly
                                </span></template></v-switch>
                            </v-list-item-action>
                            </v-list-item>
                            <v-list-item>
                            <v-list-item-action>
                                <v-switch
                                    color="#7B91FF"
                                    inset
                                    @change="viewTogglehandler($event)"
                                    v-model="isFullView"
                                ><template v-slot:label><span class="pl-3">
                                    Full View
                                </span></template></v-switch>
                            </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        </v-menu>
                    </template>
                    <template v-slot:prepend-inner>
                        <v-btn
                            @click="filterTable"
                            elevation="0"
                            top
                            small
                            :ripple="false"
                            plain
                            class="px-0"
                            style="min-width:auto !important;"
                        >
                        <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </template>
                </v-text-field>
                </v-row>
            </template>
            <template v-else>
                <v-row class="pl-8 pt-2">
                    <v-col class="text-right" cols="6">
                    </v-col>
                    <v-col cols="6" align="right">
                        <v-btn
                            color="#7385E6"
                            @click="submitNetworkSelection()"
                            text
                            tile
                            style="border-bottom: 0px solid blue !important;"
                            class="mb-1"
                        >
                            Reprice
                        </v-btn>
                        <v-btn
                            color="red"
                            @click="repriceNetworkSelection()"
                            text
                            tile
                            style="border-bottom: 0px solid blue !important;"
                            class="mb-1"
                        >
                            Cancel
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </v-col>
    </v-row>
</template>

<script>
import * as stateAPI from "../stateAPI";
import * as globalVariables from "../globalVariables";
import * as utils from "../utils";
import _ from 'lodash';

export default {
// Some things to watch out for:
// Parent container needs to have a DataTable component with a "table" ref

    name: "TableBar",

    props: [
        "is_lane_drilldown",
        "is_lane_data",
        "is_broker_user",
        "table_type",
        "selected_lanes",
        "is_dashboard",
        "is_table_loading",
        "is_dropdown_loading",
        "time_period",
        "end_date",
        "start_date"
    ],

    data: function () {
    return {
            role: stateAPI.getStateProperty(this, "role"),
            settings: stateAPI.getStateProperty(this, "table_settings"),
            type: "",
            searchTable: null,
            isLaneDrilldown: this.is_lane_drilldown,
            isLaneData: this.is_lane_data,
            isBrokerUser: this.is_broker_user,
            isWeeklyView: false,
            isFullView: false,
            nav: null,
            tableType: this.table_type,
            isDashboard: this.is_dashboard,
            selectedLanes: this.selected_lanes,
            selectedEquipmentTypes: stateAPI.getStateProperty(
                this,
                "equipment_type_list"
            ),

            // Props to pass up to the page
            showSelect: false,
            includeAcc: utils.COGS_REV.WITH_ACC,
            isTableLoading: this.is_table_loading,
            isDropdownLoading: false,
            headersShipments: globalVariables.default.headers_shipments,
            timePeriod: this.time_period,
            endDate: this.end_date,
            startDate: this.start_date,
            isCalcEnabled: stateAPI.getStateProperty(this, "calc_enabled"),
            focusFields: ["High Margin", "High Margin/Late Delivery", "High Margin/Low Margin", "Low Margin", "Late Pickup", "Late Delivery", "Low Prebook"],
            focusFieldModel: [],
            focusFieldsStore: [],
            focusMenu: false,
            focusLaneButtonColor: "inherit",
            isFavoritesApplied: false
        }
    },

    computed: {
        // Not a real bus
        tableBus() {
            return {
                "showSelect": this.showSelect,
                "isTableLoading": this.isTableLoading,
                "isDropdownLoading": this.isDropdownLoading,
                "isWeeklyView": this.isWeeklyView,
                "headersShipments": this.headersShipments,
                "searchTable": this.searchTable,
            }
        },
        theme() {
            return (this.$vuetify.theme.dark) ? 'dark' : 'light';
        }
    },

    watch: {
        tableBus: {
            handler() {
                this.$emit('busEvent', this.tableBus);
            },
            deep: true
        },

        is_table_loading(val) {
            this.isTableLoading = val;
        },

        is_dropdown_loading(val) {
            this.isDropdownLoading = val;
        },

        selected_lanes(val) {
            this.selectedLanes = val;
        }
    },

    beforeMount() {
        this.nav = this.isLaneDrilldown ? "laneDrilldown" :
                    this.isDashboard ? "dashboard" : "drilldown";
    },

    methods: {
        handleAccessorial() {
            this.isTableLoading = true;
            if (this.timePeriod == null) {
                this.timePeriod = (
                (new Date(this.endDate).getTime() -
                    new Date(this.startDate).getTime()) /
                    (24 * 60 * 60 * 1000) +
                1
                ).toFixed(0);
            }

            if (this.showAggTabs == true) {
                this.$emit('parseURL')
            } else {
                this.$emit('handleFilters')
            }
        },


        repriceNetworkSelection() {
            this.showSelect = !this.showSelect;
        },

        submitNetworkSelection() {
            const broker = stateAPI.getStateProperty(this, "broker");
            const shipper = stateAPI.getStateProperty(this, "shipper")
            const timePeriod = ((new Date().getTime() - new Date(this.startDate).getTime()) / (24 * 60 * 60 * 1000) + 1).toFixed(0);
            const startDate = timePeriod > 30 ? new Date(new Date().setDate(new Date().getDate() - 30)) : this.startDate;
            if (this.selectedLanes == null || Object.keys(this.selectedLanes) != 0) {
                this.$router.push({
                name: "calculator",
                params: {
                    prop_selected: this.selectedLanes,
                    prop_id: this.isBrokerUser ? shipper : broker,
                },
                });
            }
        },

        filterTable(val) {
            if (val === this.searchPrev) {
                return
            } else {
                this.searchPrev = val
                this.isTableLoading = true;
                this.$emit("parseURL", true);
            }
        },

        clearTable() {
            this.searchTable = null;
            this.$emit('busEvent', this.tableBus)
            this.filterTable();
        },

        viewTogglehandler(val) {
            // Emit toggle event for table bundle to handle
            this.$emit('toggleView', val)
        },

        focusLanesToggle() {
            this.focusFieldsStore = this.focusFieldModel;
            this.focusMenu = false;
            this.focusLaneButtonColor = this.focusFieldModel.length == 0 ? "inherit" : "#2AC08E";
            this.$emit("focusLanes", Array.isArray(this.focusFieldModel) ? this.focusFieldModel : [this.focusFieldModel ]);
        },

        focusLaneCancel() {
            this.focusMenu = false;
            this.focusFieldModel = this.focusFieldsStore;
        },

        storeFocusFields() {
            this.focusFieldModel = this.focusFieldsStore;
        },

        clearFocus() {
            this.focusFieldModel = [];
            this.focusLanesToggle();
        },

        getFavoriteLanes() {
            let drilldownId = "";
            if (this.isBrokerUser) {
                drilldownId = stateAPI.getStateProperty(this, "shipper");
            } else if (!this.isBrokerUser) {
                drilldownId = stateAPI.getStateProperty(this, "broker");
            }

            const favoriteLanes = stateAPI.getStateProperty(this, "favoriteLanes")[drilldownId];

            if (favoriteLanes != undefined && favoriteLanes.length != 0) {
                this.isFavoritesApplied = !this.isFavoritesApplied;
                this.$emit("favoriteLanes", this.isFavoritesApplied ? favoriteLanes : []);
            } else {
                this.isFavoritesApplied = false;
            }
        }

    },
};
</script>

<style lang="scss">
.tableSearch .v-input__slot {
    padding-left: 8px !important;
}
.tableSearch .v-input__prepend-outer {
    margin: 0 !important;
}
</style>
