var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"5","height":"100%","loading":_vm.isLoading}},[_c('v-card-title',{staticClass:"font-weight-light pa-2"},[_c('TableBar',_vm._g({ref:"bar",attrs:{"is_broker_user":_vm.isBrokerUser,"is_lane_drilldown":_vm.isLaneDrilldown,"is_lane_data":_vm.isLaneData,"table_type":_vm.tableType,"is_dashboard":_vm.isDashboard,"selected_lanes":_vm.selectedLanes,"is_table_loading":_vm.isTableLoading,"is_dropdown_loading":_vm.isDropdownLoading,"time_period":_vm.timePeriod,"start_date":_vm.startDate,"end_date":_vm.endDate},on:{"busEvent":_vm.handleBus,"toggleAgg":_vm.toggleAggregate,"toggleView":_vm.toggleFullView,"focusLanes":_vm.handleFocusLanes,"favoriteLanes":_vm.handleFavoriteLanes}},_vm.$listeners))],1),(_vm.displayedData && _vm.displayedData.length > 0)?_c('DataTable',{ref:"table",attrs:{"headers_array":this.headers,"headers_shipments":this.headersShipments,"displayed_data":this.displayedData,"is_shipper_dashboard":this.isDashboard,"server_items_length":_vm.serverItemsLength,"server_items_length_shipments":_vm.serverItemsLengthShipments,"is_lane_data":_vm.isLaneData,"has_incomplete_score":_vm.hasIncompleteScore,"percentToDollars":[
                this.headers,
                this.headersShipments,
                this.displayedData,
                !this.isWeeklyView,
                this.serverItemsLength,
                this.serverItemsLengthShipments,
                this.isTableLoading,
                this.isDropdownLoading ],"show_select":_vm.showSelect},on:{"dataTableOptionUpdate":_vm.handleUpdatedOptions,"dataTableOptionUpdateShipments":_vm.handleUpdatedOptionsShipments,"dataTableSelectionUpdate":_vm.handleUpdatedSelection,"laneClick":_vm.handleLaneClick}}):_c('v-data-table',{attrs:{"disable-pagination":"","hide-default-footer":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }