<template>
    <div>
        <v-row class="pb-4">
        <v-col cols="6">
            <v-text-field
            prepend-inner-icon="mdi-account"
            label="First Name *"
            required
            :rules="[rules.required, rules.alphabetic, rules.max]"
            v-model="first_name"
            @input="emitFirstName"
            >
            </v-text-field>
        </v-col>
        <v-col cols="6">
            <v-text-field
            prepend-inner-icon="mdi-account"
            label="Last Name *"
            required
            :rules="[rules.required, rules.alphabetic, rules.max]"
            v-model="last_name"
            @input="emitLastName"
            >
            </v-text-field>
        </v-col>
        </v-row>
        <VuePhoneNumberInput v-model="phone_number" :required="true" style="margin-top:10px;"></VuePhoneNumberInput>
    </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    name: "BasicInformation",

    components : {
        VuePhoneNumberInput
    },

    data: function () {
        return {
            rules: {
                required: value => !!value || "Required.",
                alphabetic: value => {
                    const pattern = /^[a-zA-Z\s]*$/
                    return pattern.test(value) || 'Invalid characters. Only letters are allowed.'
                },
                max: v => v.length <= 40 || "Max 40 characters",
                min: v => v.length >= 8 || "Min 8 characters",
                emailMatch: () => `The email and password you entered don't match`
            },

            role_type: null,
            // shipper_items: ["S.C. Johnson & Son, Inc."],
            // broker_items: ["Bluegrace Logistics"],
            phone_number: "",
            first_name: "",
            last_name: "",
            role: "",
            company: ""

        };
    },

    watch: {
        phone_number: function () {
            this.$emit("phoneNumberUpdate", this.phone_number);
        },
    },

    methods: {
        emitFirstName(){
            this.$emit("firstNameUpdate", this.first_name);
        },

        emitLastName(){
            this.$emit("lastNameUpdate", this.last_name);
        }

    },
};
</script>

<style>
.link {
    font-size: 13px;
    color: grey;
}

.login-button {
    background: linear-gradient(90deg, #ff7a10, #ff7a10) !important;
    color: white !important;
    border-radius: 100px !important;
    margin-bottom: 10px;
}
</style>
