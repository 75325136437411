<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <NavBar :prop_username="usn" />
    <SideBar />
    <v-main>
      <v-container fluid style="width: 91%" dense>
        <v-row v-if="isAdminUser" dense>
          <v-col cols="12" class="pb-0 mb-n8">
            <p class="text-end font-weight-light" style="color: grey">
              {{ dataFreshnessLabel }}
            </p>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="2" class="pa-0">
            <v-breadcrumbs
              ref="breadcrumbs"
              :items="crumbs"
              divider=">"
              large
              class="mb-0 pt-4 mt-3 mr-4"
            ></v-breadcrumbs>
          </v-col>
          <v-col cols="8" class="pa-0">
            <v-tabs
              :value="
                selectDashboardType == 'Broker Dashboard' ||
                selectDashboardType == 'Shipper Dashboard'
                  ? 0
                  : 1
              "
              class="mt-3 mb-3"
              color="rgba(0, 145, 255, 0.85)"
              background-color="transparent"
              centered
            >
              <v-tab v-if="!isBrokerUser" @click="onBrokerDashboardClick()"
                >Broker Dashboard</v-tab
              >
              <v-tab v-if="isBrokerUser" @click="onShipperDashboardClick()"
                >Shipper Dashboard</v-tab
              >
              <v-tab @click="onLaneDashboardClick()">Lanes</v-tab>
            </v-tabs>
          </v-col>
          <v-col cols="2" class="pa-0">
            <p
              ref="daterange"
              class="text-end mb-0 pt-4 mt-3 mr-4 font-weight-light"
              :style="{color: theme == 'light' ? '#545454' : 'white'}"
            >
              {{ periodDates }}
            </p>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-card elevation="5">
              <v-card-title class="font-weight-light">
                <v-icon large :color="$vuetify.theme.themes[theme].headerIcon" style="margin-right: 10px"
                  >mdi-gauge</v-icon>
                <p v-if="!isLaneData" class="ma-0">Dashboard</p>
                <p v-else class="ma-0">Lanes</p>
                <v-spacer></v-spacer>
                <v-chip
                  v-for="(keyval, index) in Array.from(filterChips)"
                  :key="index"
                  :color="$vuetify.theme.themes[theme].chipColor"
                  text-color="white"
                  class="mr-2"
                  close
                  @click:close="resetFilters(keyval[0])"
                >
                  {{ keyval[1] }}
                </v-chip>

                <Filters
                  @applySelectedFilters="handleEmmittedFilters"
                  :time_period="timePeriod"
                  :volume_threshold="volumeThreshold"
                  :is_lane_drilldown="!this.isLaneData"
                  :is_analytics="false"
                  :updateData="[this.filters, this.isComparisonEnabled]"
                />
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <v-row dense v-resize="onResize">
          <v-col cols="3">
            <v-card
              ref="gaugeCard"
              elevation="5"
              style="height: 100%"
            >
              <v-card-title class="font-weight-light pb-0"
                >Truce Score</v-card-title
              >
              <v-card-actions class="pb-1 pt-0">
                <Gauge
                  :averageScore="this.averageScore"
                  :gaugeCardWidth="this.gaugeCardWidth"
                  :browserZoom="this.browserZoom"
                />
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="5">
              <KeyMetrics
                ref="km"
                :is_loading = "isLoading"
                :key_metrics="key_metrics"
                :is_dashboard_km="true"
                :updateKeyMetrics="[keyMetrics]"
                :show_agg_tabs="showAggTabs"
                @periodTabChange = handleKeyMetricTabChange
                :is_period_null = isPeriodNull
                :is_comparison_enabled = isComparisonEnabled
                :query_in_progress = queryInProgress
                :key_metrics_toggle = keyMetricsToggle
                :old_period_dates = oldPeriodDates
                :period_dates = periodDates
              />
          </v-col>

          <v-col cols="4">
            <v-card elevation="5" style="height: 100%">
              <v-card-title
                class="font-weight-light pb-0 mtCardTitle"
                style="overflow-y: hidden; white-space: nowrap"
              >
                <v-row>
                  <v-col cols="lg-4 md-2" class="pb-0" style="z-index: 2;">Metric Trends</v-col>
                  <v-col cols="lg-8 md-2" class="mtTabCol">
                    <v-tabs
                      color="#0091ff"
                      right
                      show-arrows
                      v-model="metricTrendTypeTabs"
                      class="metricTrendTabs"
                    >
                      <v-tooltip top id="remove-v-tooltip" style="margin-left:auto;">
                        <template #activator="{on}">
                          <v-tab
                            :disabled="
                              disableMetricTrendCPM ||
                              disableMetricTrendMPL ||
                              disableMetricTrendVOL
                            "
                            v-on="on"
                            @click="changeMetricTrend('CPM', 0)"
                            >S|CPM</v-tab
                          >
                        </template>
                        <span>Customer Spend Per Mile and Broker Truck Cost Per Mile</span>
                      </v-tooltip>
                      <v-divider vertical inset></v-divider>
                      <v-tooltip top>
                        <template #activator="{on}">
                          <v-tab
                            :disabled="
                              disableMetricTrendCPM ||
                              disableMetricTrendMPL ||
                              disableMetricTrendVOL
                            "
                            v-on="on"
                            @click="changeMetricTrend('MPL', 1)"
                            >MPL</v-tab
                          >
                        </template>
                        <span>Margin per Load</span>
                      </v-tooltip>
                      <v-divider vertical inset></v-divider>
                      <v-tooltip top>
                        <template #activator="{on}">
                          <v-tab
                            :disabled="
                              disableMetricTrendCPM ||
                              disableMetricTrendMPL ||
                              disableMetricTrendVOL
                            "
                            v-on="on"
                            @click="changeMetricTrend('VOL', 2)"
                            >VOL</v-tab
                          >
                        </template>
                        <span>Volume</span>
                      </v-tooltip>
                    </v-tabs>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-actions class="justify-center pt-0">
                <Graph
                  :rawData="metricTrends"
                  :graphName="metricTrendType"
                  :timeRange="timePeriod"
                  :isBroker="!isLaneData"
                ></Graph>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <TableBundle
              ref="bundle"
              :is_loading="false"
              :is_broker_user="isBrokerUser"
              :is_lane_drilldown="false"
              :is_lane_data="isLaneData"
              :displayed_data="displayedData"
              :has_incomplete_score="null"
              @parseURL="parseURLPathName"
              @handleFilters="handleEmmittedFilters"
              @focusLanes="handleFocusLanes"
              @laneClick="handleLaneClick"
              :time_period="timePeriod"
              :start_date="startDate"
              :end_date="endDate"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="laneDrilldown" width="80%" overlay-color="black">
        <LaneDrilldown
          :drilldown_object="drilldownObject"
          :prop_broker="isBrokerUser"
          @stopLoading="isLoading=false"
        />
      </v-dialog>
    </v-main>
    <SplashScreen style="z-index: 220" :is_loading="isLoading"/>
  </v-app>
</template>

<script>
import NavBar from "../components/NavBar";
import Gauge from "../components/Gauge";
import SideBar from "../components/SideBar";
import TableBundle from "../containers/TableBundle";
import KeyMetrics from "../components/KeyMetrics";
import Graph from "../components/Graph";
import SplashScreen from "../components/SplashScreen";
import Filters from "../components/Filters";
import LaneDrilldown from "../components/LaneDrilldown";
import * as user_analytics from "../analytics/sendAnalyticsEvent";
import * as format from "../formatShipmentData";
import * as stateAPI from "../stateAPI";
import * as utils from "../utils";
import * as computeKeyMetrics from "../computeKeyMetrics";
import * as fetchAccountDetails from "../fetchAccountDetails";
import _ from "lodash";

export default {
  name: "ShipperDashboard",
  props: ["prop_usn"],

  components: {
    SplashScreen,
    NavBar,
    SideBar,
    Gauge,
    TableBundle,
    KeyMetrics,
    Graph,
    Filters,
    LaneDrilldown
  },

  watch: {
    laneDrilldown(isOpen) {
      if (!isOpen) {
        document.documentElement.style.overflow = "auto";
      } else { 
        document.documentElement.style.overflow = "hidden";
      }
    }
  },

  computed: {
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light';
    }
  },

  methods: {
    formatScore: format.formatScore,
    formatPercent: format.formatPercent,
    formatDollars: format.formatDollars,
    formatDecimal: format.formatDecimal,
    getColor: utils.getColor,

    resetFilters(objType) {
      utils.resetFilters(
        objType,
        this.endDate,
        this.timePeriod,
        this.tickLabels,
        this.filterChips,
        this.volumeThreshold,
        this.isCustomerDirectEnabled,
        this
      );

      this.filters.isCustomerDirectEnabled = this.isCustomerDirectEnabled;
      if (this.filterChips.size == 0) {
        this.showFilterChips = false;
        this.showAggTabs = true;
        this.parseURLPathName();
      } else {
        this.handleEmmittedFilters(
          this.endDate,
          this.timePeriod,
          this.volumeThreshold,
          this.selectedEquipmentTypes,
          false,
          this.filters
        );
      }
    },

    handleEmmittedFilters(
      date = this.endDate,
      timePeriod = this.timePeriod,
      volumeThreshold = this.volumeThreshold,
      equipmentTypes = this.selectedEquipmentTypes,
      isComparisonEnabled = this.isComparisonEnabled,
      filters = this.filters
    ) {
      const milliToDay = 1000 * 60 * 60 * 24;

      // store filters state
      this.filters = filters;

      // set time period
      this.timePeriod = timePeriod;
      stateAPI.setStateProperty(this, "storeTimePeriod", this.timePeriod);

      // set end date
      this.endDate = new Date(new Date(date).setHours(5, 0, 0));
      stateAPI.setStateProperty(this, "storeEndDate", this.endDate);

      // calculate start date
      var start_offset = parseInt(timePeriod);
      this.startDate = new Date(
        new Date(
          new Date(this.endDate).getTime() -
          start_offset * milliToDay
        ).setHours(5, 0, 0)
      );
      this.load = false;

      this.isPeriodOneDay =
        this.endDate.toLocaleDateString() == this.startDate.toLocaleDateString()
          ? true
          : false;

      // calculate delta end date
      this.deltaEndDate = new Date(
        new Date(
          new Date(this.endDate).getTime() - parseInt(timePeriod) * milliToDay
        ).setHours(5, 0, 0)
      );

      // calculate delta start date
      this.deltaStartDate = new Date(
        new Date(
          this.deltaEndDate.getTime() - (parseInt(timePeriod) - 1) * milliToDay
        ).setHours(5, 0, 0)
      );

      // set volume threshold
      this.volumeThreshold = volumeThreshold;
      stateAPI.setStateProperty(
        this,
        "storeVolumeThreshold",
        this.volumeThreshold
      );

      // set equipment types
      this.selectedEquipmentTypes = equipmentTypes;

      // set customer direct flag
      this.isCustomerDirectEnabled = filters.isCustomerDirectEnabled;
      stateAPI.setStateProperty(this, "storeIsCustomerDirectEnabled", this.isCustomerDirectEnabled);

      // set new filter chips - call from utils
      // dont display timePeriod if enddate is the only filter chip
      if (!(this.filterChips.size == 1 && this.filterChips.has("enddate"))) {
        this.setFilterChips(date, timePeriod, volumeThreshold, equipmentTypes, filters.isCustomerDirectEnabled);
      }

      // toggle off tabs
      this.showFilterChips = this.filterChips.size == 0 ? false : true;
      this.showAggTabs = !this.showFilterChips;
      this.keyMetricsToggle = null;
      stateAPI.setStateProperty(
        this,
        "storeKeyMetricsToggle",
        this.keyMetricsToggle
      );

      this.isComparisonEnabled = isComparisonEnabled;
      stateAPI.setStateProperty(
        this,
        "storeIsComparisonEnabled",
        this.isComparisonEnabled
      );

      this.parseURLPathName();
    },

    setFilterChips(date, timePeriod, volumeThreshold, selectedEquipmentTypes, isCustomerDirectEnabled) {
      var newFilterChips = new Map();

      const defaultStartDate = new Date(
        new Date(stateAPI.getStateProperty(this, "startDate")).setHours(5, 0, 0)
      );

      const defaultEndDate = new Date();
      const defaultTimePeriod = (
        (new Date(defaultEndDate).getTime() -
          new Date(defaultStartDate).getTime()) /
          (24 * 60 * 60 * 1000) +
        1
      ).toFixed(0);

      if (
        new Date(date).toLocaleDateString() !=
          defaultEndDate.toLocaleDateString() ||
        new Date(this.startDate).toLocaleDateString() !=
          defaultStartDate.toLocaleDateString()
      ) {
        newFilterChips = utils.updateFilterChips(
          "dateRange",
          "Date Range: ",
          this.isPeriodOneDay
            ? [new Date(date).toLocaleDateString()]
            : [
                new Date(this.startDate).toLocaleDateString() +
                  " - " +
                  new Date(date).toLocaleDateString(),
              ],
          newFilterChips.size != 0 ? newFilterChips : this.filterChips
        );
      }

      if (volumeThreshold > 0) {
        newFilterChips = utils.updateFilterChips(
          "volumeThreshold",
          "Volume: ",
          [parseInt(volumeThreshold)],
          newFilterChips.size != 0 ? newFilterChips : this.filterChips
        );
      }

      var unselectedEquipmentTypes = new Set(this.EQUIPMENT_TYPE_LIST);
      // Update filter chips based on equipment types selected in filter menu.

      if (unselectedEquipmentTypes.size != selectedEquipmentTypes.length) {
        for (let i = 0; i < selectedEquipmentTypes.length; i++) {
          const equipmentType = selectedEquipmentTypes[i];
          // This equipment type was selected, so prune it from the unselected set.
          unselectedEquipmentTypes.delete(equipmentType);
          // if (this.isApplyClicked) {
          newFilterChips = utils.updateFilterChips(
            "equipment" + "_" + equipmentType,
            "Equip. Type: ",
            [equipmentType],
            newFilterChips.size != 0 ? newFilterChips : this.filterChips
          );
          // }
        }
      }

      if (isCustomerDirectEnabled) {
        newFilterChips = utils.updateFilterChips("isCustomerDirectEnabled", "Customer Direct", [""], newFilterChips.size != 0 ? newFilterChips : this.filterChips);
      }

      this.filterChips = newFilterChips;
    },

    handleKeyMetricTabChange(tabNumber) {
      this.isLoading = true;
      this.keyMetricsToggle = tabNumber;
      stateAPI.setStateProperty(
        this,
        "storeKeyMetricsToggle",
        this.keyMetricsToggle
      );
      var tp = this.tabValues[tabNumber];
      if (tp == undefined || tp == null) {
        const today = new Date(new Date().setHours(5, 0, 0));
        const start = new Date(
          new Date(stateAPI.getStateProperty(this, "startDate")).setHours(
            1,
            0,
            0
          )
        );
        tp = (
          (today.getTime() - start.getTime()) / (24 * 60 * 60 * 1000) +
          1
        ).toFixed(0);
        this.endDate = today;
        this.startDate = start;
      }

      this.handleKeyMetricTabSelected(tp);

      //force resize to align bar under selector
      this.$refs.selectorTabs && this.$refs.selectorTabs.onResize();
    },

    handleKeyMetricTabSelected(timePeriod) {
      // calculate delta end date
      this.deltaEndDate = new Date(
        new Date(
          new Date(this.endDate).getTime() -
            parseInt(timePeriod) * 1000 * 60 * 60 * 24
        ).setHours(5, 0, 0)
      );

      // calculate start date
      this.startDate = new Date(
        new Date(
          new Date(this.endDate).getTime() -
            (parseInt(timePeriod) - 1) * 1000 * 60 * 60 * 24
        ).setHours(5, 0, 0)
      );
      this.deltaStartDate = new Date(
        new Date(
          this.deltaEndDate.getTime() -
            (parseInt(timePeriod) - 1) * 1000 * 60 * 60 * 24
        ).setHours(5, 0, 0)
      );

      //show tabs
      this.showAggTabs = true;
      this.showFilterChips = true;

      //set filter param
      const tickValIdx = this.tickValues.indexOf(timePeriod);
      this.filters.timeSlider = tickValIdx != -1 ? tickValIdx : 5;

      // set timePeriod
      this.timePeriod = timePeriod;

      this.setFilterChips(
        this.endDate,
        timePeriod,
        this.volumeThreshold,
        this.selectedEquipmentTypes
      );

      this.parseURLPathName();
    },

    computeKeyMetricsFilters() {
      const showChange =
        this.filterChips.size == 0 && this.keyMetricsToggle == null
          ? false
          : this.isComparisonEnabled;
      computeKeyMetrics.getKeyMetricsForTabs(
        this.key_metrics,
        showChange,
        this,
        true
      );
    },


    onBrokerDashboardClick() {
      this.$router.push({
        name: "broker-dashboard",
        params: {
          prop_usn: this.prop_usn,
        },
      });
    },

    onShipperDashboardClick() {
      this.$router.push({
        name: "shipper-dashboard",
        params: {
          prop_usn: this.prop_usn,
        },
      });
    },

    onLaneDashboardClick() {
      this.$router.push({
        name: "lane-dashboard",
        params: {
          prop_usn: this.prop_usn,
        },
      });
    },

    clearState() {
      stateAPI.setStateProperty(this, "first_load", true);
      stateAPI.setStateProperty(this, "broker_list", []);
      stateAPI.setStateProperty(this, "shipper_list", []);
      stateAPI.setStateProperty(this, "lane_list", []);
    },

    parseURLPathName(isTableChange = false) {
      this.queryInProgress = true;
      var url_string = window.location.href;
      var url = new URL(url_string);
      var selectDashboardType;
      this.isLaneData = false;
      if (url.pathname === "/app/brokerDashboard") {
        selectDashboardType = "Broker Dashboard";
      } else if (url.pathname === "/app/shipperDashboard") {
        selectDashboardType = "Shipper Dashboard";
      } else if (url.pathname === "/app/laneDashboard") {
        selectDashboardType = "Lanes";
        // Only Lane dashboard shows lanes
        this.isLaneData = true;
      }
      utils.getData(this, "dashboard", isTableChange).then((result) => {
        utils.formatDates(this.startDate, this.endDate, this.timePeriod, this);
        this.computeKeyMetricsFilters();
        this.metricTrendTypeTabs = this.metricTrendTypeIndex;
        this.allowSecondLoad = true;
        this.queryInProgress = false;
        this.isLoading = false;
      });
      return selectDashboardType;
    },

    onResize() {
      this.gaugeCardWidth = this.$refs.gaugeCard.$el.clientWidth;
      this.browserZoom = Math.round(window.devicePixelRatio * 100);
      // this.gaugeCardHeight = this.$refs.gaugeCard.$el.clientHeight;

      // Get width of metric trend tabs
      const mtCardWidth = document.querySelector('.mtCardTitle').clientWidth;
      if (mtCardWidth < 400) {
        // Change tab font size
        document.querySelectorAll('.metricTrendTabs .v-tab').forEach((tab) => {
          tab.style.fontSize = '.7rem';
        });
        // Remove right side padding from mt tab col
        document.querySelector('.mtTabCol').style.paddingRight = '0px';
      } else {
        // revert tab font size
        document.querySelectorAll('.metricTrendTabs .v-tab').forEach((tab) => {
          tab.style.fontSize = '.9rem';
        });
        // Add right side padding to mt tab col
        document.querySelector('.mtTabCol').style.paddingRight = '12px';
      }
    },

    changeMetricTrend(metricType, index) {
      if (
        !this.disableMetricTrendCPM &&
        !this.disableMetricTrendMPL &&
        !this.disableMetricTrendVOL
      ) {
        this.metricTrendType = metricType;
        this.metricTrendTypeIndex = index;
        if (metricType == "CPM") {
          this.metricTrends = _.cloneDeep(this.cpmGraphArray);
        } else if (metricType == "MPL") {
          this.metricTrends = _.cloneDeep(this.mplGraphArray);
        } else if (metricType == "VOL") {
          this.metricTrends = _.cloneDeep(this.volGraphArray);
        }
      }
    },

    handleFocusLanes(val) {
      this.isLoading = true;
      this.focusLanes = val;
      this.parseURLPathName(true);
    },

    handleLaneClick(event) {
      this.isLoading = true;
      this.drilldownObject = event; 
      this.laneDrilldown = true;
    }
  },

  beforeMount() {
    window.component = this;
    window.api = stateAPI;
    window.clear = this.clearState;
    const url = new URL(window.location.href)
    var url2 = new URL(url);
    if (url2.pathname === "/app/brokerDashboard") {
      this.selectDashboardType = "Broker Dashboard";
    } else if (url2.pathname === "/app/shipperDashboard") {
      this.selectDashboardType = "Shipper Dashboard";
    } else if (url2.pathname === "/app/laneDashboard") {
      this.selectDashboardType = "Lanes";
    }

    if (stateAPI.getStateProperty(this, "first_load")) {
      fetchAccountDetails.getAccountDetails({email:stateAPI.getStateProperty(this, "email")}).then(result=>{
        this.startDate = new Date(stateAPI.getStateProperty(this, "startDate"));
        const today = new Date(new Date().setHours(5, 0, 0));
        this.timePeriod = ((today.getTime() - this.startDate.getTime()) / (24 * 60 * 60 * 1000)).toFixed(0)

        const kmt = stateAPI.getStateProperty(this, "storeKeyMetricsToggle");
        if (kmt == null || kmt == undefined) {
          this.handleEmmittedFilters(
            this.endDate,
            this.timePeriod,
            this.volumeThreshold,
            this.selectedEquipmentTypes,
            this.isComparisonEnabled,
            this.filters
          );
        } else {
          this.handleKeyMetricTabChange(kmt);
        }

        // this.parseURLPathName();
        this.crumbs = [
          {
            text: this.selectDashboardType,
            disabled: true,
            href: new URL(window.location.href).pathname,
            equipmentType: null,
          },
        ];
        stateAPI.setStateProperty(this, "crumbs", this.crumbs);
        stateAPI.setStateProperty(this, "crumbIds", ["dashboard"]);

        stateAPI.getStateProperty(this, "storeTimePeriod");
        stateAPI.getStateProperty(this, "storeVolumeThreshold");
        stateAPI.getStateProperty(this, "storeSelectedEquipmentType");
      })

    } else {
      const kmt = stateAPI.getStateProperty(this, "storeKeyMetricsToggle");
      if (kmt == null || kmt == undefined) {
        this.handleEmmittedFilters(
          this.endDate,
          this.timePeriod,
          this.volumeThreshold,
          this.selectedEquipmentTypes,
          this.isComparisonEnabled,
          this.filters
        );
      } else {
        this.handleKeyMetricTabChange(kmt);
      }

      // this.parseURLPathName();
      this.crumbs = [
        {
          text: this.selectDashboardType,
          disabled: true,
          href: new URL(window.location.href).pathname,
          equipmentType: null,
        },
      ];
      stateAPI.setStateProperty(this, "crumbs", this.crumbs);
      stateAPI.setStateProperty(this, "crumbIds", ["dashboard"]);

      stateAPI.getStateProperty(this, "storeTimePeriod");
      stateAPI.getStateProperty(this, "storeVolumeThreshold");
      stateAPI.getStateProperty(this, "storeSelectedEquipmentType");
    }

    // Send pageView analytics event
    const email = stateAPI.getStateProperty(this, "email");
    const company = stateAPI.getStateProperty(this, "company");
    const role = stateAPI.getStateProperty(this, "role");
    user_analytics.sendEvent(email, company, role, "pageView", { pageSource: this.selectDashboardType.replaceAll(' ', '') });
  },

  mounted: function () {
    var metric_el = document.getElementById('remove-v-tooltip');
    metric_el.classList.remove('v-tooltip');
    metric_el.classList.remove('v-tooltip--top');

    // stateAPI.setStateProperty(this, "first_load", false);
    this.gaugeCardHeight = this.$refs.gaugeCard.$el.clientHeight;
    this.gaugeCardWidth = this.$refs.gaugeCard.$el.clientWidth;
    this.browserZoom = Math.round(window.devicePixelRatio * 100);
  },

  data: function () {
    return {
      allowSecondLoad: false,
      endDate: new Date(
        new Date(stateAPI.getStateProperty(this, "storeEndDate")).setHours(
          5,
          0,
          0
        )
      ),
      keyMetricsToggle: null,
      keyMetrics: null,
      dataFreshnessLabel: "",
      showAggTabs: true,
      defaultTabValue: null,
      averageScore: 0,
      isLaneData: false,
      isLoading: true,
      displayedData: [],
      originalData: [],
      selectDashboardType: this.prop_select_dashboard,
      metricTrends: null,

      // Lane Drilldown popup
      drilldownObject: null,
      laneDrilldown: false,

      percentColor: "#0091ff",
      centerShift: "margin-right:-43px;",

      value: 0,
      timePeriod: stateAPI.getStateProperty(this, "storeTimePeriod"),
      tickLabels: ["Last 7", "Last 14", "Last 30", "Last 60", "Last 90", "All"],
      tickValues: [7, 14, 30, 60, 90],

      selectedEquipmentTypes: stateAPI.getStateProperty(
        this,
        "equipment_type_list"
      ),
      // EQUIPMENT_TYPE_LIST: globalVariables.default.equipment_type_list,
      EQUIPMENT_TYPE_LIST: stateAPI.getStateProperty(
        this,
        "equipment_type_list"
      ),

      index_by: "brokerId",
      index_by_lane: "laneId",
      index_by_shipper: "shipperId",

      usn: this.prop_usn,
      date: new Date().toISOString().substring(0, 10),

      key_metrics: [
        {
          value: "0%",
          title: "Avg. Margin",
          icon: "",
          color: "",
          id: "avg_margin",
          change: "",
        },
        {
          value: "0$",
          title: "MPL",
          icon: "",
          color: "",
          id: "avg_margin_dollars",
          change: "",
        },
        {
          value: "0$",
          title: "Total Margin",
          icon: "",
          color: "",
          id: "total_margin",
          change: "",
        },
        {
          value: "0$",
          title: "Total Spend",
          icon: "",
          color: "",
          id: "total_spend",
          change: "",
        },
        {
          value: "0$",
          title: "SPL",
          icon: "",
          color: "",
          id: "avg_spend",
          change: "",
        },
        {
          value: "0",
          title: "Volume",
          icon: "",
          color: "",
          id: "total_volume",
          change: "",
        },
        {
          value: "0 Days",
          title: "Avg. CLT",
          icon: "",
          color: "",
          id: "avg_clt",
          change: "",
        },
        {
          value: "0 Days",
          title: "Avg. BLT",
          icon: "",
          color: "",
          id: "avg_blt",
          change: "",
        },
        {
          value: "0 Days",
          title: "Avg. Pre-Book",
          icon: "",
          color: "",
          id: "avg_prebook",
          change: "",
        },
        {
          value: "0%",
          title: "On-Time Pickup",
          icon: "",
          color: "",
          id: "avg_otp",
          change: "",
        },
        {
          value: "0%",
          title: "On-Time Delivery",
          icon: "",
          color: "",
          id: "avg_otd",
          change: "",
        },
        {
          value: "0%",
          title: "Prebook",
          icon: "",
          color: "",
          id: "prebook_percent",
          change: "",
        },
        {
          value: "0%",
          title: "Avg. Margin",
          icon: "",
          color: "",
          id: "proj_avg_margin",
          change: "",
        },
        {
          value: "0$",
          title: "MPL",
          icon: "",
          color: "",
          id: "proj_avg_margin_dollars",
          change: "",
        },
        {
          value: "0$",
          title: "Total Margin",
          icon: "",
          color: "",
          id: "proj_total_margin",
          change: "",
        },
        {
          value: "0$",
          title: "Total Spend",
          icon: "",
          color: "",
          id: "proj_total_spend",
          change: "",
        },
        {
          value: "0$",
          title: "SPL",
          icon: "",
          color: "",
          id: "proj_avg_spend",
          change: "",
        },
        {
          value: "0",
          title: "Volume",
          icon: "",
          color: "",
          id: "proj_total_volume",
          change: "",
        }
      ],

      crumbs: [
        {
          text: this.selectDashboardType,
          disabled: true,
          href: new URL(window.location.href).pathname,
        },
      ],
      periodDates: "",
      oldPeriodDates: "",
      filterChips: new Map(),
      volumeThreshold: stateAPI.getStateProperty(this, "storeVolumeThreshold"),

      gaugeCardWidth: 0,
      gaugeCardHeight: 0,
      browserZoom: 100,
      showStartDate: true,

      metricTrendType: "CPM",
      metricTrendTypeTabs: "CPM",
      metricTrendTypeIndex: 0,
      isPeriodOneDay: false,
      isPeriodNull: true,
      startDate: '',
      isBrokerUser: stateAPI.getStateProperty(this, "role") == "broker",
      isAdminUser: stateAPI.getStateProperty(this, "role") == "admin",
      deltaStartDate: null,
      deltaEndDate: null,
      showFilterChips: false,
      cpmGraphArray: [],
      mplGraphArray: [],
      volGraphArray: [],
      isComparisonEnabled: stateAPI.getStateProperty(
        this,
        "storeIsComparisonEnabled"
      ),
      tabValues: [14, 30, 60],
      filters: {
        dates: stateAPI.getStateProperty(this, "storeDates"),
        timeSlider: stateAPI.getStateProperty(this, "storeTimeSlider"),
        volume: stateAPI.getStateProperty(this, "storeVolumeThreshold"),
        equipment: stateAPI.getStateProperty(this, "equipment_type_list"),
        isCustomerDirectEnabled: stateAPI.getStateProperty(this, "storeIsCustomerDirectEnabled")
      },
      isDashboard: true,
      disableMetricTrendCPM: true,
      disableMetricTrendMPL: true,
      disableMetricTrendVOL: true,
      load: true,
      queryInProgress: false,
      isCustomerDirectEnabled: stateAPI.getStateProperty(this, "storeIsCustomerDirectEnabled"),
      focusLanes: []
    };
  },
};
</script>

<style lang="scss">
.green {
  color: green;
}
.red {
  color: red;
}

.metric-value {
  font-weight: 400;
  font-size: 23px;
}

tbody {
  tr:hover {
    cursor: pointer;
  }
}

ul {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.metricTrendTabs .v-tab{
  min-width: fit-content;
}

.theme--dark {
  .v-card {
    background-color: var(--v-cardBackgroundColor-base) ;
  }
  .moneyTab {
    background-color: var(--v-cardBackgroundColor-base) ;
  }
  .projectionTab {
    background-color: var(--v-cardBackgroundColor-base) ;
  }
  .performanceTab {
    background-color: var(--v-cardBackgroundColor-base) ;
  }
  .selectorDateTabs .v-slide-group {
    background-color: var(--v-cardBackgroundColor-base)  !important;
  }
  .metricTrendTabs .v-slide-group {
    background-color: var(--v-cardBackgroundColor-base) !important;
  }
  .metric-value {
    color: var(--v-metricValueColor-base);
  }
  .darkModeSwitch .v-label {
    color: var(--v-metricValueColor-base);
  }
}


</style>
