<template>
  <v-data-table
    :headers="!showSelect ? headers : truncated_headers"
    :items="displayedData"
    :expanded.sync="expanded"
    :item-key="!isLaneData ? ( isBrokerUser ? 'shipperId' : 'brokerId' ) : 'laneId'"
    :show-expand="!isShipperDashboard"
    single-expand
    :show-select="showSelect"
    class="elevation-1 dataTable"
    :footer-props="{
      'items-per-page-options': [10, 25, 50, 100],
    }"
    :items-per-page="100"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    @click:row="rowClick"
    @update:options="emitUpdatedOptions"
    :server-items-length.sync="serverItemsLength"
    @item-expanded="updatePageNumber"
    :item-class="itemRowWidth"
    :loading="isTableLoading"
    @item-selected="emitSelectedOptions"
    @toggle-select-all="emitSelectedOptions($event)"
    v-model="selected"
  >
    <template v-slot:header.name="{ header }">
      <Tooltip :header_text="header.text" :header_tool="header.tooltip" />
    </template>
    <template v-slot:header.origin="{ header }">
      <Tooltip :header_text="header.text" :header_tool="header.tooltip" />
    </template>
    <template v-slot:header.destination="{ header }">
      <Tooltip :header_text="header.text" :header_tool="header.tooltip" />
    </template>
    <template v-slot:header.score="{ header }">
      <Tooltip :header_text="header.text" :header_tool="header.tooltip" />
    </template>
    <template v-slot:header.equipmenttype="{ header }">
      <Tooltip :header_text="header.text" :header_tool="header.tooltip" />
    </template>
    <template v-slot:header.total_margin="{ header }">
      <Tooltip :header_text="header.text" :header_tool="header.tooltip" />
    </template>
    <template v-slot:header.avg_margin="{ header }">
      <v-tooltip top max-width="25%">
        <template v-slot:activator="{ on }">
          <span v-on="on">{{ header.text }}</span>
        </template>
        <span>{{ header.tooltip }}</span>
      </v-tooltip>
    </template>
    <template v-slot:header.avg_margin_dollars="{ header }">
      <v-tooltip top max-width="25%">
        <template v-slot:activator="{ on }">
          <span v-on="on">{{ header.text }}</span>
        </template>
        <span>{{ header.tooltip }}</span>
      </v-tooltip>
    </template>
    <template v-slot:header.avg_clt="{ header }">
      <Tooltip :header_text="header.text" :header_tool="header.tooltip" />
    </template>
    <!-- LTU -->
    <template v-slot:header.avg_blt="{ header }">
      <Tooltip :header_text="header.text" :header_tool="header.tooltip" />
    </template>
    <template v-slot:header.avg_prebook="{ header }">
      <Tooltip :header_text="header.text" :header_tool="header.tooltip" />
    </template>
    <template v-slot:header.otp="{ header }">
      <Tooltip :header_text="header.text" :header_tool="header.tooltip" />
    </template>
    <template v-slot:header.avg_otd="{ header }">
      <Tooltip :header_text="header.text" :header_tool="header.tooltip" />
    </template>
    <template v-slot:header.volume="{ header }">
      <Tooltip :header_text="header.text" :header_tool="header.tooltip" />
    </template>
    <template v-slot:header.avg_cogs="{ header }">
      <Tooltip :header_text="header.text" :header_tool="header.tooltip" />
    </template>
    <template v-slot:header.avg_revenue="{ header }">
      <Tooltip :header_text="header.text" :header_tool="header.tooltip" />
    </template>
    <template v-slot:header.avg_miles="{ header }">
      <Tooltip :header_text="header.text" :header_tool="header.tooltip" />
    </template>
    <template
      v-if="!this.isShipperDashboard && !this.showSelect"
      v-slot:item.gotoDrilldownIcon="{ item }"
    >
      <v-tooltip top max-width="25%" open-delay="1250">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="isLaneData"
              icon
              v-bind="attrs"
              v-on="on"
              @click.stop.prevent="updateFavoriteLanes(item)"
              small
            >
              <v-icon :color="isFavoriteLane(item) ? 'orange' : 'grey' " small>
                {{ isFavoriteLane(item) ? "mdi-star" : "mdi-star-outline" }}
              </v-icon>
            </v-btn>
        </template>
        <span>Favorite Lane</span>
      </v-tooltip>
      <v-tooltip top max-width="25%" open-delay="1250">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-icon
              style="font-size: 18px !important"
              @click.stop.prevent="gotoItemDrilldown(item)"
              class="gotoIcon"
            >
                mdi-forwardburger
            </v-icon>
          </span>
        </template>
        <span>{{ getGotoDrilldownTooltip(item) }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.score="{ item }">
      <v-menu :close-on-content-click="false" :nudge-width="220" offset-x offset-y transition="scroll-x-transition">
        <template v-slot:activator="{ on }">
          <v-chip v-if="showBadgeTooltip(item.avg_carrier_score, item.avg_broker_score, item.avg_load_creation_time)" :color="getColor(item.score)" dark v-on="on">
            <v-tooltip top open-delay="1000">
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >{{ formatScore(item.score) }}</span>
              </template>
              <span>Incomplete Score</span>
            </v-tooltip>
          </v-chip>
          <v-chip v-else :color="getColor(item.score)" dark v-on="on">
            {{ formatScore(item.score) }}
          </v-chip>
        </template>
        <v-card class="pa-1">
          <v-card-title class="pb-0">
            Score Breakdown
          </v-card-title>
          <v-card-text>
            <v-row dense>
                <v-col cols="12">
                <v-row class="ma-0 py-2">
                  On-Time Pickup {{ formatScore(item.avg_otp_score) == '--' ? '--' : "" }}
                  <v-progress-linear
                    v-if="formatScore(item.avg_otp_score) != '--'"
                    :value="formatScore(item.avg_otp_score)"
                    :color="getColor(formatScore(item.avg_otp_score))"
                  ></v-progress-linear>
                </v-row>
                <v-row class="ma-0 py-2">
                  On-Time Delivery {{ formatScore(item.avg_otd_score) == '--' ? '--' : "" }}
                  <v-progress-linear
                    v-if="formatScore(item.avg_otd_score) != '--'"
                    :value="formatScore(item.avg_otd_score)"
                    :color="getColor(formatScore(item.avg_otd_score))"
                  ></v-progress-linear>
                </v-row>
                <v-row class="ma-0 py-2">
                  Appointment Setting {{ formatScore(item.avg_ltu_score) == '--' ? '--' : "" }}
                  <v-progress-linear
                    v-if="formatScore(item.avg_ltu_score) != '--'"
                    :value="formatScore(item.avg_ltu_score)"
                    :color="getColor(formatScore(item.avg_ltu_score))"
                  ></v-progress-linear>
                </v-row>
                <v-row class="ma-0 py-2">
                  Pre-Book {{ formatScore(item.avg_prebook_score) == '--' ? '--' : "" }}
                  <v-progress-linear
                    v-if="formatScore(item.avg_prebook_score) != '--'"
                    :value="formatScore(item.avg_prebook_score)"
                    :color="getColor(formatScore(item.avg_prebook_score))"
                  ></v-progress-linear>
                </v-row>
                <v-row class="ma-0 py-2">
                  Cost Performance
                  <v-progress-linear
                    :value="formatScore(item.avg_cost_score)"
                    :color="getColor(formatScore(item.avg_cost_score))"
                  ></v-progress-linear>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col
              cols=6
              class="justify-center"
              >
                <v-col>
                  <v-row class="justify-center scorecardMetricValue">
                    {{ formatHoursToBusinessDays(item.avg_clt, 1) }}
                  </v-row>
                  <v-row class="justify-center font-weight-light">
                    {{ "Avg. CLT" }}
                  </v-row>
                </v-col>
              </v-col>
              <v-col
              :cols="6"
              class="justify-center"
              >
                <v-col>
                  <v-row class="justify-center scorecardMetricValue">
                    {{ formatHoursToBusinessDays(item.avg_blt, 1) }}
                  </v-row>
                  <v-row class="justify-center font-weight-light">
                    {{ "Avg. BLT" }}
                  </v-row>
                </v-col>
              </v-col>
            </v-row>
            <v-row>
              <v-col
              cols=12
              class="justify-center"
              >
                <v-col>
                  <v-row class="justify-center scorecardMetricValue">
                    {{ formatHoursToBusinessDays(item.avg_prebook, 1) }}
                  </v-row>
                  <v-row class="justify-center font-weight-light">
                    {{ "Avg. Prebook" }}
                  </v-row>
                </v-col>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols=6
                class="justify-center"
              >
                <v-col>
                  <v-row class="justify-center scorecardMetricValue">
                    {{ formatPercent(item.avg_otp) }}
                  </v-row>
                  <v-row class="justify-center font-weight-light">
                    {{ "OTP" }}
                  </v-row>
                </v-col>
              </v-col>
              <v-col
                cols=6
                class="justify-center"
              >
                <v-col>
                  <v-row class="justify-center scorecardMetricValue">
                    {{ formatPercent(item.avg_otd) }}
                  </v-row>
                  <v-row class="justify-center font-weight-light">
                    {{ "OTD" }}
                  </v-row>
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
    <template v-slot:item.customer_directs="{ item }">
      {{ formatCustomerDirect(item.customer_directs) }}
    </template>
    <template v-slot:item.equipmenttype="{ item }">
      {{ item.shipmentmode && item.shipmentmode == 'IMDL' ? ('IMDL - ' + item.equipmenttype) : item.equipmenttype }}
    </template>
    <template v-slot:item.total_margin="{ item }">
      {{ formatDollars(item.total_margin) }}
    </template>
    <template v-slot:item.avg_margin="{ item }">
      {{ formatPercent(item.avg_margin, 1) }}
    </template>
    <template v-slot:item.avg_margin_dollars="{ item }">
      {{ formatDollars(item.avg_margin_dollars) }}
    </template>
    <template v-slot:item.avg_clt="{ item }">
      {{ formatHoursToBusinessDays(item.avg_clt, 1, false) }}
    </template>
    <template v-slot:item.avg_blt="{ item }">
      {{ formatHoursToBusinessDays(item.avg_blt, 1, false) }}
    </template>
    <template v-slot:item.avg_prebook="{ item }">
      {{ formatHoursToBusinessDays(item.avg_prebook, 1, false) }}
    </template>
    <template v-slot:item.otp="{ item }">
      {{ formatPercent(item.otp) }}
    </template>
    <template v-slot:item.avg_otd="{ item }">
      {{ formatPercent(item.avg_otd) }}
    </template>
    <template v-slot:item.avg_cogs="{ item }">
      {{ formatDollars(item.avg_cogs) }}
    </template>
    <template v-slot:item.avg_revenue="{ item }">
      {{ formatDollars(item.avg_revenue) }}
    </template>
    <template v-slot:item.avg_miles="{ item }">
      {{ formatDecimal(item.avg_miles, 0) }}
    </template>
    <template v-slot:item.volume="{ item }">
      {{ formatNumber(item.volume) }}
    </template>
    <template
      v-if="!isShipperDashboard"
      v-slot:expanded-item="{ headers, item }"
    >
      <td
        :colspan="headers.length"
        class="expandedTableCell"
      >
        <v-data-table
          style="border-radius: 0px"
          :headers="headersShipment"
          :items="item.shipments"
          :sort-by="isShipmentView ? 'originCloseTime' : 'week'"
          :sort-desc.sync="sortDescShipment"
          class="expandedDataTableForShipments"
          :page.sync="page"
          @update:options="emitUpdatedOptionsShipments"
          :items-per-page="8"
          :footer-props="{
            'items-per-page-options': [8, 15],
            'disable-items-per-page': true,
          }"
          :server-items-length.sync="item.shipment_row_count[0].count"
          :loading="isDropdownLoading"
          loading-text="Loading Data ..."
        >
          <template v-slot:item.week="{ item }">
            {{ formatAggregationWeek(item.week) }}
          </template>
          <template v-slot:item.originCloseTime="{ item }">
            {{ formatDate(item.originCloseTime) }}
          </template>
          <template v-if="formatScore(item.score) != '--'" v-slot:item.score="{ item }">
            <v-menu :close-on-content-click="false" :nudge-width="220" offset-x offset-y transition="scroll-x-transition">
              <template v-slot:activator="{ on }">
                <v-chip class="shipChip pa-0 text-center" outlined :color="getColor(item.score)" :text-color="getShipmentScoreChipColor()" dark v-on="on">
                  {{ formatScore(item.score) }}
                </v-chip>
              </template>
              <v-card class="pa-1">
                <v-card-title class="pb-0">
                  Score Breakdown
                </v-card-title>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-row class="ma-0 py-2">
                        On-Time Pickup {{ formatScore(item.otp_score) == '--' ? '--' : "" }}
                        <v-progress-linear
                          v-if="formatScore(item.otp_score) != '--'"
                          :value="formatScore(item.otp_score)"
                          :color="getColor(formatScore(item.otp_score))"
                        ></v-progress-linear>
                      </v-row>
                      <v-row class="ma-0 py-2">
                        On-Time Delivery {{ formatScore(item.otd_score) == '--' ? '--' : "" }}
                        <v-progress-linear
                          v-if="formatScore(item.otd_score) != '--'"
                          :value="formatScore(item.otd_score)"
                          :color="getColor(formatScore(item.otd_score))"
                        ></v-progress-linear>
                      </v-row>
                      <v-row class="ma-0 py-2">
                        Appointment Setting {{ formatScore(item.ltu_score) == '--' ? '--' : "" }}
                        <v-progress-linear
                          v-if="formatScore(item.ltu_score) != '--'"
                          :value="formatScore(item.ltu_score)"
                          :color="getColor(formatScore(item.ltu_score))"
                        ></v-progress-linear>
                      </v-row>
                      <v-row class="ma-0 py-2">
                        Pre-Book {{ formatScore(item.prebook_score) == '--' ? '--' : "" }}
                        <v-progress-linear
                          v-if="formatScore(item.prebook_score) != '--'"
                          :value="formatScore(item.prebook_score)"
                          :color="getColor(formatScore(item.prebook_score))"
                        ></v-progress-linear>
                      </v-row>
                      <v-row class="ma-0 py-2">
                        Cost Performance
                        <v-progress-linear
                          :value="formatScore(item.cost_score)"
                          :color="getColor(formatScore(item.cost_score))"
                        ></v-progress-linear>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                    cols=6
                    class="justify-center"
                    >
                      <v-col>
                        <v-row class="justify-center scorecardMetricValue">
                          {{ formatHoursToBusinessDays(item.clt, 1) }}
                        </v-row>
                        <v-row class="justify-center font-weight-light">
                          {{ "CLT" }}
                        </v-row>
                      </v-col>
                    </v-col>
                    <v-col
                    :cols="6"
                    class="justify-center"
                    >
                      <v-col>
                        <v-row class="justify-center scorecardMetricValue">
                          {{ formatHoursToBusinessDays(item.blt, 1) }}
                        </v-row>
                        <v-row class="justify-center font-weight-light">
                          {{ "BLT" }}
                        </v-row>
                      </v-col>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                    cols=6
                    class="justify-center"
                    >
                      <v-col>
                        <v-row class="justify-center scorecardMetricValue">
                          {{ formatHoursToBusinessDays(item.prebook, 1) }}
                        </v-row>
                        <v-row class="justify-center font-weight-light">
                          {{ "Prebook" }}
                        </v-row>
                      </v-col>
                    </v-col>
                    <v-col
                    :cols="6"
                    class="justify-center"
                    >
                      <v-col>
                        <v-row class="justify-center scorecardMetricValue">
                          {{ formatOTD(item.destinationDelayMinutes) == '--' ? formatOTD(item.destinationDelayMinutes) : (item.destinationDelayMinutes > 0 ? '0%' : '100%') }}
                        </v-row>
                        <v-row class="justify-center font-weight-light">
                          {{ "OTD" }}
                        </v-row>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-menu>
          </template>
          <template v-else v-slot:item.score="{ item }">
            {{ formatScore(item.score) }}
          </template>
          <template v-slot:item.customer_direct="{ item }">
            {{ formatCustomerDirect(item.customer_direct) }}
          </template>
          <template v-slot:item.revenueTotal="{ item }">
            {{ formatDollars(item.revenueTotal) }}
          </template>
          <template v-slot:item.revenue="{ item }">
            {{ formatDollars(item.revenue) }}
          </template>
          <template v-slot:item.cogsTotal="{ item }">
            {{ formatDollars(item.cogsTotal) }}
          </template>
          <template v-slot:item.cogs="{ item }">
            {{ formatDollars(item.cogs) }}
          </template>
          <template v-slot:item.margin="{ item }">
            {{ formatPercent(item.margin, 1) }}
          </template>
          <template v-slot:item.avg_margin="{ item }">
            {{ formatPercent(item.avg_margin, 1) }}
          </template>
          <template v-slot:item.margin_dollars="{ item }">
            {{ formatDollars(item.margin_dollars) }}
          </template>
          <template v-slot:item.avg_margin_dollars="{ item }">
            {{ formatDollars(item.avg_margin_dollars) }}
          </template>
          <template v-slot:item.clt="{ item }">
            {{ formatHoursToBusinessDays(item.clt, 1, false) }}
          </template>
          <template v-slot:item.blt="{ item }">
            {{ formatHoursToBusinessDays(item.blt, 1, false) }}
          </template>
          <template v-slot:item.prebook="{ item }">
            {{ formatHoursToBusinessDays(item.prebook, 1, false) }}
          </template>
          <template v-slot:item.preBook="{ item }">
            {{ formatHoursToBusinessDays(item.preBook, 1, false) }}
          </template>
          <template v-slot:item.distanceMiles="{ item }">
            {{ formatDecimal(item.distanceMiles, 2) }}
          </template>
          <template v-slot:item.miles="{ item }">
            {{ formatDecimal(item.miles, 2) }}
          </template>
          <template v-slot:item.avg_otd="{ item }">
            {{ formatPercent(item.avg_otd) }}
          </template>
          <template v-slot:item.volume="{ item }">
            {{ formatNumber(item.volume) }}
          </template>
          <template v-slot:item.destinationDelayMinutes="{ item }">
            <template v-if="formatOTD(item.destinationDelayMinutes) > 0 ||
            (formatOTD(item.destinationDelayMinutes) == '--' && item.originDepartureTime == null) ||
            (formatOTD(item.destinationDelayMinutes) == '--' && item.originDepartureTime != null && item.destinationDepartureTime != null) "
            > {{ formatOTD(item.destinationDelayMinutes) }} </template>
            <template v-else-if="formatOTD(item.destinationDelayMinutes) == '--' && item.originDepartureTime != null"> In Transit </template>
            <template v-else>
              <v-icon dense>mdi-clock-check-outline</v-icon>
            </template>
          </template>
        </v-data-table>
      </td>
    </template>
  </v-data-table>
</template>
<script>
import Tooltip from "./Tooltip";
import * as format from "../formatShipmentData";
import * as utils from "../utils";
import * as stateAPI from "../stateAPI";
import * as fetchAccountDetails from "../fetchAccountDetails";
import _ from 'lodash';

export default {
  /**
   * This component is for displaying the data table
   */
  name: "DataTable",
  props: [
    "headers_array",
    "headers_shipments",
    "displayed_data",
    "percentToDollars",
    "is_shipper_dashboard",
    "is_lane_data",
    "server_items_length",
    "server_items_length_shipments",
    "show_select",
    "has_incomplete_score"
  ],

  components: {
    Tooltip,
  },

  computed:{
    theme() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light';
    }
  },

  /**
   * listens for changes to the data variables
   * @param {Array} val (val = [headers, headersShipment, displayedData])
   * (headers is either headersBrokers or headersLanes)
   */
  //TODO - convert val to object instead of array
  watch: {
    percentToDollars: {
      handler(val) {
        this.headers = val[0];
        this.headersShipment = val[1];
        this.displayedData = val[2];
        this.isShipmentView = val[3];
        this.serverItemsLength = val[4];
        this.serverItemsLengthShipments = val[5];
        this.isTableLoading = val[6];
        this.isDropdownLoading = val[7];
      },
      deep: true
    },

    show_select(val) {
      this.showSelect = val;
    },
  },

  methods: {
    formatScore: format.formatScore,
    formatPercent: format.formatPercent,
    formatDollars: format.formatDollars,
    formatDecimal: format.formatDecimal,
    formatDate: format.formatDate,
    formatHours: format.formatHours,
    formatHoursToBusinessDays: format.formatHoursToBusinessDays,
    formatAggregationWeek: format.formatAggregationWeek,
    formatOTD: format.formatOTD,
    formatNumber: format.formatNumber,
    getColor: utils.getColor,
    isDevEnv: utils.isDevEnv,
    itemRowWidth(item) {
      if (item.origin || item.destination) {
        return "originDestinationWidth";
      }
    },
    updatePageNumber(item) {
      this.expandedItem = item;
      this.page = 1;
    },
    getGotoDrilldownTooltip(item) {
      if (item.shipperId && this.isBrokerUser) {
        return "Shipper Drilldown";
      } else if (item.brokerId && !this.isBrokerUser) {
        return "Broker Drilldown";
      } else {
        return "Lane Drilldown";
      }
    },
    gotoItemDrilldown(item) {
      if (!this.isShipperDashboard) {
        if (item.shipperId && this.isBrokerUser) {
          this.rowClickShipper(item);
        } else if (item.brokerId && !this.isBrokerUser) {
          this.rowClickBroker(item);
        } else {
          this.rowClickLane(item, true);
        }
      }
    },
    /**
     * handles a row being clicked.
     * In dashboard page it will go to that broker or lane drilldown.
     * In drilldown page it will show the shipments
     *
     * @param {object} item (contains the lane/broker information)
     * @param {*} slot (identifies the slot that will open to show shipments)
     */
    rowClick(item, slot) {
      if (!this.isShipperDashboard) {
        slot.expand(!slot.isExpanded);
      } else {
        if (this.isLaneData) {
          this.rowClickLane(item);
        } else if (!this.isBrokerUser) {
          this.rowClickBroker(item);
        } else if (this.isBrokerUser) {
          this.rowClickShipper(item);
        }
      }
    },
    /**
     * routes to the broker drilldown page
     * @param {object} value (object containing broker information)
     */
    rowClickBroker(value) {
      stateAPI.setStateProperty(this, "broker", value.brokerId);
      const broker_name = value.name;
      // replace spaces in broker name with underscore for display in url
      const url_broker_name = broker_name.replace(/ /g, "_");
      this.$router.push({
        name: "drilldown",
        query: { broker: url_broker_name },
        params: { has_incomplete_score: this.formatScore(value.avg_carrier_score) == '--' || this.formatScore(value.avg_broker_score) == '--' ? true : false},
      });
    },
    /**
     * routes to the shipper drilldown page
     * @param {object} value (object containing shipper information)
     */
    rowClickShipper(value) {
      stateAPI.setStateProperty(this, "shipper", value.shipperId);
      const shipper_name = value.name;
      // replace spaces in shipper name with underscore for display in url
      const url_shipper_name = shipper_name.replace(/ /g, "_");
      this.$router.push({
        name: "drilldown",
        query: { shipper: url_shipper_name },
        params: {},
      });
    },
    /**
     * routes to the lane drilldown page
     * @param {object} value (object containing lane information)
     */
    rowClickLane(value, sendSBId = false) {
      stateAPI.setStateProperty(this, "lane", value.laneId);
      if (this.isBrokerUser) {
        stateAPI.setStateProperty(this, "shipper", value.shipperId);
      } else {
        stateAPI.setStateProperty(this, "broker", value.brokerId);
      }
      this.$emit("laneClick", 
        {
          origin: value.origin, 
          destination: value.destination, 
          equipmenttype: value.equipmenttype, 
          sb_id: sendSBId ? value.filterKeyIds : null
        });
    },

    emitUpdatedOptions(value) {
      this.$emit("dataTableOptionUpdate", value);
    },

    emitUpdatedOptionsShipments(value) {
      this.$emit("dataTableOptionUpdateShipments", value);
    },

    emitSelectedOptions(value) {
      this.$emit("dataTableSelectionUpdate", value);
    },

    showBadgeTooltip(carrier_score, broker_score, avg_load_creation_time) {
      if (this.isShipperDashboard && (this.formatScore(carrier_score) == '--' || (this.formatScore(broker_score) == '--' && avg_load_creation_time == null))) {
        return true
      } else if (!this.isShipperDashboard && (this.formatScore(carrier_score) == '--' || (this.formatScore(broker_score) == '--' && avg_load_creation_time == null))) {
        if (this.is_lane_data && !this.has_incomplete_score) {
          return true
        }
      }
      return false;
    },

    operationScoreFormatter(broker_score, avg_load_creation_time, customer_lead_time) {
      if (this.formatScore(broker_score) == "--" && avg_load_creation_time != null) {
        if (this.formatHoursToBusinessDays(customer_lead_time, 1, false) < 1.5) {
          return " - Not enough lead time"
        }
        return " --"
      } else if (this.formatScore(broker_score) == "--" && avg_load_creation_time == null) {
        return  " - Broker does not have this capability"
      }
      return ""
    },

    operationScoreFormatterShipments(broker_score, customer_lead_time) {
      if (this.formatScore(broker_score) == "--") {
        if (this.formatHoursToBusinessDays(customer_lead_time, 1, false) < 1.5) {
          return " - Not enough lead time"
        }
        return " --"
      }
      return ""
    },

    formatCustomerDirect(customerNames) {
      return customerNames == undefined ? "" : customerNames.split(",").sort().join(", ");
    },

    getShipmentScoreChipColor() {
      return this.theme == 'light' ? 'black' : 'white';
    },

    getFavoriteLanesParam(item) {
      const params = {};

      if (this.isBrokerUser) {
        params.id = stateAPI.getStateProperty(this, "shipper");
      } else if (!this.isBrokerUser) {
        params.id = stateAPI.getStateProperty(this, "broker");
      } else {
        this.$forceUpdate();
        return params;
      }

      params.laneId = item.laneId;

      return params;
    },

    isFavoriteLane(item) {
      const params = this.getFavoriteLanesParam(item);

      if (params != null && params != undefined && Object.keys(params).length > 0) {
        if (Object.keys(this.favoriteLanes).includes(params.id)) {
          if (this.favoriteLanes[params.id].includes(params.laneId)) {
            return true;
          }
        }
      }

      return false;
    },

    updateFavoriteLanes(item) {
      const params = this.getFavoriteLanesParam(item);

      if (params != null && params != undefined && Object.keys(params).length > 0) {
        if (this.isFavoriteLane(item)) {
          // if laneId in favorites then delete
          this.favoriteLanes[params.id].splice(this.favoriteLanes[params.id].indexOf(params.laneId), 1);
        } else {
          // if laneId not in favorites then add
          if (this.favoriteLanes[params.id] == undefined) {
            this.favoriteLanes[params.id] = [];
          }
          this.favoriteLanes[params.id].push(params.laneId);
        }

        // remove unused id
        if (this.favoriteLanes[params.id].length == 0) {
          delete this.favoriteLanes[params.id];
        }

        stateAPI.setStateProperty(this, "favoriteLanes", this.favoriteLanes);
        // get configuration in order to update it
        fetchAccountDetails.getAccountDetails({email:this.email}).then(result=>{

          var data = null;
          var tempConfig = {favoriteLanes: this.favoriteLanes};

          if (result != undefined && result.status == 200) {
            data = JSON.parse(result.data.records[0].config);
          }

          if (data != null) {
              data.favoriteLanes = this.favoriteLanes;
              tempConfig = _.cloneDeep(data);
          }

          // Update configuration
          const query_params = {
            email: this.email,
            config: 1,
            new_config: JSON.stringify(tempConfig)
          };

          fetchAccountDetails.updateAccountDetails(query_params);
        });

        this.$forceUpdate();
      }
    }
  },

  data: function () {
    return {
      headers: this.headers_array,
      truncated_headers: this.headers_array.slice(1),
      displayedData: this.displayed_data,
      headersShipment: this.headers_shipments,
      sortBy: "volume",
      sortDesc: true,
      sortDescShipment: true,
      sortByForShipment: !this.isShipmentView ? "originCloseTime" : "week",
      expanded: [],
      isShipperDashboard: this.is_shipper_dashboard,
      isLaneData: this.is_lane_data,
      isAdminBroker:
        stateAPI.getStateProperty(this, "role") == "broker" ||
        stateAPI.getStateProperty(this, "role") == "admin",
      isBrokerUser: stateAPI.getStateProperty(this, "role") == "broker",
      isShipmentView: true,
      pagination: {
        page: 0,
        itemsPerPage: 100,
        pageStart: 2000,
        pageStop: 2001,
        pageCount: 1,
      },
      paginationShipments: {},
      serverItemsLength: this.server_items_length,
      serverItemsLengthShipments: this.server_items_length_shipments,
      page: 1,
      expandedItem: null,
      isTableLoading: false,
      isDropdownLoading: false,
      showSelect: this.show_select,
      selected: [],
      favoriteLanes: stateAPI.getStateProperty(this, "favoriteLanes"),
      email: stateAPI.getStateProperty(this, "email")
    };
  },
};
</script>
<style>

.dataTable .widthWrap {
  max-width: 100px;
  overflow-wrap: break-word !important;
  white-space: normal !important;
}

.expandedDataTableForShipments tbody tr:nth-of-type(even) {
  background-color: rgba(141, 140, 140, 0.05);
}

.gotoIcon:hover {
  color: #ffffff;
  background-color: #0091ff;
  padding: 3px;
  border-radius: 50%;
}

.gotoIcon {
  padding: 3px;
  border-radius: 50%;
}

.dataTable .goToWidthWrap {
  max-width: 30px;
  overflow-wrap: break-word !important;
  white-space: normal !important;
  padding-right: 0px;
}

.originDestinationWidth td:first-child {
  padding-left: 4px !important;
  padding-right: 4px !important;
  min-width: 70px !important;
}

.originDestinationWidth td {
  max-width: 10rem !important;
}

.shipChip {
  width:40px;
  justify-content: center;
}

.scorecardMetricValue {
  font-weight: 400;
  font-size: 18px;
  color: var(--v-scorecardMetricColor-base);
}

.expandedTableCell {
  padding-right: 20px; 
  padding-left: 20px; 
  background-color: var(--v-expandedTableCellColor-base);
}
</style>
