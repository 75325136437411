<template>
  <v-app>
    <v-app-bar
      clipped-left
      flat
      app
      dark
      color="white"
      class="px-xl-14 px-lg-14 px-md-14 hidden-sm-and-down"
    >
      <div>
        <v-img
          alt="Truce Logo"
          id="logo"
          class="shrink mt-2"
          contain
          v-bind:src="require('../assets/Truce_Black.png')"
          width="140"
          style="margin-right: 120px"
          @click="navigateToHome()"
        />
      </div>
      <v-spacer />
      <v-btn
        style="margin-left: 120px; font-size: 16px"
        class="mt-5 mb-5 dmsans font-weight-bold"
        elevation="0"
        color="black"
        @click="dialog = true"
        text
      >
        Contact Us
      </v-btn>
      <v-btn
        style="margin-left: 10px; font-size: 16px"
        class="mt-5 mb-5 dmsans font-weight-bold"
        elevation="0"
        color="black"
        text
        @click="navigateToApp()"
      >
        Sign In
      </v-btn>
    </v-app-bar>
    <v-app-bar flat app dark color="white" class="pa-0 ma-0 hidden-md-and-up">
      <div>
        <v-img
          alt="Truce Logo"
          class="shrink mt-2"
          contain
          v-bind:src="require('../assets/Truce_Black.png')"
          width="140"
          @click="navigateToHome()"
        />
      </div>
    </v-app-bar>

    <v-main class="pa-0" align="center" justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card class="pa-6">
          <v-card-title>
            <span class="text-h5">Contact Us</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Name*"
                    required
                    v-model="name"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Company"
                    v-model="company"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Email*"
                    required
                    v-model="email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    name="input-7-1"
                    label="Comments"
                    outlined
                    v-model="comment"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="dialog = false"> Cancel </v-btn>
            <v-btn
              color="green"
              text
              @click="
                dialog = false;
                sendEmail();
              "
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <section id="privacy" style="background-color: white !important">
        <v-container
          style="padding-top: 16vh"
          class="px-xl-16 px-lg-16 px-md-16 px-sm-4 px-4"
        >
          <div
            class="text-h2 font-weight-bold text-left pb-14"
            style="color: #0091ff"
          >
            Privacy Policy
          </div>
          <div class="text-left">
            <p><i>Last Updated: September 23, 2021</i></p>
            <p>
              Thank you for visiting <a href="/">https://www.truce.io/</a> (the
              “Site”), which is operated by Truce Tech Inc. (“Truce,” “we,” “us”
              or “our”). This Privacy Policy (the “Policy”) describes what
              personal information we and our affiliates collect about you, how
              we collect it, how we use it and share it, and what choices you
              have regarding it. This Policy applies to personal information we
              collect about you when you visit the Site, as well as other data
              that shippers and brokers provide when using our Truce
              Transparency Platform, any mobile application we may provide, and
              anywhere else we may interact with you (collectively, the
              “Services”). By using the Services, you expressly consent to the
              collection, use and sharing of your personal information and other
              confidential information as described in this Policy. If you do
              not agree with the privacy practices described in this Policy, you
              should not use the Services.
            </p>
            <p>
              This Policy is governed by and made a part of our Terms of Use
              <a href="/terms-of-use">www.truce.io/terms-of-use</a> (the
              “Terms”). We encourage you to review the Terms as well as this
              Policy periodically and make sure that you understand the content
              of each. This Policy may be updated periodically and without prior
              notice to you to reflect changes in our privacy practices. You
              should review this Policy periodically so that you keep up to date
              on our most current policies and practices.
            </p>
            <p>
              Truce provides a neutral platform where shippers and brokers
              exchange real-time cost and execution data to increase efficiency.
              This Policy does not cover or address: the privacy practices of
              the shippers and brokers (including their authorized licensees) or
              our privacy practices relating to the personal information of job
              applicants, employees, agents and other personnel.
            </p>
            <p>
              <b>Personal Information We Collect.</b> We collect the following
              categories and types of personal information:
              <v-list dense disabled style="background-color: white !important">
                <v-item-group>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong>Contact Information:</strong> your first and
                        last name, postal address, email address, and phone
                        number;</span
                      ></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong>Other identifying information:</strong> IP
                        address, passwords and other security information for
                        authentication and access, written content you provide
                        when writing a review, social media usernames, and
                        cookie identifiers;</span
                      ></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong>Financial Information:</strong> credit card,
                        debit card and bank account information when purchasing
                        products from us;</span
                      ></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong>Demographic information:</strong> gender and
                        age;</span
                      ></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong>Internet or other electronic activity:</strong>
                        your browsing and click history, including information
                        about how you navigate within the Services;</span
                      ></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong>Commercial information:</strong> products
                        purchased or viewed within the Services;</span
                      ></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong>Audio and visual information:</strong> your
                        videos and photos that you have uploaded to the Services
                        and call recordings when you call customer services;
                        and</span
                      ></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong>Inferences</strong> drawn from the categories
                        described above in order to create a profile about you
                        to reflect your preferences, characteristics, behavior
                        and attitude.</span
                      ></v-list-item-content
                    >
                  </v-list-item>
                </v-item-group>
              </v-list>
            </p>
            <p>
              <b>Why We Collect Personal Information.</b> We may use or may have
              used the personal information we have about you in the following
              ways:
              <v-list dense disabled style="background-color: white !important">
                <v-item-group>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content>
                      <span
                        ><strong>Transactional Purposes:</strong> We use your
                        contact information, financial information, and
                        commercial information to:</span
                      >
                      <v-list dense style="background-color: white !important">
                        <v-list-item-group>
                          <v-list-item>
                            <v-list-item-icon
                              ><v-icon
                                >mdi-circle-small</v-icon
                              ></v-list-item-icon
                            >
                            <v-list-item-content
                              >Set up your account with us;</v-list-item-content
                            >
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon
                              ><v-icon
                                >mdi-circle-small</v-icon
                              ></v-list-item-icon
                            >
                            <v-list-item-content
                              >Receive, process, confirm, send and track your
                              order;</v-list-item-content
                            >
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon
                              ><v-icon
                                >mdi-circle-small</v-icon
                              ></v-list-item-icon
                            >
                            <v-list-item-content
                              >Communicate with you about your
                              order;</v-list-item-content
                            >
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong>Analytical Purposes:</strong> We use your other
                        identifying information, internet activity and browsing
                        history, commercial information, and demographic
                        information to analyze preferences, trends and
                        statistics.</span
                      ></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong>Marketing and Promotional Purposes:</strong> We
                        use your contact information, commercial information,
                        demographic information, internet or other electronic
                        activity, and inferences to:</span
                      >
                      <v-list dense style="background-color: white !important">
                        <v-list-item-group>
                          <v-list-item>
                            <v-list-item-icon
                              ><v-icon
                                >mdi-circle-small</v-icon
                              ></v-list-item-icon
                            >
                            <v-list-item-content
                              >Inform you of our new products, services and
                              offers;</v-list-item-content
                            >
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon
                              ><v-icon
                                >mdi-circle-small</v-icon
                              ></v-list-item-icon
                            >
                            <v-list-item-content
                              >Provide you with and measure and optimize
                              targeted advertising;</v-list-item-content
                            >
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon
                              ><v-icon
                                >mdi-circle-small</v-icon
                              ></v-list-item-icon
                            >
                            <v-list-item-content
                              >Provide you with other information from and about
                              Truce, including personalized marketing
                              communications.</v-list-item-content
                            >
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong
                          >Maintenance and Improvement of Services and
                          Website:</strong
                        >
                        We use your contact information, commercial information,
                        and internet activity and browsing history to:</span
                      >
                      <v-list dense style="background-color: white !important">
                        <v-list-item-group>
                          <v-list-item>
                            <v-list-item-icon
                              ><v-icon
                                >mdi-circle-small</v-icon
                              ></v-list-item-icon
                            >
                            <v-list-item-content
                              >Provide and maintain functionality of the
                              Services, including our customer feedback and
                              review features;</v-list-item-content
                            >
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon
                              ><v-icon
                                >mdi-circle-small</v-icon
                              ></v-list-item-icon
                            >
                            <v-list-item-content
                              >Handle your customer services requests;
                              and</v-list-item-content
                            >
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon
                              ><v-icon
                                >mdi-circle-small</v-icon
                              ></v-list-item-icon
                            >
                            <v-list-item-content
                              >Help us diagnose technical and service problems
                              and administer the Services.</v-list-item-content
                            >
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong>Review and Content Creation Purposes:</strong
                        >We use your contact information, commercial
                        information, and audio and visual information to enable
                        reviews of the restaurants we partner with and to
                        display content that you have created and allowed us to
                        display on our Services and on social media and other
                        advertising.</span
                      ></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong>Security and Fraud Prevention:</strong>We use
                        your contact information, other identifying information,
                        commercial information, financial information, internet
                        activity and browsing history, audio and visual
                        information and inferences to protect the Site, our
                        company, and others and to prevent fraud, theft and
                        misconduct.</span
                      ></v-list-item-content
                    >
                  </v-list-item>
                </v-item-group>
              </v-list>
            </p>
            <p>
              <b>How We Collect Personal Information.</b> We may collect or may
              have collected personal information about you from various
              sources.
              <v-list dense disabled style="background-color: white !important">
                <v-item-group>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong
                          >We collect information directly from you:</strong
                        >
                        We collect contact information, demographic information,
                        financial information, and audio and visual information
                        (such as when you post pictures in a review on the
                        Services or on one of our social media pages) directly
                        from you.
                      </span></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong>From your friends or family members:</strong>
                        We may collect contact information from your friends or
                        family member when they tag you on a post to one of our
                        social media pages.</span
                      ></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong>Through other technologies:</strong> We collect
                        audio and visual information such as when you call our
                        customer support.
                      </span></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong
                          >From our business partners and service
                          providers:</strong
                        >We may collect contact information, demographic
                        information, commercial information, and internet and
                        electronic activity from demographic companies,
                        analytics providers, advertising companies and networks,
                        third-party retailers, and other third parties that we
                        choose to collaborate or work with.</span
                      ></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong>From our affiliates:</strong> We may obtain
                        your contact information and other identifying
                        information from affiliates that you have interacted
                        with.</span
                      ></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong
                          >We collect information from you passively.</strong
                        >
                        We collect Internet or other electronic activity
                        passively using tools like browser cookies. This
                        activity is further described below.</span
                      ></v-list-item-content
                    >
                  </v-list-item>
                </v-item-group>
              </v-list>
            </p>
            <p>
              <b
                >Information Collected from Shippers and Brokers Using Our
                Services.</b
              >
              Users of our services may be requested to provide data fields
              including but not limited to Broker and Shipper Name and Primary
              Reference, Customer Name, Origin and Destination Information,
              execution timestamps, and data related to revenue and cost of
              goods sold (“User Data”). The use, storage, return and destruction
              of this User Data will be governed by the Data Security and
              Integrity provisions of our Terms of Use
              www.truce.io/terms-of-use.
            </p>
            <p>
              <b>Combining Information.</b> We may combine information you give
              us online or you submit through the platform. We may also combine
              that information with publicly available information and
              information we receive from or cross-reference with our business
              partners and service providers. You authorize Truce to use this
              combined User Data in an anonymized, de-identified or aggregated
              manner to build new products and features, enhance the Services we
              provide to you, and for other promotional and commercial purposes.
              <br />You agree and represent that you have reviewed and are
              subject to our Terms of Use, which describes, among other things,
              Truce’s use of User Data (SEE DATA SECURITY AND INTEGRITY SECTION
              OF TERMS OF USE).
            </p>
            <p>
              <b>With Whom We Share Your Personal Information.</b> In general,
              we do not share personal information about you with third parties
              for such third-parties’ own marketing or advertising purposes. We
              do share personal information with third parties for other
              purposes. For example, we share personal information with:
              <v-list dense disabled style="background-color: white !important">
                <v-item-group>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong>Our Service Providers:</strong>
                        We may contract with companies or persons to provide
                        certain services including credit card processing,
                        shipping, data analysis and management. We call them our
                        Service Providers. We provide our Service Providers with
                        the information needed for them to perform these
                        services. We also ask our Service Providers to confirm
                        that their privacy practices are consistent with ours.
                      </span></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong>Our Affiliates:</strong> We may share personal
                        information with businesses controlling, controlled by,
                        or under common control with Truce.</span
                      ></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong>Corporate Transactions:</strong> We may share
                        personal information with parties to business
                        transactions such as those we deal with in mergers,
                        acquisitions, joint ventures, sales of assets,
                        reorganizations, divestitures, dissolutions,
                        bankruptcies, liquidations, or other types of business
                        transactions. In these types of transactions, personal
                        information may be shared, sold, or transferred, and it
                        may be used subsequently by a third party.
                      </span></v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon
                      ><v-icon>mdi-square-small</v-icon></v-list-item-icon
                    >
                    <v-list-item-content
                      ><span
                        ><strong>Law Enforcement and Courts:</strong>In certain
                        instances we may disclose your contact information when
                        we have reason to believe that it is necessary to
                        identify, contact or bring legal action against persons
                        or entities who may be causing injury to you, to Truce
                        or to others. We may also disclose your contact
                        information when we believe the law or legal process
                        requires it.</span
                      ></v-list-item-content
                    >
                  </v-list-item>
                </v-item-group>
              </v-list>
            </p>
            <p>
              <b>Cookies.</b> When you visit the Site, we or a third-party
              company may send one or more cookies to your computer or other
              device. We may also use cookies and other similar technologies in
              emails that you receive from us. A cookie is a small data file
              that is placed on the hard drive of your computer when you visit a
              website. A session cookie expires immediately when you end your
              session (i.e., close your browser). A persistent cookie stores
              information on the hard drive so when you end your session and
              return to the same website at a later date the cookie information
              is still available. We use cookies to improve the quality of the
              Services. We also use cookies to identify who you are, tailor
              measure and optimize the Services and advertising to suit the
              personal interests of you and others, estimate our audience size,
              assist our online merchants to track visits to and sales through
              our Services and to process your order, track your order, and/or
              analyze your visiting and email interaction patterns. If you would
              like to opt out of accepting cookies altogether, you can generally
              set your browser to not accept cookies or to notify you when you
              are sent a cookie, giving you the chance to decide whether or not
              to accept it. However, certain features of our web sites or other
              Services may not work if you delete or disable cookies.
            </p>
            <p>
              <b>Advertising and Online Tracking.</b> We may allow third-party
              companies to serve ads and/or collect certain information when you
              visit the Site. These companies may use information (e.g., click
              stream information, browser type, time and date, subject of
              advertisements clicked or scrolled over) collected during your
              visits to this and other websites in order to provide
              advertisements about goods and services likely to be of interest
              to you. These companies may also tie identifiers associated with a
              particular browser or device to identifiers associated with other
              browsers or devices used by the same user or household (e.g., a
              device identifier associated with a user's computer may be tied to
              the device identifiers of that user's tablet and phone) in order
              to measure and target advertisements tailored to user interests
              across their devices and to send personalized marketing
              communications. These companies typically use a cookie or
              third-party web beacon to collect this information. To learn more
              or opt out from these companies you can visit
              <a href="http://www.networkadvertising.org/choices"
                >http://www.networkadvertising.org/choices</a
              >
              and
              <a href="https://www.aboutads.info/"
                >https://www.aboutads.info/
              </a>
              also provide you with additional tools to opt out of marketing
              from us or certain transfers of your information. You can learn
              about this in the “Opting Out of Mailings” section immediately
              below.
            </p>
            <p>
              <b>Mobile Applications.</b> Depending on your permissions, we may
              receive your personal information from your Internet service and
              mobile device providers. Users of mobile devices who do not want
              to receive interest-based advertising may opt-out in several ways.
              Learn more about your choices for mobile devices by visiting
              <a href="http://www.aboutads.info/appchoices"
                >http://www.aboutads.info/appchoices</a
              >. Each operating system, (iOS for Apple phones, Android for
              Android devices, and Windows for Microsoft devices) provides its
              own instructions on how to prevent the delivery of tailored
              in-application advertisements. You should view your device or
              system “Settings” to determine how you can opt out of use of your
              device ID for “cross-app” personalized advertising.
            </p>
            <p>
              <b>Opting Out of Mailings.</b> The Site provides you with several
              opportunities to agree to receive communications from Truce, and
              any of our affiliated brands or divisions. If at any time you wish
              to stop receiving communications from us please send us an email
              to contact@truce.io with the phrase “<b
                ><i>Privacy Opt-out: Truce Mailings</i></b
              >” in the subject line, or write to us at the address provided
              below, and we will remove you from our mailing list.
              Alternatively, for e-mail communications, you may opt out of
              receiving such communications by following the unsubscribe
              instructions set forth at the bottom of our promotional e-mail
              messages.
            </p>
            <p>
              <b>Third-Party Sites.</b> In some instances, the Services or our
              website may link to third-party websites and services that are not
              controlled by us. We are not responsible for the privacy practices
              of those websites or services, and your use of them is at your
              sole discretion and risk. We recommend that you review the privacy
              policies and practices of any such third party.
            </p>
            <p>
              <b>Information Security.</b> Truce takes commercially reasonable
              security and backup measures to protect the information submitted
              to us. However, no method of transmission over the Internet or
              method of electronic storage and security is 100% secure.
              Therefore, while we strive to protect your personal information,
              we cannot guarantee its absolute security. We encourage all of our
              customers and partners to retain copies of all uploaded
              information on their own systems. Additionally, if you contact us
              by email, you should be aware that email transmissions might not
              be secure. Information you send by email could be viewed in
              transit by a third party. We will have no liability for disclosure
              of your information due to errors or unauthorized acts of third
              parties during transmission.
            </p>
            <p>
              <b>Children’s Personal Information.</b> The Truce Services are not
              directed to children under the age of 13 and we do not knowingly
              collect any personal information from children under the age of 13
              without parental consent. If we learn that a child under the age
              of 13 has provided us with personal information, or that such
              personal information has otherwise been inadvertently collected,
              we will delete it in accordance with applicable law.
            </p>
            <p>
              <b>International Customer Privacy.</b> Truce is operated in the
              United States. If you are located outside of the United States,
              then please be aware that information we collect will be
              transferred to and processed in the United States. By using our
              Services, or providing us with any information, you fully
              understand and unambiguously consent to this transfer, processing
              and storage of your information in the United States, a
              jurisdiction in which the privacy laws may not be as comprehensive
              as those in the country where you are located, reside and/or are a
              citizen.
            </p>
            <p>
              <b>Updating or Amending Your Information.</b> You may update,
              amend or otherwise change the information that Truce retains about
              you at any time by logging into your account and visiting the
              settings page or by contacting our customer support team.
            </p>
            <p>
              <b>Updates to this Policy.</b> This Policy may be updated
              periodically and without prior notice to you to reflect changes in
              our personal information practices. You should review this Policy
              periodically so that you keep up to date on our most current
              policies and practices. We will post a prominent notice on the
              Site to notify you of any significant changes to our privacy
              practices and indicate at the top of the Policy when it was last
              updated. Continued use of our Services after any update shall
              constitute your agreement to the new policy.
            </p>
            <p>
              <b>Questions or Comments.</b> If you have any questions or
              comments about this Policy, our privacy practices, or use of the
              Services, please feel free to contact us by email at
              contact@truce.io, or by mail at the following address: Truce Tech
              Inc - 109 Symonds Dr. #429 - Hinsdale, IL 60522.
            </p>
          </div>
        </v-container>
      </section>

      <v-footer style="background-color: #0d47a1">
        <v-card
          flat
          tile
          width="100%"
          class="blue darken-4 text-center hidden-sm-and-down"
        >
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-row>
                  <div>
                    <v-img
                      alt="Truce Logo"
                      class="shrink"
                      contain
                      src="../assets/Truce_Logo_White.png"
                      width="140"
                      style="margin-right: 120px"
                    />
                  </div>
                </v-row>
                <v-row>
                  <div class="white--text">
                    &copy; 2021 Truce Tech, Inc. All Rights Reserved.
                  </div>
                </v-row>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn
                  style="margin-left: 120px; font-size: 16px"
                  class="mt-5 dmsans font-weight-bold"
                  elevation="0"
                  color="white"
                  text
                  @click="toTop()"
                >
                  Privacy
                </v-btn>
                <v-btn
                  style="margin-left: 10px; font-size: 16px"
                  class="mt-5 dmsans font-weight-bold"
                  elevation="0"
                  color="white"
                  text
                  @click="navigateToTerms()"
                >
                  Terms of Use
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row class="hidden-md-and-up">
          <v-col cols="12">
            <div class="mx-auto text-center">
              <v-btn
                style="font-size: 16px"
                class="mt-5 dmsans font-weight-bold"
                elevation="0"
                color="white"
                text
                @click="toTop()"
              >
                Privacy
              </v-btn>
            </div>
            <div class="mx-auto text-center">
              <v-btn
                style="font-size: 16px"
                class="mb-5 dmsans font-weight-bold"
                elevation="0"
                color="white"
                text
                @click="navigateToTerms()"
              >
                Terms of Use
              </v-btn>
              <div class="white--text">
                &copy; 2021 Truce Tech, Inc. All Rights Reserved.
              </div>
            </div>
          </v-col>
        </v-row>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import { API } from "aws-amplify";
export default {
  name: "Landing",
  data: function () {
    return {
      scrollPosition: null,
      btnColor_1: "white",
      logo_img: "Truce_Logo_White.png",
      dialog: false,
      name: "",
      company: "",
      email: "",
      comment: "",
      backToTop: false,
      backToTopMargin: "backToTop",
    };
  },
  methods: {
    updateScroll(e) {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition > document.documentElement.clientHeight - 50) {
        this.btnColor_1 = "black";
        this.logo_img = "Truce_Logo_Black.png";
      } else {
        this.btnColor_1 = "white";
        this.logo_img = "Truce_Logo_White.png";
      }

      if (this.scrollPosition < window.innerHeight * 5 - 50) {
        this.backToTopMargin = "backToTop";
      } else {
        this.backToTopMargin = "backToTop_Footer";
      }

      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.backToTop = top > 20;
    },
    navigateToApp() {
      this.$router.push({
        name: "app",
        params: { prop_usn: "Roop Pal", prop_toggle: "left" },
      });
    },
    navigateToHome() {
      this.$router.push({
        name: "landing",
      });
    },
    navigateToTerms() {
      this.$router.push({
        name: "terms",
      });
    },
    sendEmail() {
      const apiName = "Contact"; // replace this with your api name.
      const path = "/contact"; //replace this with the path you have configured on your API
      const myInit = {
        body: {
          name: this.name,
          company: this.company,
          email: this.email,
          comment: this.comment,
        }, // replace this with attributes you need
        headers: {}, // OPTIONAL
      };

      API.post(apiName, path, myInit)
        .then((response) => {
          console.log("DONE SENDING EMAIL");
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },

  beforeMount() {
    this.$vuetify.goTo(0);
  },

  mounted() {
    // window.addEventListener("scroll", this.updateScroll);
    this.$vuetify.goTo(0);
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap");
.v-tab {
  text-transform: none !important;
}

.v-tab:hover {
  color: #536dfe !important;
  border-bottom: 2px solid #536dfe !important;
}

.rounded-card {
  border-radius: 5px !important;
}

.my-overlay >>> .v-overlay__content {
  width: 100%;
}

.change_color {
  color: #536dfe;
}

.bottom-gradient {
  background-image: linear-gradient(to bottom, rgb(255, 0, 0) 0%, 72px);
}

.dmsans {
  font-family: "DM Sans", sans-serif !important;
}
.backToTop {
  margin-bottom: 4.3vh;
}
.backToTop_Footer {
  margin-bottom: 17vh;
}
#logo:hover {
  cursor: pointer;
}
</style>
