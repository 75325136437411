<template>
    <div class="d-flex justify-center align-center">
        <div class="text-h2 mr-4">$</div>
        <Roller
            class="roller text-h1"
            default-value="0"
            :text="displayNum"
            :char-set="numbers"
        ></Roller>
        <!-- {{ displayNumber }} -->
    </div>
</template>

<script>
import Roller from "vue-roller";
export default {
name: "Counter",
props: [ 'targetNum' ],

components: {
    Roller
},

computed: {
    displayNum() {
        let result = this.targetNum.slice(-3)
        for (let i = 1; i < this.targetNum.length/3; i++) {
            result = this.targetNum.slice(-3*(i+1), -3*i) + "," + result
        }
        return result
    }
},

mounted() {
},

model() {
    
},

methods() {
},

data: function () {
    return {
    };
},

};
</script>

<style>
.roller .roller__char {
    padding-left: 8px !important;
}
</style>