<template>
  <v-tooltip top max-width="25%">
    <template v-slot:activator="{ on }">
      <span v-on="on">{{ headertext }}</span>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: "Tooltip",
  props: ["header_text", "header_tool"],

  watch:{
    header_text(val) {
      this.headertext = val;
    },
    header_tool(val) {
      this.tooltip = val;
    },
  },
  data: function () {
    return {
      headertext: this.header_text,
      tooltip: this.header_tool,
    };
  },
};
</script>
<style>
</style>
