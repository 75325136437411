<template>
    <v-row class="d-flex justify-center">
        <v-col cols="5">
            <v-autocomplete
                dense
                v-model="value[0]"
                :items="hourVals"
                hide-details
            >
                <template #item="{ parent, item, on, attr }">
                    <v-list-item
                        v-on="on"
                        v-bind="attr"
                        style="min-height:32px"
                    >{{ item }}</v-list-item>
                </template>
            </v-autocomplete>
        </v-col>
        <v-col cols=1 class="px-0 mx-0 d-flex justify-center">
            <div class="pt-2">:</div>
        </v-col>
        <v-col cols="5">
            <v-autocomplete
                dense
                v-model="value[1]"
                :items="minuteVals"
                hide-details
            >
                <template #item="{ parent, item, on, attr }">
                    <v-list-item
                        v-on="on"
                        v-bind="attr"
                        style="min-height:32px"
                    >{{ item }}</v-list-item>
                </template>
            </v-autocomplete>
        </v-col>
    </v-row>
</template>

<script>
export default {
name: "TimePicker",
props: [ 'value' ],

mounted() {
},

model() {
    
},

methods() {
},

data: function () {
    return {
        hourVals: Array.from(new Array(24), (_, index) => index.toString().padStart(2, '0')),
        minuteVals: Array.from(new Array(4), (_, index) => (index * 15).toString().padStart(2, '0')),
    };
},

};
</script>

<style>
</style>