<template>
    <v-card elevation="5" height="100%" :loading="isLoading">
        <v-card-title class="font-weight-light pa-2">
            <TableBar
                ref="bar"
                :is_broker_user="isBrokerUser"
                :is_lane_drilldown="isLaneDrilldown"
                :is_lane_data="isLaneData"
                :table_type="tableType"
                :is_dashboard="isDashboard"
                :selected_lanes="selectedLanes"
                :is_table_loading="isTableLoading"
                :is_dropdown_loading="isDropdownLoading"
                @busEvent="handleBus"
                @toggleAgg="toggleAggregate"
                @toggleView="toggleFullView"
                @focusLanes="handleFocusLanes"
                @favoriteLanes="handleFavoriteLanes"
                v-on="$listeners"
                :time_period="timePeriod"
                :start_date="startDate"
                :end_date="endDate"
            />
        </v-card-title>
            <DataTable
                ref="table"
                v-if="displayedData && displayedData.length > 0"
                @dataTableOptionUpdate="handleUpdatedOptions"
                @dataTableOptionUpdateShipments="handleUpdatedOptionsShipments"
                @dataTableSelectionUpdate="handleUpdatedSelection"
                @laneClick="handleLaneClick"
                :headers_array="this.headers"
                :headers_shipments="this.headersShipments"
                :displayed_data="this.displayedData"
                :is_shipper_dashboard="this.isDashboard"
                :server_items_length="serverItemsLength"
                :server_items_length_shipments="serverItemsLengthShipments"
                :is_lane_data="isLaneData"
                :has_incomplete_score="hasIncompleteScore"
                :percentToDollars="[
                    this.headers,
                    this.headersShipments,
                    this.displayedData,
                    !this.isWeeklyView,
                    this.serverItemsLength,
                    this.serverItemsLengthShipments,
                    this.isTableLoading,
                    this.isDropdownLoading,
                ]"
                :show_select="showSelect"
            />
        <v-data-table v-else disable-pagination hide-default-footer>
        </v-data-table>
    </v-card>
</template>

<script>
import * as stateAPI from "../stateAPI";
import TableBar from "../components/TableBar.vue";
import * as utils from "../utils";
import DataTable from "../components/DataTable.vue";
import * as globalVariables from "../globalVariables";
import structuredClone from "@ungap/structured-clone";
import _ from "lodash";
export default {
// Some things to watch out for:
// Parent container needs to have a DataTable component with a "table" ref

    name: "TableBundle",

    components: {
        TableBar,
        DataTable,
    },

    props: [
        "is_loading",
        "is_lane_drilldown",
        "is_lane_data",
        "is_broker_user",
        "displayed_data",
        "has_incomplete_score",
        "time_period",
        "end_date",
        "start_date",
        "customer_direct_enabled"
    ],

    watch: {
        customer_direct_enabled(val) {
            this.isCustomerDirectEnabled = val;
            this.populateHeaders();
            this.populateShipmentHeaders();
        }
    },

    data: function () {
    return {
            role: stateAPI.getStateProperty(this, "role"),
            settings: stateAPI.getStateProperty(this, "table_settings"),
            showSelect: false,
            selectedEquipmentTypes: stateAPI.getStateProperty(
                this,
                "equipment_type_list"
            ),
            selectedLanes: {},
            testkey: 0,
            page: this.$root.$children[0].$children[0],
            isDashboard: null,

            // From Props
            isLoading: this.is_loading,
            isLaneDrilldown: this.is_lane_drilldown,
            isLaneData: this.is_lane_data,
            isBrokerUser: this.is_broker_user,
            isWeeklyView: false,
            isFullView: false,

            // Bar data
            searchTable: null,
            timePeriod: this.time_period,
            endDate: this.end_date,
            startDate: this.start_date,

            // Table data
            displayedData: this.displayed_data,
            headers: null,
            headersShipments: null,
            serverItemsLength: -1,
            serverItemsLengthShipments: -1,
            isTableLoading: false,
            isDropdownLoading: false,
            hasIncompleteScore: this.has_incomplete_score,
            pageSize: 100,
            pageNumber: 0,
            sortColumn: "volume",
            sortDirection: "DESC",
            pageSizeShipments: 8,
            pageNumberShipments: 0,
            sortColumnShipments: "originCloseTime",
            sortDirectionShipments: "DESC",
            isCustomerDirectEnabled: this.customer_direct_enabled
        };
    },

    created() {
        // Determine whether on Shipper/Broker/Lane   Dashboard/Drilldown and assign accordingly
        const url = new URL(window.location.href);
        this.isDashboard = url.pathname.includes("Dashboard")
        if (this.isLaneDrilldown || (this.isDashboard && !url.pathname.includes("lane"))) {
            this.volumeThreshold = 0;
            this.isLaneData = false;
            if (this.isBrokerUser) {
                this.tableType = "Shipper";
            } else if (!this.isBrokerUser) {
                this.tableType = "Broker";
            }
        } else {
            this.tableType = "Lane";
            this.isLaneData = true;
        }
        this.populateHeaders()
        this.populateShipmentHeaders()
    },

    methods: {
        populateHeaders() {
            const url = new URL(window.location.href);
            var newHeaders = [];
            if (this.isLaneData) {
                newHeaders = this.isDashboard ?
                    globalVariables.default.headers_lanes
                    :
                    _.cloneDeep(globalVariables.default.headers_lanes_drilldown)
                if (!this.isDashboard && this.isCustomerDirectEnabled) {
                    if (!newHeaders.includes(globalVariables.default.customer_direct_header)) {
                        newHeaders.splice(5, 0, globalVariables.default.customer_direct_header);
                    }
                }
            } else {
                newHeaders = this.isBrokerUser ?
                    newHeaders = this.isDashboard ?
                        globalVariables.default.headers_shippers
                        :
                        globalVariables.default.headers_shippers_drilldown
                    :
                    newHeaders = this.isDashboard ?
                        globalVariables.default.headers_brokers
                        :
                        globalVariables.default.headers_brokers_drilldown
            }
            if (!this.isFullView) {
                this.headers = this.filterHeaders(newHeaders)
            } else {
                this.headers = newHeaders;
            }
        },

        populateShipmentHeaders() {
            if (this.isWeeklyView) {
                this.headersShipments = _.cloneDeep(globalVariables.default.headers_shipments_aggregation);
                if (this.isCustomerDirectEnabled && !this.headersShipments.includes(globalVariables.default.customer_direct_header_shipments)) {
                    this.headersShipments.splice(2, 0, globalVariables.default.customer_direct_header_shipments);
                }
            } else {
                this.headersShipments = _.cloneDeep(globalVariables.default.headers_shipments);
                if (this.isCustomerDirectEnabled && !this.headersShipments.includes(globalVariables.default.customer_direct_header_shipments)) {
                    this.headersShipments.splice(3, 0, globalVariables.default.customer_direct_header_shipments);
                }
            }
            if (!this.isFullView) {
                this.headersShipments = this.filterHeaders(this.headersShipments)
            }
        },

        filterHeaders(headers) {
            var headers_filtered = [];
            for (const entry of headers) {
                if (!globalVariables.default.simple_view_header_blacklist.includes(entry.value)) {
                    var clone;
                    if (entry.text == "Broker Name"){
                        clone = structuredClone(entry);
                        clone["width"] = "16%";
                        headers_filtered.push(clone);
                    } else if (entry.text == "Origin" || entry.text == "Destination") {
                        clone = structuredClone(entry);
                        clone["width"] = "11.5%";
                        headers_filtered.push(clone);
                    } else {
                        headers_filtered.push(entry);
                    }
                }
            }
            return headers_filtered
        },

        handleBus(elem) {
            this.showSelect = elem.showSelect
            this.isTableLoading = elem.isTableLoading
            this.isDropdownLoading = elem.isDropdownLoading
            this.isWeeklyView = elem.isWeeklyView
            this.searchTable = elem.searchTable
        },

        handleUpdatedOptions(options) {
            this.pageSize = options.itemsPerPage;
            this.pageNumber = options.page - 1;

            if (options.sortBy.length > 0) {
                this.sortColumn = options.sortBy[0];
                this.sortDirection = options.sortDesc[0] ? "DESC" : "ASC";
            } else {
                this.sortColumn = "volume";
                this.sortDirection = "ASC";
            }

            this.$emit("parseURL", true);
            this.$forceUpdate();
        },

        handleUpdatedOptionsShipments(options) {
            this.pageSizeShipments = options.itemsPerPage;
            this.pageNumberShipments = options.page - 1;
            if (options.sortBy.length > 0) {
                this.sortColumnShipments = options.sortBy[0];
                this.sortDirectionShipments = options.sortDesc[0] ? "DESC" : "ASC";
            } else {
                this.sortColumnShipments = "originCloseTime";
                this.sortDirectionShipments = "DESC";
            }

            this.$emit("parseURL", true);
            this.$forceUpdate();
        },

        handleUpdatedSelection(options) {
            // handle all lanes selected/unselected
            if (options.items) {
                this.selectedLanes = {};
                if (options["value"]) {
                    options.items.forEach(item => { this.selectedLanes[item.laneId] = null; });
                }
                return;
            }

            // handle specific lane selected/unselected
            var item = options["item"];
            var checked = options["value"];
            if (!checked) {
                // Checked is false, delete from selectedLanes
                delete this.selectedLanes[item["laneId"]];
            } else {
                // Checked is true, add to selectedLanes
                this.selectedLanes[item["laneId"]] = null;
            }
        },

        handleLaneClick(event) {
            this.$emit("laneClick", event);
        },

        toggleFullView(val) {
            this.isFullView = val;
            this.populateHeaders();
            this.populateShipmentHeaders();
        },

        toggleAggregate(val) {
            // I don't know why this is necessary for the table prop to update but it's kind of funny that it is
            this.$refs.bar.isDropdownLoading = true;
            this.isWeeklyView = val;
            this.populateShipmentHeaders();
            this.$emit('parseURL', true);
        },

        handleFocusLanes(val) {
            this.$emit('focusLanes', val);
        },

        handleFavoriteLanes(val) {
            this.$emit('favoriteLanes', val);
        }

    },
};
</script>

<style>
.dataTable {
    background-color: var(--v-dataTableBackground-base) !important;
    color: var(--v-dataTableText-base) !important;
}
</style>
