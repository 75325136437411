<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <NavBar username="usn" />
    <SideBar />
    <v-main>
      <v-container fluid style="width: 93%" dense>
        <v-row v-if="isAdminUser" dense>
          <v-col cols="12" class="pb-0 mb-n8">
            <p class="text-end font-weight-light" style="color: grey">
              {{ dataFreshnessLabel }}
            </p>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="10" class="pa-0">
            <v-breadcrumbs
              :items="crumbs"
              divider=">"
              large
              class="mb-0 pt-4 mt-0 mr-4"
            ></v-breadcrumbs>
          </v-col>
          <v-col cols="2" class="pa-3">
            <p
              class="text-end mb-0 mr-4 pt-2 font-weight-light"
              :style="{color: theme == 'light' ? '#545454' : 'white'}"
            >
              {{ periodDates }}
            </p>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-card elevation="5">
              <v-card-title class="font-weight-light">
                <v-icon
                  v-if="!isBrokerUser"
                  large
                  :color="$vuetify.theme.themes[theme].headerIcon"
                  class="mr-4"
                  >mdi-lan-connect</v-icon
                >
                <v-icon
                  v-else
                  large
                  :color="$vuetify.theme.themes[theme].headerIcon"
                  class="mr-4"
                  >mdi-warehouse</v-icon
                >
                <template>
                  {{ broker }}
                </template>
                <v-spacer></v-spacer>
                <v-chip
                  v-for="(keyval, index) in Array.from(filterChips)"
                  :key="index"
                  :color="$vuetify.theme.themes[theme].chipColor"
                  text-color="white"
                  class="mr-2"
                  close
                  @click:close="resetFilters(keyval[0])"
                >
                  {{ keyval[1] }}
                </v-chip>
                <Filters
                  @applySelectedFilters="handleEmmittedFilters"
                  :time_period="timePeriod"
                  :volume_threshold="volumeThreshold"
                  :is_lane_drilldown="false"
                  :is_analytics="false"
                  :updateData="[this.filters, this.isComparisonEnabled]"
                />
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>

        <!-- BROKER/SHIPPER DRILLDOWN -->
        <v-row dense v-resize="onResize">
          <v-col cols="3">
            <v-card
              ref="gaugeCard"
              elevation="5"
              style="height: 100%"
            >
              <v-card-title
                class="font-weight-light"
                style="padding-bottom: 0px"
                >Truce Score</v-card-title
              >
              <v-card-actions class="pt-0 text-h4">
                <ScoreCircle
                  :averageScore="this.averageScore"
                  :gaugeCardWidth="this.gaugeCardWidth"
                  :browserZoom="this.browserZoom"
                  :is_lane_drilldown="false"
                  :has_incomplete_score="this.hasIncompleteScore"
                />
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="5">
              <KeyMetrics
                ref="km"
                :is_loading = "isLoading"
                :key_metrics="key_metrics"
                :is_dashboard_km="true"
                :updateKeyMetrics="[keyMetrics]"
                :show_agg_tabs="showAggTabs"
                @periodTabChange = handleKeyMetricTabChange
                :is_period_null = isPeriodNull
                :is_comparison_enabled = isComparisonEnabled
                :query_in_progress = queryInProgress
                :key_metrics_toggle = keyMetricsToggle
                :old_period_dates = oldPeriodDates
                :period_dates = periodDates
              />
          </v-col>

          <v-col cols="4">
            <v-card elevation="5" style="height: 100%">
              <v-card-title
                class="font-weight-light pb-0"
                style="overflow-y: hidden; white-space: nowrap"
              >
                <v-row>
                  <v-col cols="lg-4 md-2" class="pb-0">Metric Trends</v-col>
                  <v-col cols="lg-8 md-2">
                    <v-tabs
                      color="#0091ff"
                      right
                      show-arrows
                      v-model="metricTrendTypeTabs"
                      class="metricTrendTabs"
                    >
                      <v-tooltip top id="remove-v-tooltip" style="margin-left:auto;">
                        <template #activator="{on}">
                          <v-tab
                            :disabled="
                              disableMetricTrendCPM ||
                              disableMetricTrendMPL ||
                              disableMetricTrendVOL
                            "
                            v-on="on"
                            @click="changeMetricTrend('CPM', 0)"
                            >S|CPM</v-tab
                          >
                        </template>
                        <span>Customer Spend Per Mile and Broker Truck Cost Per Mile</span>
                      </v-tooltip>
                      <v-divider vertical inset></v-divider>
                      <v-tooltip top>
                        <template #activator="{on}">
                          <v-tab
                            :disabled="
                              disableMetricTrendCPM ||
                              disableMetricTrendMPL ||
                              disableMetricTrendVOL
                            "
                            v-on="on"
                            @click="changeMetricTrend('MPL', 1)"
                            >MPL</v-tab
                          >
                        </template>
                        <span>Margin per Load</span>
                      </v-tooltip>
                      <v-divider vertical inset></v-divider>
                      <v-tooltip top>
                        <template #activator="{on}">
                          <v-tab
                            :disabled="
                              disableMetricTrendCPM ||
                              disableMetricTrendMPL ||
                              disableMetricTrendVOL
                            "
                            v-on="on"
                            @click="changeMetricTrend('VOL', 2)"
                            >VOL</v-tab
                          >
                        </template>
                        <span>Volume</span>
                      </v-tooltip>
                    </v-tabs>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-actions class="justify-center pt-0">
                <Graph
                  :rawData="metricTrends"
                  :graphName="metricTrendType"
                  :timeRange="timePeriod"
                  :isBroker="true"
                ></Graph>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>


        <v-row dense>
          <v-col cols="12">
            <TableBundle
              ref="bundle"
              :is_loading="false"
              :is_broker_user="isBrokerUser"
              :is_lane_drilldown="false"
              :is_lane_data="isLaneData"
              :displayed_data="displayedData"
              :has_incomplete_score="hasIncompleteScore"
              @parseURL="parseURLPathName"
              @handleFilters="handleEmmittedFilters"
              @focusLanes="handleFocusLanes"
              @favoriteLanes="handleFavoriteLanes"
              @laneClick="handleLaneClick"
              :time_period="timePeriod"
              :start_date="startDate"
              :end_date="endDate"
              :customer_direct_enabled="isCustomerDirectEnabled"
            />
          </v-col>
        </v-row>
      </v-container>      
      <v-dialog v-model="laneDrilldown" width="80%" overlay-color="black">
        <LaneDrilldown
          :drilldown_object="drilldownObject"
          :prop_broker="isBrokerUser"
          @stopLoading="isLoading=false"
        />
      </v-dialog>
    </v-main>
    <SplashScreen style="z-index: 220" :is_loading="isLoading"/>
  </v-app>
</template>




<script>
import NavBar from "../components/NavBar";
import TableBundle from "../containers/TableBundle.vue";
import SideBar from "../components/SideBar";
import ScoreCircle from "../components/ScoreCircle";
import Filters from "../components/Filters";
import KeyMetrics from "../components/KeyMetrics";
import Graph from "../components/Graph";
import SplashScreen from "../components/SplashScreen";
import LaneDrilldown from "../components/LaneDrilldown";
import * as user_analytics from "../analytics/sendAnalyticsEvent";
import * as format from "../formatShipmentData";
import * as stateAPI from "../stateAPI";
import * as utils from "../utils";
import * as computeKeyMetrics from "../computeKeyMetrics";
import _ from "lodash";

export default {
  name: "Drilldown",
  props: ["prop_broker", "prop_sb_id", "has_incomplete_score"],

  components: {
    NavBar,
    TableBundle,
    SideBar,
    ScoreCircle,
    Filters,
    KeyMetrics,
    Graph,
    SplashScreen,
    LaneDrilldown
  },

  computed:{
    theme() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light';
    }
  },

  methods: {
    formatScore: format.formatScore,
    formatPercent: format.formatPercent,
    formatDollars: format.formatDollars,
    formatDecimal: format.formatDecimal,


    resetFilters(objType) {
      utils.resetFilters(
        objType,
        this.endDate,
        this.timePeriod,
        this.tickLabels,
        this.filterChips,
        this.volumeThreshold,
        this.isCustomerDirectEnabled,
        this
      );

      this.filters.isCustomerDirectEnabled = this.isCustomerDirectEnabled;
      if (this.filterChips.size == 0) {
        this.showFilterChips = false;
        this.showAggTabs = true;
        this.parseURLPathName();
      } else {
        this.handleEmmittedFilters(
          this.endDate,
          this.timePeriod,
          this.volumeThreshold,
          this.selectedEquipmentTypes,
          false,
          this.filters
        );
      }
    },

    handleEmmittedFilters(
      date = this.endDate,
      timePeriod = this.timePeriod,
      volumeThreshold = this.volumeThreshold,
      equipmentTypes = this.selectedEquipmentTypes,
      isComparisonEnabled = this.isComparisonEnabled,
      filters = this.filters
    ) {
      const milliToDay = 1000 * 60 * 60 * 24;

      // store filters state
      this.filters = filters;

      // set time period
      this.timePeriod = timePeriod;
      stateAPI.setStateProperty(this, "storeTimePeriod", this.timePeriod);

      // set end date
      this.endDate = new Date(new Date(date).setHours(5, 0, 0));
      stateAPI.setStateProperty(this, "storeEndDate", this.endDate);

      // calculate start date
      var start_offset = parseInt(timePeriod);
      this.startDate = new Date(
        new Date(
          new Date(this.endDate).getTime() -
          start_offset * milliToDay
        ).setHours(5, 0, 0)
      );
      this.load = false;

      this.isPeriodOneDay =
        this.endDate.toLocaleDateString() == this.startDate.toLocaleDateString()
          ? true
          : false;

      // calculate delta end date
      this.deltaEndDate = new Date(
        new Date(
          new Date(this.endDate).getTime() - parseInt(timePeriod) * milliToDay
        ).setHours(5, 0, 0)
      );

      // calculate delta start date
      this.deltaStartDate = new Date(
        new Date(
          this.deltaEndDate.getTime() - (parseInt(timePeriod) - 1) * milliToDay
        ).setHours(5, 0, 0)
      );

      // set volume threshold
      this.volumeThreshold = volumeThreshold;
      stateAPI.setStateProperty(this, "storeVolumeThreshold", this.volumeThreshold);


      // set equipment types
      this.selectedEquipmentTypes = equipmentTypes;

      // set customer direct flag
      this.isCustomerDirectEnabled = filters.isCustomerDirectEnabled;
      stateAPI.setStateProperty(this, "storeIsCustomerDirectEnabled", this.isCustomerDirectEnabled);

      // set new filter chips - call from utils
      // dont display timePeriod if enddate is the only filter chip
      if (!(this.filterChips.size == 1 && this.filterChips.has("enddate"))) {
        this.setFilterChips(date, timePeriod, volumeThreshold, equipmentTypes, filters.isCustomerDirectEnabled);
      }
      // toggle off tabs
      this.showFilterChips = this.filterChips.size == 0 ? false : true;
      this.showAggTabs = !this.showFilterChips;
      this.keyMetricsToggle = null;
      stateAPI.setStateProperty(
        this,
        "storeKeyMetricsToggle",
        this.keyMetricsToggle
      );

      this.isComparisonEnabled = isComparisonEnabled;
      stateAPI.setStateProperty(
        this,
        "storeIsComparisonEnabled",
        this.isComparisonEnabled
      );

      this.parseURLPathName();
    },

    setFilterChips(date, timePeriod, volumeThreshold, selectedEquipmentTypes, isCustomerDirectEnabled) {
      var newFilterChips = new Map();

      const defaultStartDate = new Date(
        new Date(stateAPI.getStateProperty(this, "startDate")).setHours(5, 0, 0)
      );

      const defaultEndDate = new Date();
      const defaultTimePeriod = (
        (new Date(defaultEndDate).getTime() -
          new Date(defaultStartDate).getTime()) /
          (24 * 60 * 60 * 1000) +
        1
      ).toFixed(0);

      if (
        new Date(date).toLocaleDateString() !=
          defaultEndDate.toLocaleDateString() ||
        new Date(this.startDate).toLocaleDateString() !=
          defaultStartDate.toLocaleDateString()
      ) {
        newFilterChips = utils.updateFilterChips(
          "dateRange",
          "Date Range: ",
          this.isPeriodOneDay
            ? [new Date(date).toLocaleDateString()]
            : [
                new Date(this.startDate).toLocaleDateString() +
                  " - " +
                  new Date(date).toLocaleDateString(),
              ],
          newFilterChips.size != 0 ? newFilterChips : this.filterChips
        );
      }

      if (volumeThreshold > 0) {
        newFilterChips = utils.updateFilterChips(
          "volumeThreshold",
          "Volume: ",
          [parseInt(volumeThreshold)],
          newFilterChips.size != 0 ? newFilterChips : this.filterChips
        );
      }

      var unselectedEquipmentTypes = new Set(this.EQUIPMENT_TYPE_LIST);
      // Update filter chips based on equipment types selected in filter menu.

      if (unselectedEquipmentTypes.size != selectedEquipmentTypes.length) {
        for (let i = 0; i < selectedEquipmentTypes.length; i++) {
          const equipmentType = selectedEquipmentTypes[i];
          // This equipment type was selected, so prune it from the unselected set.
          unselectedEquipmentTypes.delete(equipmentType);
          // if (this.isApplyClicked) {
          newFilterChips = utils.updateFilterChips(
            "equipment" + "_" + equipmentType,
            "Equip. Type: ",
            [equipmentType],
            newFilterChips.size != 0 ? newFilterChips : this.filterChips
          );
          // }
        }
      }

      if (isCustomerDirectEnabled) {
        newFilterChips = utils.updateFilterChips("isCustomerDirectEnabled", "Customer Direct", [""], newFilterChips.size != 0 ? newFilterChips : this.filterChips);
      }

      this.filterChips = newFilterChips;
    },

    handleKeyMetricTabChange(tabNumber) {
      this.isLoading = true;
      this.keyMetricsToggle = tabNumber;
      stateAPI.setStateProperty(
        this,
        "storeKeyMetricsToggle",
        this.keyMetricsToggle
      );
      var tp = this.tabValues[tabNumber];
      if (tp == undefined || tp == null) {
        const today = new Date(new Date().setHours(5, 0, 0));
        const start = new Date(
          new Date(stateAPI.getStateProperty(this, "startDate")).setHours(
            1,
            0,
            0
          )
        );
        tp = (
          (today.getTime() - start.getTime()) / (24 * 60 * 60 * 1000) +
          1
        ).toFixed(0);
        this.endDate = today;
        this.startDate = start;
      }

      this.handleKeyMetricTabSelected(tp);
    },

    handleKeyMetricTabSelected(timePeriod) {
      // calculate delta end date
      this.deltaEndDate = new Date(
        new Date(
          new Date(this.endDate).getTime() -
            parseInt(timePeriod) * 1000 * 60 * 60 * 24
        ).setHours(5, 0, 0)
      );

      // calculate start date
      this.startDate = new Date(
        new Date(
          new Date(this.endDate).getTime() -
            (parseInt(timePeriod) - 1) * 1000 * 60 * 60 * 24
        ).setHours(5, 0, 0)
      );
      this.deltaStartDate = new Date(
        new Date(
          this.deltaEndDate.getTime() -
            (parseInt(timePeriod) - 1) * 1000 * 60 * 60 * 24
        ).setHours(5, 0, 0)
      );

      //show tabs
      this.showAggTabs = true;
      this.showFilterChips = true;

      //set filter param
      const tickValIdx = this.tickValues.indexOf(timePeriod);
      this.filters.timeSlider = tickValIdx != -1 ? tickValIdx : 5;

      // set timePeriod
      this.timePeriod = timePeriod;

      this.setFilterChips(
        this.endDate,
        timePeriod,
        this.volumeThreshold,
        this.selectedEquipmentTypes
      );

      this.parseURLPathName();
    },

    computeKeyMetricsFilters() {
      const showChange =
        this.filterChips.size == 0 && this.keyMetricsToggle == null
          ? false
          : this.isComparisonEnabled;
      computeKeyMetrics.getKeyMetricsForTabs(
        this.key_metrics,
        showChange,
        this,
        true
      );
    },

    /**
     * refresh crumbs and the crumb ids
     * set broker/lane/shipper id depending on drilldowqn
     */
    getBreadCrumb() {
      var matchingIndex = null;
      var crumbIds = stateAPI.getStateProperty(this, "crumbIds");

      var crumbText = this.broker;
      if (
        this.lane_equipment_type !== null &&
        this.lane_equipment_type !== undefined
      ) {
        crumbText = this.lane_equipment_type + ": " + this.broker;
      }

      this.crumbs.forEach((crumb) => {
        if (crumb.text == crumbText) {
          matchingIndex = this.crumbs.indexOf(crumb);
        }
        crumb.disabled = false;
      });

      if (matchingIndex != null) {
        // set crumbs
        this.crumbs.splice(
          matchingIndex + 1,
          this.crumbs.length - matchingIndex
        );
        this.crumbs[this.crumbs.length - 1].disabled = true;
        stateAPI.setStateProperty(this, "crumbs", this.crumbs);

        // set crumb ids and broker/shipper id
        crumbIds.splice(matchingIndex + 1, crumbIds.length - matchingIndex);
        var currCrumb = this.crumbs[this.crumbs.length - 1];
        var currCrumbId = crumbIds[crumbIds.length - 1];
        if (currCrumb.href.includes("broker")) {
          stateAPI.setStateProperty(this, "broker", currCrumbId);
        } else if (currCrumb.href.includes("shipper")) {
          stateAPI.setStateProperty(this, "shipper", currCrumbId);
        }
        stateAPI.setStateProperty(this, "crumbIds", crumbIds);
        return;
      }

      if (this.crumbs.length >= 4) {
        this.crumbs.splice(3, 1);
        crumbIds.splice(3, 1);
      }

      const currentURL = new URL(window.location.href);
      this.crumbs.push({
        text: crumbText,
        disabled: true,
        href: currentURL.pathname + currentURL.search,
        equipmentType: this.lane_equipment_type,
      });

      if (currentURL.search.includes("broker")) {
        crumbIds.push(stateAPI.getStateProperty(this, "broker"));
      } else if (currentURL.search.includes("shipper")) {
        crumbIds.push(stateAPI.getStateProperty(this, "shipper"));
      }

      stateAPI.setStateProperty(this, "crumbs", this.crumbs);
      stateAPI.setStateProperty(this, "crumbIds", crumbIds);
    },

    getURLParams() {
      var url_string = window.location.href;
      var url = new URL(url_string);
      var c;

      if (url.searchParams.get("broker")) {
        c = url.searchParams.get("broker");
      } else if (url.searchParams.get("shipper")) {
        c = url.searchParams.get("shipper");
      }
      return c.replace(/_/g, " ");
    },

    getURLEquipmentType() {
      var url_string = window.location.href;
      var url = new URL(url_string);

      return url.searchParams.get("equipment_type");
    },

    onResize() {
      this.gaugeCardWidth = this.$refs.gaugeCard.$el.clientWidth;
      this.browserZoom = Math.round(window.devicePixelRatio * 100);
    },

    parseURLPathName(isShipmentTableChange = false) {
      this.queryInProgress = isShipmentTableChange ? false : true;
      this.getURLParams();
      this.isLaneData = true;

      utils.getData(this, "drilldown", isShipmentTableChange).then((result) => {
        utils.formatDates(this.startDate, this.endDate, this.timePeriod, this);
        this.computeKeyMetricsFilters();
        this.metricTrendTypeTabs = this.metricTrendTypeIndex;
        this.allowSecondLoad = true;
        this.$refs.bundle.displayedData = this.displayedData;
        this.queryInProgress = false;
        this.isLoading = false;
      });
    },

    changeMetricTrend(metricType, index) {
      if (
        !this.disableMetricTrendCPM &&
        !this.disableMetricTrendMPL &&
        !this.disableMetricTrendVOL
      ) {
        this.metricTrendType = metricType;
        this.metricTrendTypeIndex = index;
        if (metricType == "CPM") {
          this.metricTrends = _.cloneDeep(this.cpmGraphArray);
        } else if (metricType == "MPL") {
          this.metricTrends = _.cloneDeep(this.mplGraphArray);
        } else if (metricType == "VOL") {
          this.metricTrends = _.cloneDeep(this.volGraphArray);
        }
      }
    },

    changeCostSpendGraph(toSet) {
      this.costSpendGraphType = toSet;
      const graphs = document.querySelectorAll(".costSpendGraph");
      if (toSet == "LINE") {
        this.costSpendGraphIndex = 0;
        const translate = 0;
        graphs.forEach(graphs => (graphs.style.transform = `translateX(${translate}%)`));
      } else if (toSet == "BAR") {
        this.costSpendGraphIndex = 1;
        const translate = -100;
        graphs.forEach(graphs => (graphs.style.transform = `translateX(${translate}%)`));
      }
    },

    changeVolumeGraph(toSet) {
      this.volumeGraphType = toSet;
      const graphs = document.querySelectorAll(".volumeGraph");
      if (toSet == "LINE") {
        this.volumeGraphIndex = 0;
        const translate = 0;
        graphs.forEach(graphs => (graphs.style.transform = `translateX(${translate}%)`));
      } else if (toSet == "BAR") {
        this.volumeGraphIndex = 1;
        const translate = -100;
        graphs.forEach(graphs => (graphs.style.transform = `translateX(${translate}%)`));
      }
    },



    customFilter(_, queryText, itemText) {
      const cleanedQuery = queryText.replace(/[&@\\/#?!|^_,`+=()$~%.'";:*?<>{}]/g, ' ').toLocaleLowerCase();
      const queryTokens = cleanedQuery.split(' ');
      const fillers = ["to", "and"];
      for (const token of queryTokens) {
        if (fillers.includes(token)) { continue; }
        if (itemText.toLocaleLowerCase().indexOf(token) === -1) {
          return false;
        }
      }
      return true;
    },

    searchselectedSB() {
      this.isLoading = true;
      this.parseURLPathName();
    },

    handleFocusLanes(val) {
      this.isLoading = true;
      this.focusLanes = val;
      this.parseURLPathName(true);
    },

    handleFavoriteLanes(val) {
      this.isLoading = true;
      this.favoriteLanes = val;
      this.parseURLPathName(true);
    },

    handleLaneClick(event) {
      this.isLoading = true;
      this.drilldownObject = event; 
      this.laneDrilldown = true;
    }
  },

  beforeMount() {
    this.selectedSB = this.prop_sb_id != undefined ? this.prop_sb_id : null;
    this.getBreadCrumb();
    this.getURLParams();
    const kmt = stateAPI.getStateProperty(this, "storeKeyMetricsToggle");
    if (kmt == null || kmt == undefined) {
      this.handleEmmittedFilters(
        this.endDate,
        this.timePeriod,
        this.volumeThreshold,
        this.selectedEquipmentTypes,
        this.isComparisonEnabled,
        this.filters
      );
    } else {
      this.handleKeyMetricTabChange(kmt);
    }

    var url = new URL(window.location.href);
    var page_title;
    if (url.searchParams.get("broker")) {
      page_title = "Broker Drilldown";
    } else if (url.searchParams.get("shipper")) {
      page_title = "Shipper Drilldown";
    }

    // Send pageView analytics event
    const email = stateAPI.getStateProperty(this, "email");
    const company = stateAPI.getStateProperty(this, "company");
    const role = stateAPI.getStateProperty(this, "role");
    user_analytics.sendEvent(email, company, role, "pageView", { pageSource: page_title.replaceAll(' ', '') });
  },

  mounted() {
    var metric_el = document.getElementById('remove-v-tooltip');
    metric_el.classList.remove('v-tooltip');
    metric_el.classList.remove('v-tooltip--top');

    this.gaugeCardWidth = this.$refs.gaugeCard.$el.clientWidth;
    this.browserZoom = Math.round(window.devicePixelRatio * 100);
  },

  data: function () {
    return {
      laneDrilldown: false,
      drilldownObject: null,
      allowSecondLoad: false,
      lane_equipment_type: this.getURLEquipmentType(),
      keyMetricsToggle: null,
      dataFreshnessLabel: "",
      showAggTabs: true,
      defaultTabValue: null,
      percentColor: "#0091ff",
      percentToDollars: false,
      averageScore: 0,
      index_by: "brokerId",
      query_params: {},
      index_by_lane: "laneId",
      query_params_lane: {},
      index_by_shipper: "shipperId",
      query_params_shipper: {},
      displayedData: [], // TODO this is also used in filters.
      originalData: [], // TODO this is also used in filters.
      broker: this.getURLParams(),
      isLoading: true,
      key_metrics: [
        {
          value: "0%",
          title: "Avg. Margin",
          icon: "",
          color: "",
          id: "avg_margin",
          change: "",
        },
        {
          value: "$0",
          title: "MPL",
          icon: "",
          color: "",
          id: "avg_margin_dollars",
          change: "",
        },
        {
          value: "0$",
          title: "Total Margin",
          icon: "",
          color: "",
          id: "total_margin",
          change: "",
        },
        {
          value: "0$",
          title: "Total Spend",
          icon: "",
          color: "",
          id: "total_spend",
          change: "",
        },
        {
          value: "0$",
          title: "SPL",
          icon: "",
          color: "",
          id: "avg_spend",
          change: "",
        },
        {
          value: "0",
          title: "Volume",
          icon: "",
          color: "",
          id: "total_volume",
          change: "",
        },
        {
          value: "0 Days",
          title: "Avg. CLT",
          icon: "",
          color: "",
          id: "avg_clt",
          change: "",
        },
        {
          value: "0 Days",
          title: "Avg. BLT",
          icon: "",
          color: "",
          id: "avg_blt",
          change: "",
        },
        {
          value: "0 Days",
          title: "Avg. Pre-Book",
          icon: "",
          color: "",
          id: "avg_prebook",
          change: "",
        },
        {
          value: "0%",
          title: "On-Time Pickup",
          icon: "",
          color: "",
          id: "avg_otp",
          change: "",
        },
        {
          value: "0%",
          title: "On-Time Delivery",
          icon: "",
          color: "",
          id: "avg_otd",
          change: "",
        },
        {
          value: "0%",
          title: "Prebook",
          icon: "",
          color: "",
          id: "prebook_percent",
          change: "",
        },
        {
          value: "0%",
          title: "Avg. Margin",
          icon: "",
          color: "",
          id: "proj_avg_margin",
          change: "",
        },
        {
          value: "0$",
          title: "MPL",
          icon: "",
          color: "",
          id: "proj_avg_margin_dollars",
          change: "",
        },
        {
          value: "0$",
          title: "Total Margin",
          icon: "",
          color: "",
          id: "proj_total_margin",
          change: "",
        },
        {
          value: "0$",
          title: "Total Spend",
          icon: "",
          color: "",
          id: "proj_total_spend",
          change: "",
        },
        {
          value: "0$",
          title: "SPL",
          icon: "",
          color: "",
          id: "proj_avg_spend",
          change: "",
        },
        {
          value: "0",
          title: "Volume",
          icon: "",
          color: "",
          id: "proj_total_volume",
          change: "",
        }
      ],

      crumbs: stateAPI.getStateProperty(this, "crumbs"),
      date: new Date().toISOString().substr(0, 10),

      periodDates: "", // TODO this is also used in filters.
      oldPeriodDates: "", // TODO this is also used in filters.
      // TODO(pass this as a prop to Filters.vue and modify those variables directly)
      keyMetrics: null,

      selectedEquipmentTypes: stateAPI.getStateProperty(
        this,
        "equipment_type_list"
      ),
      EQUIPMENT_TYPE_LIST: stateAPI.getStateProperty(
        this,
        "equipment_type_list"
      ),
      // timePeriod:
      //   (new Date().getTime() -
      //     new Date(stateAPI.getStateProperty(this, "startDate")).getTime()) /
      //     (24 * 60 * 60 * 1000) +
      //   1,
      timePeriod: stateAPI.getStateProperty(this, "storeTimePeriod"),
      tickLabels: ["Last 7", "Last 14", "Last 30", "Last 60", "Last 90", "All"],
      tickValues: [7, 14, 30, 60, 90],

      // Chips v-model indexed by filter type (e.g. "equipment_Dry Van") with value (e.g. "Equip. Type: Dry Van").
      filterChips: new Map(),
      volumeThreshold: stateAPI.getStateProperty(this, "storeVolumeThreshold"),

      gaugeCardWidth: 0,
      gaugeCardHeight: 0,
      browserZoom: 100,
      showStartDate: true,
      isPeriodOneDay: false,
      isPeriodNull: true,
      startDate: new Date(
        new Date(stateAPI.getStateProperty(this, "startDate")).setHours(5, 0, 0)
      ),
      isBrokerUser: stateAPI.getStateProperty(this, "role") == "broker",
      isAdminUser: stateAPI.getStateProperty(this, "role") == "admin",
      endDate: new Date(
        new Date(stateAPI.getStateProperty(this, "storeEndDate")).setHours(
          5,
          0,
          0
        )
      ),
      deltaStartDate: null,
      deltaEndDate: null,
      //TODO: remove this variable once null check is in backend, it is not applicable
      metricTrendType: "CPM",
      metricTrendTypeTabs: "CPM",
      metricTrendTypeIndex: 0,

      // Cost Spend Graphs
      costSpendGraphType: "LINE",
      costSpendGraphIndex: 0,
      // Volume Graphs
      volumeGraphType: "LINE",
      volumeGraphIndex: 0,

      showFilterChips: false,
      isComparisonEnabled: stateAPI.getStateProperty(
        this,
        "storeIsComparisonEnabled"
      ),
      tabValues: [14, 30, 60],
      filters: {
        dates: stateAPI.getStateProperty(this, "storeDates"),
        timeSlider: stateAPI.getStateProperty(this, "storeTimeSlider"),
        volume: stateAPI.getStateProperty(this, "storeVolumeThreshold"),
        equipment: stateAPI.getStateProperty(this, "equipment_type_list"),
        isCustomerDirectEnabled: stateAPI.getStateProperty(this, "storeIsCustomerDirectEnabled")
      },
      cpmGraphArray: [],
      mplGraphArray: [],
      volGraphArray: [],
      metricTrends: null,
      isDashboard: false,
      disableMetricTrendCPM: true,
      disableMetricTrendMPL: true,
      disableMetricTrendVOL: true,
      showVolumeGraph: false,
      costSpendVolumeData: [],
      costVolumeBarData: [],
      cpmLaneCpmNetworkData: [],
      onTimeGraphData: [],
      carouselModel: 0,
      isLaneData: true,
      selectedLanes: {},
      dropdownSBList: [],
      selectedSB: null,
      hasIncompleteScore: this.has_incomplete_score,
      load: true,
      queryInProgress: false,
      isCustomerDirectEnabled: stateAPI.getStateProperty(this, "storeIsCustomerDirectEnabled"),
      focusLanes: [],
      favoriteLanes: []
    };
  },
};
</script>

<style>
.green {
  color: green;
}
.red {
  color: red;
}
.metric-value {
  font-weight: 400;
  font-size: 23px;

}
.v-data-table__expanded__row {
  background-color: var(--v-vdtExpandedRowColor-base);
}
.v-data-table-header th {
  white-space: nowrap;
}
.shipmentHeaderStyling {
  background-color: var(--v-shipmentHeaderStylingColor-base);
}

.selectedTab {
  color: #2196f3 !important;
}
.selectedTab > .v-btn__content{
  opacity: 1 !important;
}

.rotate-135 {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.graphSlide{
  width: 200%;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
}

.costSpendGraph {
  transition: 250ms;
  width: 100%;
}

.volumeGraph {
  transition: 250ms;
  width: 100%;
}
</style>
