<template>
    <v-app :style="{background: $vuetify.theme.themes[theme].background}">
        <v-main>
            <v-container fluid dense
                id="pageContainer"
            >
                <v-container
                    class="logContainer"
                    :class="active ? 'active': ''"
                >
                    <transition-group
                        name="slide-up"
                    >
                        <v-card
                            class="message"
                            :class="msg.type"
                            flat
                            v-for="msg in messages"
                            :key="msg.msgid"
                            height="min-content"
                        >
                            <!--v-card-title v-if="!msg.isQuery" style="position:absolute; top:0; right:0">
                                <div>Response</div>
                                <v-spacer/>
                                <v-btn icon color="blue lighten-3">
                                    <v-icon dark>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                                <v-btn icon color="green lighten-3">
                                    <v-icon dark>
                                        mdi-thumb-up
                                    </v-icon>
                                </v-btn>
                                <v-btn icon color="red lighten-3"> 
                                    <v-icon dark>
                                        mdi-thumb-down
                                    </v-icon>
                                </v-btn>
                            </v-card-title-->
                            <v-card-text>
                                <div 
                                    v-if="'content' in msg && 'text' in msg.content"
                                    style="white-space: pre-wrap"
                                >
                                    {{ msg.content.text }}
                                </div>
                                <div v-html="msg.content.markdown" v-if="'content' in msg && 'markdown' in msg.content" class="pt-6"></div>
                            </v-card-text>
                            <l-map 
                                style="height: 300px" 
                                :zoom="zoom" 
                                :center="center" 
                                v-if="'content' in msg && 'map' in msg.content"
                            >
                                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                                <l-marker :lat-lng="markerLatLng"></l-marker>
                            </l-map>
                            <v-img
                                :width="300"
                                v-if="'content' in msg && 'map' in msg.content"
                                aspect-ratio="16/9"
                                cover
                                src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
                            ></v-img>
                        </v-card>
                    </transition-group>
                </v-container>
                <v-spacer/>
                <v-textarea
                    ref="textField"
                    v-model="text"
                    class="textField"
                    :class="active ? 'active': ''"
                    rows="2"
                    filled
                    auto-grow
                    no-resize
                    background-color="rgba(120, 120, 120, 0.2)"
                    @keydown="handleKeyboard"
                ></v-textarea>
                <v-checkbox label="Dev Mode" v-model="dev_mode"></v-checkbox>
            </v-container>
        </v-main>
    </v-app>
</template>
<script>
import * as queryLLM from "../queryLLM";
import Vue from 'vue';
import L from 'leaflet';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

import { marked } from 'marked';
marked.use({
    mangle: false,
    headerIds: false,
});

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

export default {
    name: "ChatStream",
    props: [],

    components: {
        LMap, LTileLayer, LMarker
    },

    methods: {
        handleKeyboard(event){
            if (event.key === 'Enter' && !event.shiftKey && this.text.replace(/\s/g,'') !== "") {
                event.preventDefault();
                this.submit();
            }
        },
        submit() {
            this.active = true;
            // add text to messages and clear field
            this.logQuery(this.text)
            // if (this.text.includes('image')) {
            //     this.showMockImage = true
            // }

            // hit API using text, then use returned value to make query
            this.init_response_card()
            this.send_query_socket(this.text);
            // this.parseResponse(responsePromise)
            this.$refs.textField.reset()
        },
        logQuery(queryText) {
            this.msgCounter = this.messages.length;
            this.messages.push({
                msgid: this.msgCounter,
                isQuery: true,
                content: {
                    text: queryText
                },
                type: "query",
            })
            this.msgCounter += 1;
        },
        parseResponse(promise) {
            promise.then((response) => {
                if (response.responseType === "ERROR") {
                    this.messages.push({ 
                        msgid: this.msgCounter, 
                        isQuery: false,    
                        title: "error",
                        content: {text: response.message},
                    })
                    this.msgCounter += 1
                    return
                }
                let messageContent = {}
                messageContent.markdown = this.markdownToHtml(response.message)
                if (this.showMockImage) {
                    this.messages.push({ 
                        msgid: this.msgCounter,    
                        isQuery: false,
                        title: "Sample Image",
                        content: {
                            image: "placeholder"
                        },
                    })
                } else {
                    this.messages.push({ 
                        msgid: this.msgCounter,   
                        isQuery: false,
                        // title: messageTitle,
                        content: messageContent,
                    })
                }
                this.msgCounter += 1
            })
        },
        init_response_card(){
            this.messages.push({ 
                        msgid: this.msgCounter,   
                        isQuery: false,
                        // title: messageTitle,
                        content: {text: ""},
                    })
        },

        add_token(event) {
            console.log(`[message] Token received from server: ${event.data}`);
            console.log(this.messages, this.msgCounter);
            var response_dict = JSON.parse(event.data);
            var response_token = response_dict["token"];
            //response_token = response_token
            this.messages[this.msgCounter]["content"]["text"] += response_token;
        },

        close_connection(event){
            if (event.wasClean) {
                    console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
                } else {
                    // e.g. server process killed or network down
                    // event.code is usually 1006 in this case
                    console.log('[close] Connection died');
                }         
        },

        reinit_agent(){
            var socket = new WebSocket(this.socket_url);
            socket.onopen = function(e) {
                socket.send(JSON.stringify({
                    action:"postMessage",
                    task_ip: "192.168.234.93",
                    endpoint: "new_conversation"
                }))
            }
        },

        send_query_socket(query_text) {
            var socket = new WebSocket(this.socket_url);
            var dev_mode = this.dev_mode;
            socket.onopen = function(e) {
                    console.log("[open] Connection established");
                    socket.send(JSON.stringify({
                            action:"postMessage",
                            task_ip:"192.168.234.93",
                            endpoint:"freightgpt",
                            message: query_text,
                            dev_mode: dev_mode
                        })
                    );
                };
            socket.onmessage = this.add_token;
            socket.onclose = this.close_connection;
            socket.onerror = function(error) {
                console.log(`[error]`);
            };
            // this.socket.send(JSON.stringify({
            //         action:"postMessage",
            //         task_ip:"192.168.91.142",
            //         message: query_text,
            //         dev_mode: this.dev_mode
            //     })
            // );
        }
    },

    computed:{
        theme() {
            return (this.$vuetify.theme.dark) ? 'dark' : 'light';
        },
    },

    mounted() {
        this.text = "";
        // this.socket = new WebSocket(this.socket_url);
        // this.socket.onopen = function(e) {
        //         console.log("[open] Connection established");
        //     };
        // this.socket.onmessage = this.add_token;
        // this.socket.onclose = this.close_connection;
        // this.socket.onerror = function(error) {
        //     console.log(`[error]`);
        // };
        this.reinit_agent();
    },

    watch: {
    },

    data: function () {
        return {
            active: false,
            socket_url: "wss://wps3vtux75.execute-api.us-east-1.amazonaws.com/test",
            socket: null,
            text: "",
            messages: [],
            msgCounter: 0,
            dev_mode: false
        };
    },
};
</script>
<style>
#pageContainer {
    width: 91%; 
    height: 100%; 
    max-height: 100%; 
    display:flex; 
    flex-direction: column; 
    justify-content: flex-end; 
    align-items: center;
    padding-bottom: 32px;
}

.textField  {
    width: 50%;
    height: fit-content;
    max-height: 20vh;
    flex-grow: 0;
    border-radius: 4px;
    position:relative;
    top: -50vh;

}
.textField.active { 
    top: 0;
    transition-duration: 0.8s;
    transition-property: top;
}
.textField textarea {
    min-height: 54px;
    max-height: 15vh;
    height: fit-content;
}
.textField::-webkit-scrollbar-corner {
    display: none;
}

.logContainer {
    max-height: 80vh;
    max-width: 60%;
    display: none;
    /* top: -50vh; */
    overflow-y: auto;
    position: relative;
}
.logContainer.active {
    display: block;
}

.message {
    margin-bottom: 8px;
}
.query { 
    background-color: rgba(120, 120, 180, 0.2) !important;
}
.response {
    background-color: rgba(120, 180, 120, 0.2) !important;
}

.slide-up-enter-active {
    transition: all .4s ease;
}
.slide-up-enter {
    transform: translateY(300px);
    opacity: 0;
}

/* * { outline: 1px solid aliceblue; } */
</style>