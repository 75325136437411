<template>
  <form autocomplete="email">
    <input
      type="password"
      style="display: none"
      name="whatever"
      autocomplete="email"
    />
    <v-text-field
      prepend-inner-icon="mdi-account"
      label="Email"
      :rules="[rules.required]"
      :value="email"
      v-model="email"
      @input="emitEmail"
      ref="emailField"
      :autofocus="true"
      @keyup.enter="emitLogin"
    ></v-text-field>

    <v-text-field
      prepend-inner-icon="mdi-lock"
      :rules="[rules.required]"
      :append-icon="password_visibility ? 'mdi-eye-off' : 'mdi-eye'"
      @click:append="() => (password_visibility = !password_visibility)"
      :type="password_visibility ? 'password' : 'text'"
      label="Password"
      v-model="password"
      @input="emitPassword"
      @keyup.enter="emitLogin"
    ></v-text-field>

    <p class="link forgot-password" @click="emitForgotPassword" style="color: '#545454'">
      Forgot Password?
    </p>

    <br />
    <br />
    <br />
  </form>
</template>

<script>
export default {
  name: "LoginScreen",


  data: function () {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },

      email: "",
      password: "",
      password_visibility: true,
    };
  },

  mounted: function () {
    let intervalDetectAutofill = setInterval(() => {
      if (
        document.querySelectorAll('input[type="text"]:-webkit-autofill')
          .length > 0
      ) {
        var fields = document.getElementsByClassName("v-label theme--light");
        var emField = fields[0];
        var pwField = fields[1];
        emField.classList.add("v-label--active");
        pwField.classList.add("v-label--active");

        clearInterval(intervalDetectAutofill);
      }
    }, 50)

    setTimeout(() => {
      if (intervalDetectAutofill) {
        clearInterval(intervalDetectAutofill);
        intervalDetectAutofill = null;
      }
    }, 1000)
  },

  methods: {
    emitEmail() {
      // remove all whitespace and emit
      this.$emit("emailUpdate", this.email.replace(/\s/g, ""));
    },

    emitPassword() {
      // remove all whitespace and emit
      this.$emit("passwordUpdate", this.password.replace(/\s/g, ""));
    },

    emitForgotPassword() {
      this.$emit("forgotPassword", true);
    },

    emitLogin(e) {
      //sign in if enter is pressed
      this.$emit("triggerLogin");
    },
  },
};
</script>

<style>
.link {
  font-size: 13px;
  color: grey;
}

.login-button {
  background: linear-gradient(90deg, #ff7a10, #ff7a10) !important;
  color: white !important;
  border-radius: 100px !important;
  margin-bottom: 10px;
}

.forgot-password {
  float: right;
  cursor: pointer;
}
</style>
