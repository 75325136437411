<template>
  <v-app-bar clipped-left app dense :style="{background: $vuetify.theme.themes[theme].navbar}">
    <div class="d-flex align-center">
      <v-img
        alt="Truce Logo"
        class="shrink mr-2"
        contain
        src="../assets/Truce_Logo.png"
        width="120"
      />
    </div>
    <v-spacer></v-spacer>
    <SearchBar />

    <v-menu
      v-model="notificationMenu"
      :close-on-content-click="false"
      :nudge-width="200"
      transition="slide-y-transition"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" dark>
          <v-badge dot left color="red" overlap :value="new_notifs.length != 0">
            <v-icon :color="$vuetify.theme.themes[theme].iconColor">mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-card>
        <v-list style="max-height: 200px" class="overflow-y-auto" two-line>
          <template v-for="(item, index) in new_notifs">
            <v-subheader v-if="item.header" :key="item.header">
              {{ item.header }}
            </v-subheader>
            <v-divider
              v-else-if="item.divider"
              :key="index"
              :inset="item.inset"
            ></v-divider>
            <v-list-item v-else :key="item.subject">
              <v-icon medium color="blue darken-4" style="margin-right: 24px"
                >mdi-bell</v-icon
              >

              <v-list-item-content class="pr-1">
                <v-badge dot color="#FFD200" overlap>
                  <v-list-item-title
                    >{{ item.subject }} - {{ item.sender }}</v-list-item-title
                  >
                  <v-list-item-subtitle
                    style="max-width: 400px"
                    v-html="item.message"
                  ></v-list-item-subtitle>
                </v-badge>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>

        <v-list>
          <v-list-item v-if="new_notifs.length == 0">
            <v-list-item-title
              style="text-align: center; color: gray; cursor: default"
              >No new notifications</v-list-item-title
            >
          </v-list-item>
          <v-list-item class="notification-hover" @click="goToNotifications">
            <v-list-item-title style="text-align: center"
              >See all notifications</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <hr class="divider"/>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="slide-y-transition"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="user-button" v-bind="attrs" v-on="on">
          <div class="mr-2 icon-color">{{ usn }}</div>
          <v-avatar size="35">
            <v-icon size="32" :color="$vuetify.theme.themes[theme].iconColor">mdi-account-circle</v-icon>
          </v-avatar>
        </v-btn>
      </template>

      <v-card elevation="0" rounded max-width="300px">
        <v-list style="border-top: 1px solid lightgrey">
          <v-list-item>
            <v-switch
              v-model="isDarkMode"
              :label="`${isDarkMode ? 'Disable' : 'Enable'} Dark Mode`"
              @change="toggleDarkMode()"
              class="darkModeSwitch"
            ></v-switch>
          </v-list-item>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            @click="item.func"
          >
            <v-icon v-text="item.icon" style="margin-right: 10px" :color="$vuetify.theme.themes[theme].iconColor"></v-icon>
            <v-list-item-title class="icon-color">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>
<script>
import * as stateAPI from "../stateAPI";
import SearchBar from "./Search";
import * as fetchAccountDetails from "../fetchAccountDetails";
import { Auth } from "aws-amplify";
import * as fetch from "../fetchNotifications";

export default {
  name: "NavBar",
  props: ["prop_username"],
  components: {
    SearchBar,
  },

  computed:{
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light';
    }
  },

  methods: {
    async signOut() {
      try {
        await Auth.signOut({ global: true });
        this.$router.push({
          name: "app",
          params: { prop_usn: "Roop Pal", prop_toggle: "left" },
        });
      } catch (error) {
        console.log("error signing out: ", error);
      }
    },

    goToNotifications() {
      this.$router.push({
        name: "notifications",
        params: { prop_usn: "Roop Pal" },
      });
    },

    goToAccount() {
      this.$router.push({
        name: "account",
        params: { prop_usn: "Roop Pal" },
      });
    },

    getCurrentConfig() {
      const storedEmail = stateAPI.getStateProperty(this, "email");
      const query_params = {
        email: storedEmail,
        config: 1
      };
      fetchAccountDetails.getAccountDetails(query_params).then((result) => {
        if (result != undefined && result.status == 200) {
          const cfg = JSON.parse(result.data.records[0].config);
          this.curConfig = cfg;
          this.isDarkMode = this.curConfig.darkMode ? this.curConfig.darkMode : false;
        }
      });
    },

    toggleDarkMode() {
      stateAPI.setStateProperty(this, "darkMode", this.isDarkMode);
      this.$vuetify.theme.dark = this.isDarkMode;
      this.curConfig.darkMode = this.isDarkMode;
      const query_params = {
        email: this.email,
        config: 1,
        new_config: JSON.stringify(this.curConfig)
      };

      fetchAccountDetails.updateAccountDetails(query_params);
    }
  },

  mounted() {
    const date = new Date();
    const today = date.toISOString().split("T")[0];
    const prior_week = new Date(date.getTime() - 60 * 60 * 24 * 7 * 1000)
      .toISOString()
      .split("T")[0];
    const params = {
      email: stateAPI.getStateProperty(this, "email"),
      start_date: prior_week,
      end_date: today,
      new: 1,
    };
    fetch.getNotifications(params).then((result) => {
      this.new_notifs = result;
    });
  },

  beforeMount() {
    this.getCurrentConfig();
  },

  data: function() {
    return {
      usn: stateAPI.getStateProperty(this, "username"),
      menu: false,
      notificationMenu: false,
      items: [
        { title: "Account", icon: "mdi-account", func: this.goToAccount },
        { title: "Sign Out", icon: "mdi-logout", func: this.signOut },
      ],
      new_notifs: [],
      isDarkMode: false,
      // Config object in db
      curConfig: null,
      email: stateAPI.getStateProperty(this, "email")
    };
  },
};
</script>
<style>
.nav-right-margin {
  margin-right: 15px;
}
.divider {
  margin-right: 5px;
  margin-left: 5px;
  width: 1px;
  height: 70%;
  color: var(--v-iconColor-base)
}
.user-button {
  color: white !important;
  background-color: transparent !important;
  box-shadow: 0px 0px !important;
}
.notification-hover:hover {
  cursor: pointer;
}

.icon-color {
  color: var(--v-iconColor-base);
}
</style>
