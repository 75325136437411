<template>
  <v-app>
    <v-app-bar
      clipped-left
      flat
      app
      dark
      color="transparent"
      class="px-xl-14 px-lg-14 px-md-14 hidden-sm-and-down"
    >
      <div>
        <v-img
          alt="Truce Logo"
          id="logo"
          class="shrink mt-2"
          contain
          v-bind:src="require('../assets/' + logo_img)"
          width="140"
          style="margin-right: 120px"
          @click="toTop()"
        />
      </div>
      <v-spacer />
      <v-btn
        style="margin-left: 120px; font-size: 16px"
        class="mt-5 dmsans font-weight-bold"
        elevation="0"
        :color="btnColor_1"
        @click="contactFormDialog = true"
        text
      >
        Contact Us
      </v-btn>
      <v-btn
        style="margin-left: 10px; font-size: 16px"
        class="mt-5 dmsans font-weight-bold"
        elevation="0"
        :color="btnColor_1"
        text
        @click="navigateToApp()"
      >
        Sign In
      </v-btn>
    </v-app-bar>
    <v-app-bar
      flat
      app
      dark
      color="transparent"
      class="pa-0 ma-0 hidden-md-and-up"
    >
      <div>
        <v-img
          alt="Truce Logo"
          class="shrink mt-2"
          contain
          v-bind:src="require('../assets/' + logo_img)"
          width="140"
        />
      </div>
    </v-app-bar>

    <v-btn
      v-scroll="updateScroll"
      v-show="backToTop"
      fab
      dark
      fixed
      bottom
      right
      elevation="0"
      color="blue darken-4"
      @click="toTop"
      :class="backToTopMargin"
      class="hidden-md-and-down"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>

    <v-main class="pa-0" align="center" justify="center">
      <v-dialog v-model="contactFormDialog" persistent max-width="600px">
        <v-form ref="contactForm" v-model="contactFormValid">
          <v-card class="pa-6" style="background-color: white; color: black">
            <v-card-title>
              <span class="text-h5">Contact Us</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="Name*"
                      :rules="[rules.required, rules.alphanumeric]"
                      v-model="name"
                      light
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Company"
                      :rules="[rules.alphanumeric]"
                      v-model="company"
                      light
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Email*"
                      :rules="[rules.required, rules.email]"
                      v-model="email"
                      light
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      name="input-7-1"
                      label="Comments"
                      :rules="[rules.counter, rules.alphanumeric]"
                      outlined
                      v-model="comment"
                      counter
                      :maxlength="this.commentMaxLength"
                      light
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="contactFormDialog = false; resetForm()"> Cancel </v-btn>
              <v-btn
                light
                color="green"
                text
                :disabled="!contactFormValid"
                @click="
                  sendEmail();
                "
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <section id="header">
        <v-container fluid class="pa-0">
          <v-img
            src="../assets/header_blue.jpg"
            style="height: 100vh"
            class="align-center"
            gradient="to bottom, rgba(0,0,0,0.7), transparent 40vh"
          >
            <v-row style="margin-top: -100px !important">
              <v-col cols="12" xl="8" lg="8" md="8" sm="12">
                <div
                  class="
                    text-h5 text-sm-h4 text-md-h3 text-lg-h2 text-xl-h2
                    font-weight-bold
                    ml-xl-auto ml-lg-auto ml-md-auto
                    mr-xl-0 mr-lg-0 mr-md-0
                    mx-sm-auto mx-auto
                    text-center
                    text-sm-center
                    text-md-left
                    text-lg-left
                    text-xl-left
                    mb-7
                    dmsans
                  "
                  style="color: white; line-height: 1.1; width: 80%"
                >
                  Data-transparent relationships <br />
                  for shippers and 3PLs
                </div>
                <div
                  class="
                    text-subtitle-1 text-sm-h6 text-md-h5 text-lg-h4 text-xl-h4
                    ml-xl-auto ml-lg-auto ml-md-auto
                    mr-xl-0 mr-lg-0 mr-md-0
                    mx-sm-auto mx-auto
                    text-center
                    text-sm-center
                    text-md-left
                    text-lg-left
                    text-xl-left
                    hidden-sm-and-down
                    mb-7
                    dmsans
                  "
                  style="color: white; width: 80%"
                >
                  Truce enables trusted partnerships, <br />
                  sustainable cost reduction, and <br />
                  improved on time delivery.
                </div>
                <div
                  class="
                    text-subtitle-1
                    mx-auto
                    text-center
                    hidden-md-and-up
                    font-weight-bold
                    mb-7
                    dmsans
                  "
                  style="color: #0d47a1; width: 80%"
                >
                  Truce enables trusted partnerships, <br />
                  sustainable cost reduction, and <br />
                  improved on time delivery.
                </div>
                <div
                  class="
                    ml-xl-auto ml-lg-auto ml-md-auto
                    mr-xl-0 mr-lg-0 mr-md-0
                    mx-sm-auto mx-auto
                    text-center
                    text-sm-center
                    text-md-left
                    text-lg-left
                    text-xl-left
                  "
                  style="color: white; width: 80%"
                >
                  <v-btn
                    style="font-size: 17px; color: #0091ff"
                    class="mt-4 pa-6 dmsans font-weight-bold"
                    elevation="0"
                    color="white"
                    @click="contactFormDialog = true"
                  >
                    Request Demo
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row
              justify="center"
              style="position: relative; bottom: -25vh"
              class="hidden-sm-and-down"
            >
              <v-btn
                fab
                outlined
                color="white"
                style="opacity: 1 !important"
                @click="$vuetify.goTo('#platform', { offset: -76 })"
              >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </v-row>
          </v-img>
        </v-container>
      </section>

      <section
        id="platform"
        class="application"
        style="height: 102vh !important; background-color: #f7f9fe !important"
      >
        <v-container fluid class="pa-0">
          <v-row
            class="pa-0 ma-0 align-center"
            style="height: 102vh; max-width: 100%"
          >
            <v-col cols="6" class="pr-13 hidden-sm-and-down">
              <div class="ml-auto mb-7" style="width: 80%; max-width: 800px">
                <v-img :src="platformImage.src"> </v-img>
              </div>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              class="pl-xl-13 pl-lg-13 pl-md-13"
            >
              <div
                class="
                  mr-xl-auto mr-lg-auto mr-md-auto
                  ml-xl-0 ml-lg-0 ml-md-0
                  mx-sm-auto mx-auto
                  mb-7
                  dmsans
                "
                style="width: 80%; max-width: 800px"
              >
                <div
                  class="
                    text-xl-h5
                    text-lg-h5
                    text-md-h6
                    text-sm-subtitle-1
                    text-body-1
                    ml-xl-auto ml-lg-auto ml-md-auto
                    mr-xl-0 mr-lg-0 mr-md-0
                    mx-sm-auto mx-auto
                    text-center
                    text-sm-center
                    text-md-left
                    text-lg-left
                    text-xl-left
                    mb-2
                    dmsans
                  "
                  style="color: #a6a8b0"
                >
                  P L A T F O R M
                </div>
                <div
                  class="
                    text-xl-h2 text-lg-h2 text-md-h3 text-sm-h4 text-h6
                    ml-xl-auto ml-lg-auto ml-md-auto
                    mr-xl-0 mr-lg-0 mr-md-0
                    mx-sm-auto mx-auto
                    text-center
                    text-sm-center
                    text-md-left
                    text-lg-left
                    text-xl-left
                    mb-xl-7 mb-lg-7 mb-md-7 mb-sm-3 mb-3
                    font-weight-bold
                    dmsans
                  "
                  style="color: #0091ff"
                >
                  Introducing the Truce Transparency Platform
                </div>
                <div
                  class="
                    text-xl-h5
                    text-lg-h5
                    text-md-h6
                    text-sm-subtitle-1
                    text-body-2
                    ml-xl-auto ml-lg-auto ml-md-auto
                    mr-xl-0 mr-lg-0 mr-md-0
                    mx-sm-auto mx-auto
                    text-center
                    text-sm-center
                    text-md-left
                    text-lg-left
                    text-xl-left
                    mb-7
                    font-weight-light
                    pr-xl-16 pr-lg-16 pr-md-16
                    dmsans
                  "
                  style="color: black"
                >
                  From metrics on performance and margin to advanced analytics
                  with machine learning, Truce provides an ultramodern platform
                  that enables you to take your freight partnerships to the next
                  level.
                </div>
              </div>
              <div
                class="mx-auto mb-7 hidden-md-and-up"
                style="width: 70%; max-width: 800px"
              >
                <v-img :src="platformImage.src"> </v-img>
              </div>
            </v-col>
          </v-row>
          <v-row
            justify="center"
            style="position: relative; bottom: 13vh"
            class="hidden-sm-and-down"
          >
            <v-btn
              fab
              outlined
              color="blue darken-4"
              style="opacity: 1 !important"
              @click="$vuetify.goTo('#trust', { offset: -76 })"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </section>

      <section id="trust" style="height: 102vh !important; background-color: white">
        <v-container fluid class="pa-0">
          <v-row
            class="pa-0 ma-0 align-center"
            style="height: 102vh; max-width: 100%"
          >
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              class="pl-xl-13 pl-lg-13 pl-md-13"
            >
              <div
                class="
                  ml-xl-auto ml-lg-auto ml-md-auto
                  mr-xl-0 mr-lg-0 mr-md-0
                  mx-sm-auto mx-auto
                  mb-7
                "
                style="width: 80%; max-width: 800px"
              >
                <div
                  class="
                    text-xl-h5
                    text-lg-h5
                    text-md-h6
                    text-sm-subtitle-1
                    text-body-2
                    ml-xl-auto ml-lg-auto ml-md-auto
                    mr-xl-0 mr-lg-0 mr-md-0
                    mx-sm-auto mx-auto
                    text-center
                    text-sm-center
                    text-md-left
                    text-lg-left
                    text-xl-left
                    mb-2
                    dmsans
                  "
                  style="color: #a6a8b0"
                >
                  T R U S T
                </div>
                <div
                  class="
                    text-xl-h2 text-lg-h2 text-md-h3 text-sm-h4 text-h5
                    ml-xl-auto ml-lg-auto ml-md-auto
                    mr-xl-0 mr-lg-0 mr-md-0
                    mx-sm-auto mx-auto
                    text-center
                    text-sm-center
                    text-md-left
                    text-lg-left
                    text-xl-left
                    mb-xl-7 mb-lg-7 mb-md-7 mb-sm-3 mb-3
                    font-weight-bold
                    dmsans
                  "
                  style="color: #0091ff"
                >
                  Build the foundation for trusted partnerships
                </div>
                <div
                  class="
                    text-xl-h5
                    text-lg-h5
                    text-md-h6
                    text-sm-subtitle-1
                    text-body-2
                    ml-xl-auto ml-lg-auto ml-md-auto
                    mr-xl-0 mr-lg-0 mr-md-0
                    mx-sm-auto mx-auto
                    text-center
                    text-sm-center
                    text-md-left
                    text-lg-left
                    text-xl-left
                    mb-7
                    font-weight-light
                    pr-xl-16 pr-lg-16 pr-md-16
                    dmsans
                  "
                  style="color: black"
                >
                  Gain insight into the complexities of your brokers' business.
                  Leverage precise cost data to avoid paper rates and grow your
                  current partnerships.
                </div>
              </div>
              <div
                class="mx-auto mb-7 hidden-md-and-up"
                style="width: 70%; max-width: 800px"
              >
                <v-img :src="trustImage.src"> </v-img>
              </div>
            </v-col>
            <v-col cols="6" class="hidden-sm-and-down">
              <div class="mr-auto mb-7" style="width: 80%; max-width: 800px">
                <v-img :src="trustImage.src"> </v-img>
              </div>
            </v-col>
          </v-row>
          <v-row
            justify="center"
            style="position: relative; bottom: 13vh"
            class="hidden-sm-and-down"
          >
            <v-btn
              fab
              outlined
              color="blue darken-4"
              style="opacity: 1 !important"
              @click="$vuetify.goTo('#stability', { offset: -76 })"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </section>

      <section
        id="stability"
        style="height: 102vh !important; background-color: #eef1fc !important"
      >
        <v-container fluid class="pa-0">
          <v-row
            class="pa-0 ma-0 align-center"
            style="height: 102vh; max-width: 100%"
          >
            <v-col cols="6" class="pr-13 hidden-sm-and-down">
              <div class="ml-auto mb-7" style="width: 80%; max-width: 800px">
                <v-img :src="stabilityImage.src"> </v-img>
              </div>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              class="pl-xl-13 pl-lg-13 pl-md-13"
            >
              <div
                class="
                  mr-xl-auto mr-lg-auto mr-md-auto
                  ml-xl-0 ml-lg-0 ml-md-0
                  mx-sm-auto mx-auto
                  mb-7
                  dmsans
                "
                style="width: 80%; max-width: 800px"
              >
                <div
                  class="
                    text-xl-h5
                    text-lg-h5
                    text-md-h6
                    text-sm-subtitle-1
                    text-body-2
                    ml-xl-auto ml-lg-auto ml-md-auto
                    mr-xl-0 mr-lg-0 mr-md-0
                    mx-sm-auto mx-auto
                    text-center
                    text-sm-center
                    text-md-left
                    text-lg-left
                    text-xl-left
                    mb-2
                    dmsans
                  "
                  style="color: #a6a8b0"
                >
                  S T A B I L I T Y
                </div>
                <div
                  class="
                    text-xl-h2 text-lg-h2 text-md-h3 text-sm-h4 text-h5
                    ml-xl-auto ml-lg-auto ml-md-auto
                    mr-xl-0 mr-lg-0 mr-md-0
                    mx-sm-auto mx-auto
                    text-center
                    text-sm-center
                    text-md-left
                    text-lg-left
                    text-xl-left
                    mb-xl-7 mb-lg-7 mb-md-7 mb-sm-3 mb-3
                    font-weight-bold
                    dmsans
                  "
                  style="color: #0091ff"
                >
                  More stability and <br />
                  more commitment
                </div>
                <div
                  class="
                    text-xl-h5
                    text-lg-h5
                    text-md-h6
                    text-sm-subtitle-1
                    text-body-2
                    ml-xl-auto ml-lg-auto ml-md-auto
                    mr-xl-0 mr-lg-0 mr-md-0
                    mx-sm-auto mx-auto
                    text-center
                    text-sm-center
                    text-md-left
                    text-lg-left
                    text-xl-left
                    mb-7
                    font-weight-light
                    pr-xl-16 pr-lg-16 pr-md-16
                    dmsans
                  "
                  style="color: black"
                >
                  Understand your brokers' strongest lanes and commit to them
                  long-term. Insulate your network from market swings and create
                  stability and efficiency for you and your partners. Manage
                  your network without RFPs if you choose.
                </div>
              </div>
              <div
                class="mx-auto mb-7 hidden-md-and-up"
                style="width: 70%; max-width: 800px"
              >
                <v-img :src="stabilityImage.src"> </v-img>
              </div>
            </v-col>
          </v-row>
          <v-row
            justify="center"
            style="position: relative; bottom: 13vh"
            class="hidden-sm-and-down"
          >
            <v-btn
              fab
              outlined
              color="blue darken-4"
              style="opacity: 1 !important"
              @click="$vuetify.goTo('#network', { offset: -76 })"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </section>

      <section id="network" style="height: 102vh !important; background-color: white">
        <v-container fluid class="pa-0">
          <v-row
            class="pa-0 align-center ma-0"
            style="height: 102vh !important; max-width: 100%"
          >
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              class="pl-xl-13 pl-lg-13 pl-md-13"
            >
              <div
                class="
                  ml-xl-auto ml-lg-auto ml-md-auto
                  mx-sm-auto mx-auto
                  mr-xl-0 mr-lg-0 mr-md-0
                  mb-7
                "
                style="width: 80%; max-width: 800px"
              >
                <div
                  class="
                    text-xl-h5
                    text-lg-h5
                    text-md-h6
                    text-sm-subtitle-1
                    text-body-1
                    ml-xl-auto ml-lg-auto ml-md-auto
                    mr-xl-0 mr-lg-0 mr-md-0
                    mx-sm-auto mx-auto
                    text-center
                    text-sm-center
                    text-md-left
                    text-lg-left
                    text-xl-left
                    mb-2
                    dmsans
                  "
                  style="color: #a6a8b0"
                >
                  N E T W O R K
                </div>
                <div
                  class="
                    text-xl-h2 text-lg-h2 text-md-h3 text-sm-h4 text-h5
                    ml-xl-auto ml-lg-auto ml-md-auto
                    mr-xl-0 mr-lg-0 mr-md-0
                    mx-sm-auto mx-auto
                    text-center
                    text-sm-center
                    text-md-left
                    text-lg-left
                    text-xl-left
                    mb-xl-7 mb-lg-7 mb-md-7 mb-sm-3 mb-3
                    font-weight-bold
                    dmsans
                  "
                  style="color: #0091ff"
                >
                  Protect your budget and optimize your network
                </div>
                <div
                  class="
                    text-xl-h5
                    text-lg-h5
                    text-md-h6
                    text-sm-subtitle-1
                    text-body-2
                    ml-xl-auto ml-lg-auto ml-md-auto
                    mr-xl-0 mr-lg-0 mr-md-0
                    mx-sm-auto mx-auto
                    text-center
                    text-sm-center
                    text-md-left
                    text-lg-left
                    text-xl-left
                    mb-7
                    font-weight-light
                    pr-xl-16 pr-lg-16 pr-md-16
                    dmsans
                  "
                  style="color: black"
                >
                  Be proactive during market swings. No more wasteful
                  overspending in deflationary markets, and no more distrust
                  when markets are chaotic. Identify opportunities for
                  improvement with precise data, and execute on them with
                  confidence.
                </div>
              </div>
              <div
                class="mx-auto mb-7 hidden-md-and-up"
                style="width: 70%; max-width: 800px"
              >
                <v-img :src="networkImage.src"> </v-img>
              </div>
            </v-col>
            <v-col cols="6" class="hidden-sm-and-down">
              <div class="mr-auto mb-7" style="width: 80%; max-width: 800px">
                <v-img :src="networkImage.src"> </v-img>
              </div>
            </v-col>
          </v-row>
          <v-row
            justify="center"
            style="position: relative; bottom: 13vh"
            class="hidden-sm-and-down"
          >
            <v-btn
              fab
              outlined
              color="blue darken-4"
              style="opacity: 1 !important"
              @click="$vuetify.goTo('#contact', { offset: -76 })"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </section>

      <section
        id="contact"
        style="height: 90vh !important; background-color: #eef1fc !important"
      >
        <v-container fluid class="pa-0">
          <v-row
            class="pa-0 ma-0 align-center"
            style="height: 87vh; max-width: 100%"
          >
            <v-col cols="6" class="pr-13 hidden-sm-and-down">
              <div class="ml-auto mb-7" style="width: 80%; max-width: 800px">
                <v-img :src="contactUsImage.src"> </v-img>
              </div>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              class="pl-xl-13 pl-lg-13 pl-md-13"
            >
              <div
                class="
                  mr-xl-auto mr-lg-auto mr-md-auto
                  mx-sm-auto mx-auto
                  ml-xl-0 ml-lg-0 ml-md-0
                  mb-7
                "
                style="width: 80%; max-width: 800px"
              >
                <div
                  class="
                    text-xl-h2 text-lg-h2 text-md-h3 text-sm-h4 text-h5
                    ml-xl-auto ml-lg-auto ml-md-auto
                    mr-xl-0 mr-lg-0 mr-md-0
                    mx-sm-auto mx-auto
                    text-center
                    text-sm-center
                    text-md-left
                    text-lg-left
                    text-xl-left
                    mb-3
                    font-weight-bold
                    dmsans
                  "
                  style="color: #0091ff"
                >
                  Contact Us
                </div>
                <div
                  class="
                    text-xl-h5
                    text-lg-h5
                    text-md-h6
                    text-sm-subtitle-1
                    text-subtitle-2
                    ml-xl-auto ml-lg-auto ml-md-auto
                    mr-xl-0 mr-lg-0 mr-md-0
                    mx-sm-auto mx-auto
                    text-center
                    text-sm-center
                    text-md-left
                    text-lg-left
                    text-xl-left
                    mb-7
                    font-weight-light
                    pr-xl-16 pr-lg-16 pr-md-16
                    dmsans
                  "
                  style="color: black"
                >
                  Get in touch with our sales team! Request a demo below.
                </div>
                <div
                  class="
                    mb-7
                    font-weight-light
                    pr-xl-16 pr-lg-16 pr-md-16
                    dmsans
                    ml-xl-auto ml-lg-auto ml-md-auto
                    mr-xl-0 mr-lg-0 mr-md-0
                    mx-sm-auto mx-auto
                    text-center
                    text-sm-center
                    text-md-left
                    text-lg-left
                    text-xl-left
                  "
                >
                  <v-btn
                    style="font-size: 17px; color: white"
                    class="mt-4 pa-6 dmsans font-weight-bold"
                    elevation="0"
                    color="blue darken-4"
                    @click="contactFormDialog = true"
                  >
                    Request Demo
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <v-footer style="background-color: #0d47a1">
        <v-card
          flat
          tile
          width="100%"
          class="blue darken-4 text-center hidden-sm-and-down"
        >
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-row>
                  <div>
                    <v-img
                      alt="Truce Logo"
                      class="shrink"
                      contain
                      src="../assets/Truce_Logo_White.png"
                      width="140"
                      style="margin-right: 120px"
                    />
                  </div>
                </v-row>
                <v-row>
                  <div class="white--text">
                    &copy; 2021 Truce Tech, Inc. All Rights Reserved.
                  </div>
                </v-row>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn
                  style="margin-left: 120px; font-size: 16px"
                  class="mt-5 dmsans font-weight-bold"
                  elevation="0"
                  color="white"
                  text
                  @click="navigateToPrivacy()"
                >
                  Privacy
                </v-btn>
                <v-btn
                  style="margin-left: 10px; font-size: 16px"
                  class="mt-5 dmsans font-weight-bold"
                  elevation="0"
                  color="white"
                  text
                  @click="navigateToTerms()"
                >
                  Terms of Use
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row class="hidden-md-and-up">
          <v-col cols="12">
            <div class="mx-auto text-center">
              <v-btn
                style="font-size: 16px"
                class="mt-5 dmsans font-weight-bold"
                elevation="0"
                color="white"
                text
                @click="navigateToPrivacy()"
              >
                Privacy
              </v-btn>
            </div>
            <div class="mx-auto text-center">
              <v-btn
                style="font-size: 16px"
                class="mb-5 dmsans font-weight-bold"
                elevation="0"
                color="white"
                text
                @click="navigateToTerms()"
              >
                Terms of Use
              </v-btn>
              <div class="white--text">
                &copy; 2021 Truce Tech, Inc. All Rights Reserved.
              </div>
            </div>
          </v-col>
        </v-row>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import { API } from "aws-amplify";
export default {
  name: "Landing",
  data: function () {
    return {
      scrollPosition: null,
      btnColor_1: "white",
      logo_img: "Truce_Logo_White.png",
      contactFormDialog: false,
      name: "",
      company: "",
      email: "",
      comment: "",
      backToTop: false,
      backToTopMargin: "backToTop",
      platformImage: new Image(),
      trustImage: new Image(),
      stabilityImage: new Image(),
      networkImage: new Image(),
      contactUsImage: new Image(),
      contactFormValid: false,
      commentMaxLength: 250,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value && (value.length <= this.commentMaxLength || `Max ${this.commentMaxLength} characters`),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        alphanumeric: value => {
          const pattern = /^[a-zA-Z0-9\s]*$/
          return pattern.test(value) || 'Invalid characters. Only letters and numbers allowed.'
        },
      },
    };
  },
  methods: {
    updateScroll(e) {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition > document.documentElement.clientHeight - 50) {
        this.btnColor_1 = "black";
        this.logo_img = "Truce_Logo_Black.png";
      } else {
        this.btnColor_1 = "white";
        this.logo_img = "Truce_Logo_White.png";
      }

      if (this.scrollPosition < window.innerHeight * 5 - 50) {
        this.backToTopMargin = "backToTop";
      } else {
        this.backToTopMargin = "backToTop_Footer";
      }

      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.backToTop = top > 20;
    },
    navigateToApp() {
      this.$router.push({
        name: "app",
        params: { prop_usn: "Roop Pal", prop_toggle: "left" },
      });
    },
    navigateToPrivacy() {
      this.$router.push({
        name: "privacy",
      });
    },
    navigateToTerms() {
      this.$router.push({
        name: "terms",
      });
    },
    resetForm(){
      this.$refs.contactForm.reset();
    },
    validateForm(){
      return this.$refs.contactForm.validate();
    },
    sendEmail() {
      // only send email if form validation passes
      if (this.validateForm()) {
        const apiName = "Contact"; // replace this with your api name.
        const path = "/contact"; //replace this with the path you have configured on your API
        const myInit = {
          body: {
            name: this.name,
            company: this.company,
            email: this.email,
            comment: this.comment,
          }, // replace this with attributes you need
          headers: {}, // OPTIONAL
        };

        API.post(apiName, path, myInit)
          .then((response) => {
            console.log("DONE SENDING EMAIL");
          })
          .catch((error) => {
            console.log(error.response);
          });

        this.contactFormDialog = false;

        this.resetForm();
      }
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
  mounted() {
    this.toTop();

    window.addEventListener("scroll", this.updateScroll);

    this.platformImage.src = require("../assets/platform@2x.png");
    this.trustImage.src = require("../assets/trust@2x.png");
    this.stabilityImage.src = require("../assets/stability@2x.png");
    this.networkImage.src = require("../assets/score@2x.png");
    this.contactUsImage.src = require("../assets/contact img@2x.png");
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap");
.v-tab {
  text-transform: none !important;
}

.v-tab:hover {
  color: #536dfe !important;
  border-bottom: 2px solid #536dfe !important;
}

.rounded-card {
  border-radius: 5px !important;
}

.my-overlay >>> .v-overlay__content {
  width: 100%;
}

.change_color {
  color: #536dfe;
}

.bottom-gradient {
  background-image: linear-gradient(to bottom, rgb(255, 0, 0) 0%, 72px);
}

.dmsans {
  font-family: "DM Sans", sans-serif !important;
}
.backToTop {
  margin-bottom: 4.3vh;
}
.backToTop_Footer {
  margin-bottom: 17vh;
}
#logo:hover {
  cursor: pointer;
}
</style>
