<template>
  <v-navigation-drawer
    app
    clipped
    flat
    expand-on-hover
    class="pt-4"
    mini-variant-width="2.7em"
    :color="$vuetify.theme.themes[theme].sidebarBackground"
  >
    <v-list>
      <v-list-item-group v-model="model" :color="$vuetify.theme.themes[theme].headerIcon" :mandatory="sidebarMandatory">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          @click="menuActionClick(item.action)"
        >
          <v-list-item-icon>
            <v-icon :color="$vuetify.theme.themes[theme].iconColor">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <template v-slot:append>
      <v-list>
        <v-list-item-group v-model="modelEnd" :color="$vuetify.theme.themes[theme].headerIcon" :mandatory="!sidebarMandatory">
          <v-list-item
            v-for="(item, i) in itemsEnd"
            :key="i"
            @click="menuActionClick(item.action)"
          >
            <v-list-item-icon>
              <v-icon :color="$vuetify.theme.themes[theme].iconColor">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
    </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import * as stateAPI from "../stateAPI";

export default {
  name: "SideBar",

  computed: {
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light';
    }
  },

  data: function () {
    const navItems = [
      {
        icon: "mdi-lan-connect",
        text: "Broker Dashboard",
        action: "broker-dashboard",
        enabled:
        stateAPI.getStateProperty(this, "role") == "admin" ||
        stateAPI.getStateProperty(this, "role") == "shipper",
      },
      {
        icon: "mdi-warehouse",
        text: "Shipper Dashboard",
        action: "shipper-dashboard",
        enabled: stateAPI.getStateProperty(this, "role") == "broker",
      },
      {
        icon: "mdi-state-machine",
        text: "Lanes",
        action: "lane-dashboard",
        enabled: true,
      },
      {
        icon: "mdi-star",
        text: "Favorites",
        action: "favorites",
        enabled: true,
      },
      {
        icon: "mdi-google-analytics",
        text: "Network Analytics",
        action: "analytics",
        enabled: true,
      },
      {
        icon: "$calcSidebar",
        text: "Pricing Calculator",
        action: "calculator",
        enabled: stateAPI.getStateProperty(this, "calc_enabled"),
      },
      {
        icon: "mdi-bell",
        text: "Notifications",
        action: "notifications",
        enabled: true,
      },
      {
        icon: "mdi-chart-pie",
        text: "Scorecarding",
        action: "scorecard",
        enabled: stateAPI.getStateProperty(this, "user_id") == "0647c8dc-165c-44d2-9783-64570403a39e",
      },
      {
        icon: "mdi-plus",
        text: "FreightGPT",
        action: "chatstream",
        enabled: true,
      },
    ];

    const itemsEnd = [
      {
        icon: "mdi-cog",
        text: "Settings",
        enabled: true,
        action: "account"
      },
    ];

    var endModel = -1;
    const routeName = this.$route.name;
    const filteredNav = this.filterNav(navItems);
    const navModel = filteredNav.findIndex((item) => item.action === routeName);
    const sidebar_mandatory = navModel == -1 ? false : true;

    return {
      role: stateAPI.getStateProperty(this, "role"),
      items: filteredNav,
      model: navModel,
      itemsEnd: this.filterNav(itemsEnd),
      modelEnd: endModel,
      sidebarMandatory: sidebar_mandatory,
    };
  },

  methods: {
    filterNav(items) {
      return items.filter((item) => item.enabled);
    },

    menuActionClick(action) {
      // Assumes action is lane-dashboard or broker-dashboard.
      console.assert(
        action === "lane-dashboard" ||
          action === "broker-dashboard" ||
          action === "shipper-dashboard" ||
          action === "analytics" ||
          action === "notifications" ||
          action === "calculator" ||
          action === "scorecard" ||
          action === "account" ||
          action === "favorites",
        "action is ${action}, not lane-dashboard or broker dashboard or data-ingestion"
      );

      var params;
      if (action == "account") {
        params = {
          initPage: true
        }
      } else {
        params = { prop_usn: "Roop Pal" }
      }

      this.$router.push({
        name: action,
        params: params,
      });
    },
  },
};
</script>

<style scoped>
  .rotate-135 {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
</style>
