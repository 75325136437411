import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import Landing from './landing/Landing';
import Login from './containers/Login';
import ShipperDashboard from './containers/ShipperDashboard';
import Drilldown from './containers/DetailDrilldown';
import Notifications from './containers/Notifications';
import store from './store';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './authentication/aws-exports';
import Analytics from './containers/Analytics'
import Calculator from './containers/Calculator';
import Scorecard from './containers/Scorecard';
import VueApexCharts from 'vue-apexcharts';
import Privacy from './landing/Privacy';
import Terms from './landing/Terms';
import Account from './containers/Account';
import VueVectorMap from 'vuevectormap';
import LaneDrilldownPage from './containers/LaneDrilldownPage';
import Favorites from './containers/Favorites';
import PricingForm from './pricing/containers/PricingForm';
import ChatStream from './chat/containers/ChatStream';
import './us-map';

Amplify.configure({
  Auth: awsconfig,
  API: {
    endpoints: [
      {
        name: "SBUAccess",
        endpoint: "https://llvtzheyh4.execute-api.us-east-1.amazonaws.com/prod"
      },
      {
        name: "Contact",
        endpoint: "https://gkiuzzt1h8.execute-api.us-east-1.amazonaws.com/contactTest"
      },
      {
        name: "ResendTempPassword",
        endpoint: "https://jzbd0kolh3.execute-api.us-east-1.amazonaws.com/dev"
      }
    ]
  },
  Storage: {
    AWSS3: {
      bucket: 'broker-data-upload', //REQUIRED -  Amazon S3 bucket name
      region: 'us-east-1', //OPTIONAL -  Amazon service region
    }
  }
});


Vue.use(VueRouter)
Vue.use(VueApexCharts)
Vue.use(VueVectorMap)
Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false


const failGuard = function (to, next) {
  var nextParams = { path: "/app/login" }
  if (routes.filter(route => route.path == to.path)) {
    nextParams.query = { redirect: to.fullPath };
  }
  next(nextParams);
}

const guard = function (to, from, next) {
  Auth.currentAuthenticatedUser()
    .then(user => {
      // Authenticated user, continue
      next();
    })
    .catch(err => failGuard(to, next));
};

const routes = [
  { path: '/', name: 'landing', component: Landing },
  { path: '/app', name: 'app', component: Login },
  { path: '/app/login', component: Login },
  { path: '/privacy-policy', name: 'privacy', component: Privacy },
  { path: '/terms-of-use', name: 'terms', component: Terms },

  {
    name: 'lane-dashboard',
    path: '/app/laneDashboard',
    component: ShipperDashboard,
    props: true,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    }
  },

  {
    name: 'broker-dashboard',
    path: '/app/brokerDashboard',
    component: ShipperDashboard,
    props: true,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    }
  },

  {
    name: 'shipper-dashboard',
    path: '/app/shipperDashboard',
    component: ShipperDashboard,
    props: true,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    }
  },

  {
    name: 'drilldown',
    path: '/app/drilldown',
    component: Drilldown,
    props: true,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    }
  },

  {
    name: 'lanedrilldown',
    path: '/app/laneDrilldown',
    component: LaneDrilldownPage,
    props: true,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    }
  },

  {
    name: 'analytics',
    path: '/app/analytics',
    component: Analytics,
    props: true,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    }
  },

  {
    name: 'notifications',
    path: '/app/notifications',
    component: Notifications,
    props: true,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    }
  },

  {
    name: 'calculator',
    path: '/app/calculator',
    component: Calculator,
    props: true,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    }
  },

  {
    name: 'account',
    path: '/app/account',
    component: Account,
    props: true,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    }
  },

  {
    name: 'scorecard',
    path: '/app/scorecard',
    component: Scorecard,
    props: true,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    }
  },

  {
    name: 'favorites',
    path: '/app/favorites',
    component: Favorites,
    props: true,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    }
  },

  
  {
    name: 'pricing',
    path: '/pricing',
    component: PricingForm,
    props: true,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    }
  },  

  {
    name: 'chatstream',
    path: '/chatstream',
    component: ChatStream,
    props: true,
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    }
  },
]

const router = new VueRouter({
  mode: "history",
  routes
})

new Vue({
  router,
  vuetify,
  store: store,
  beforeRouteUpdate(to) {
    this.name = to.params.name
  },
  render: h => h(App)
}).$mount('#app')
