<template>
<v-app style="background-color: #f5f8ff">
    <NavBar :prop_username="usn" />
    <SideBar />
    <v-main>
    <v-container fluid style="width: 87%" gx-2>
        <v-row>
        <v-breadcrumbs
            ref="breadcrumbs"
            :items="crumbs"
            divider=">"
            large
            class="ma-0 pa-2"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-col>
            <p
            class="text-end mb-0 pt-4 mr-4 font-weight-light"
            style="color: grey"
            >
            {{ periodDates }}
            </p>
        </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card elevation="5">
                <v-card-title class="font-weight-light">
                    <v-icon large color="blue darken-4" class="mr-4"
                    >mdi-chart-pie</v-icon
                    >Carrier Scoring
                    <v-spacer></v-spacer>
                </v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <!-- Cards -->
        <v-row style="height: 30%">
            <v-col cols="3" v-for="item in cellCards" :key="item.key">
                <v-card elevation="5" style="height: 100%">
                    <v-card-title class="cellCard justify-center text-h2 pt-6">
                        {{ item.value }}
                    </v-card-title>
                    <v-card-subtitle class="text-center">
                        {{ item.name }}
                    </v-card-subtitle>
                    <v-card-text class="text-center">
                        <v-chip label
                            color="green lighten-4"
                            text-color="green darken-2"
                        >
                        Top Carrier: {{ item.top}}
                        </v-chip>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- Donut and Volume graph -->
        <v-row ma-0>
            <v-col cols="4">
                <v-card elevation="5" style="height: 100%">
                <v-card-title>
                    Carrier Rank Composition
                </v-card-title>
                <v-card-text>
                    <apexchart
                        type="donut"
                        height="140%"
                        :options="donutOptions"
                        :series="donutSeries"
                    ></apexchart>
                </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="8">
                <v-card elevation="5" >
                <v-card-title>
                    Volume
                </v-card-title>
                <v-card-text>
                    <Graph
                        graphName="scorecardVolume"
                        :rawData="volumeSeries"
                        :timeRange="timePeriod"
                        :isBroker="isBrokerUser"
                    ></Graph>
                </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- Table -->
        <v-row>
            <v-col cols="12">
                <v-card elevation="5" style="height: 100%;">
                    <v-card-title>
                        <v-row>
                            <v-col>Carrier Table</v-col>
                            <v-spacer></v-spacer>
                            <v-col>
                                <v-text-field
                                    v-model="searchTable"
                                    label="Search Carriers"
                                    hide-details
                                    single-line
                                    class="pr-2 pb-3"
                                    style="margin-top: -1px; width: 500px;"
                                    clearable
                                    >
                                    <template v-slot:prepend-inner>
                                        <v-icon>mdi-magnify</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="tableRecords"
                        item-key="name"
                        :footer-props="{
                        'items-per-page-options': [5, 10, 20, 50, 100],
                        }"
                        :search="searchTable"
                        :items-per-page="20"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        @update:options="handleUpdatedOptions"
                        server-items-length.sync="2"
                    >
                        <template v-slot:header.name="{ header }">
                            {{ header.text }}
                        </template>
                        <template v-slot:header.is_asset="{ header }">
                            {{ header.text }}
                        </template>
                        <template v-slot:header.total_spend="{ header }">
                            {{ header.text }}
                        </template>
                        <template v-slot:header.otp_first="{ header }">
                            {{ header.text }}
                        </template>
                        <template v-slot:header.otp_final="{ header }">
                            {{ header.text }}
                        </template>
                        <template v-slot:header.otd_first="{ header }">
                            {{ header.text }}
                        </template>
                        <template v-slot:header.otd_final="{ header }">
                            {{ header.text }}
                        </template>
                        <template v-slot:header.volume="{ header }">
                            {{ header.text }}
                        </template>

                        <template v-slot:item.carrier_name="{ item }">
                            <div class="pl-16 d-flex justify-start">{{ formatName(item.carrier_name) }}</div>
                        </template>
                        <template v-slot:item.asset_type="{ item }">
                            <div class="ml-n2 mr-2">
                                <v-chip label v-if="item.asset_type == 'ASSET'"
                                    color="purple lighten-4"
                                    text-color="purple darken-2"
                                    class="font-weight-bold text-body-1-xs"
                                    style="height: 20px; font-size:12px;">ASSET</v-chip>
                                <v-chip label small v-else-if="item.asset_type == 'NON-ASSET'"
                                    color="blue lighten-4"
                                    text-color="blue darken-2"
                                    class="font-weight-bold text-body-1-xs"
                                    style="height: 20px; font-size:12px;">NON-ASSET</v-chip>
                                    <v-chip label small v-else
                                    color="grey lighten-2"
                                    text-color="grey darken-2"
                                    class="font-weight-bold text-body-1-xs"
                                    style="height: 20px; font-size:12px;">NA</v-chip>
                            </div>
                        </template>

                        <template v-slot:item.total_spend="{ item }">
                            {{ formatDollars(item.total_spend) }}
                        </template>
                        <template v-slot:item.avg_otp_plan="{ item }">
                            {{ formatPercent(item.avg_otp_plan) }}
                        </template>
                        <template v-slot:item.avg_otp="{ item }">
                            {{ formatPercent(item.avg_otp) }}
                        </template>
                        <template v-slot:item.avg_otd_plan="{ item }">
                            {{ formatPercent(item.avg_otd_plan) }}
                        </template>
                        <template v-slot:item.avg_otd="{ item }">
                            {{ formatPercent(item.avg_otd) }}
                        </template>
                        <template v-slot:item.volume="{ item }">
                            {{ formatNumber(item.volume) }}
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    </v-main>
    <SplashScreen :is_loading="isLoading" />
</v-app>
</template>
<script>
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import Graph from "../components/Graph";
import SplashScreen from "../components/SplashScreen";
import * as globalVariables from "../globalVariables";
import * as fetchScorecarding from "../fetchScorecarding";
import * as stateAPI from "../stateAPI";
import * as user_analytics from "../analytics/sendAnalyticsEvent";
import * as format from "../formatShipmentData";
import * as utils from "../utils";
export default {
name: "Scorecard",

components: { NavBar, SideBar, Graph, SplashScreen },

props: ["prop_usn"],

methods: {
    formatDollars: format.formatDollars,
    formatPercent: format.formatPercent,
    formatName: format.formatName,
    formatNumber: format.formatNumber,
    handleUpdatedOptions(options) {
        this.pageSize = options.itemsPerPage;
        this.pageNumber = options.page - 1;

        if (options.sortBy.length > 0) {
            this.sortColumn = options.sortBy[0];
            this.sortDirection = options.sortDesc[0] ? "DESC" : "ASC";
        } else {
            this.sortColumn = "volume";
            this.sortDirection = "ASC";
        }

        //this.$forceUpdate();
    },

    async getScorecardData() {
        // TODO setup once query is built
        await fetchScorecarding.fetchScorecardData(this.query_params)
        .then((result) => {
            this.tableRecords = result.carrier_table;
            this.cellCards.forEach(card => {
                if (card.key == "avg_otd") {
                    card.top = this.formatName(result.average_otd[0].name);
                    card.value = this.formatPercent(result.average_otd[0].value);
                    card.bottom = this.formatName(result.average_otd[1].name);
                } else if (card.key == "avg_otp") {
                    card.top = this.formatName(result.average_otp[0].name);
                    card.value = this.formatPercent(result.average_otp[0].value);
                    card.bottom = this.formatName(result.average_otp[1].name);
                } else if (card.key == "total_spend") {
                    card.top = this.formatName(result.total_spend[0].name);
                    card.value = this.formatDollars(result.total_spend[0].value);
                    card.bottom = this.formatName(result.total_spend[1].name);
                } else if (card.key == "avg_otd_plan") {
                    card.top = this.formatName(result.average_otd_plan[0].name);
                    card.value = this.formatPercent(result.average_otd_plan[0].value);
                    card.bottom = this.formatName(result.average_otd_plan[1].name);
                }
            })
            this.volumeSeries = result.volume_per_day;

            result.carrier_rank.forEach(item => {
                if (item.carrier_rank.toLowerCase() == "contract") {
                    this.donutSeries.splice(0, 1, item.volume);
                } else if (item.carrier_rank.toLowerCase() == "manual") {
                    this.donutSeries.splice(1, 1, item.volume);
                } else if (item.carrier_rank.toLowerCase() == "real-time api") {
                    this.donutSeries.splice(2, 1, item.volume);
                } else if (item.carrier_rank.toLowerCase() == "spotmarket") {
                    this.donutSeries.splice(3, 1, item.volume);
                }
            })
        })
        this.isLoading = false
    }
},

beforeMount() {
    if (stateAPI.getStateProperty(this, "user_id") == "0647c8dc-165c-44d2-9783-64570403a39e") {
        this.getScorecardData();
        this.crumbs = [
            {
                text: "Carrier Scorecard",
                disabled: true,
                href: new URL(window.location.href).pathname,
            },
        ];
        stateAPI.setStateProperty(this, "crumbs", this.crumbs);


        // Send pageView analytics event
        const email = stateAPI.getStateProperty(this, "email");
        const company = stateAPI.getStateProperty(this, "company");
        const role = stateAPI.getStateProperty(this, "role");
        user_analytics.sendEvent(email, company, role, "pageView", { pageSource: "Scorecard" });
    }
},

data: function () {
    return {
    query_params: {},
    endDate: new Date(),
    selectedEquipmentTypes: stateAPI.getStateProperty(
        this,
        "equipment_type_list"
    ),
    // EQUIPMENT_TYPE_LIST: globalVariables.default.equipment_type_list,
    EQUIPMENT_TYPE_LIST: stateAPI.getStateProperty(
        this,
        "equipment_type_list"
    ),
    index_by: "brokerId",
    usn: this.prop_usn,
    date: new Date().toISOString().substring(0, 10),
    periodDates: "",
    oldPeriodDates: "",
    filterChips: new Map(),
    volumeThreshold: 0,
    startDate: new Date(stateAPI.getStateProperty(this, "startDate")),
    includeAcc: 0,
    timePeriod: null,
    tickLabels: ["90", "60", "30", "7", "1"],
    // this may need to change
    isLaneData: false,
    isBrokerUser: stateAPI.getStateProperty(this, "role") == "broker",
    crumbs: [
        {
        text: "Carrier Scorecard",
        disabled: true,
        href: new URL(window.location.href).pathname,
        },
    ],
    filters: {
        dates: null,
        timeSlider: 5,
        volume: 0,
        equipment: stateAPI.getStateProperty(this, "equipment_type_list"),
    },

    // Table
    headers: globalVariables.default.headers_scorecard,
    pageSize: 20,
    pageNumber: 0,
    sortColumn: "total_spend",
    sortDirection: "ASC",
    sortBy: "total_spend",
    sortDesc: true,
    searchTable: null,

    // Donut
    donutOptions: {
        labels: ["Contract", "Manual", "Real-Time API", "Spot Market"],
        dataLabels: {
            enabled: true,
            dropShadow: {
                enabled: false
            }
        },
        legend: {
            show: true,
            position: "right",
            offsetY: 75
        },
    },
    donutSeries: [0, 0, 0, 0],

    // Volume graph
    volumeOptions: {
        chart: {
            type: 'area',
            height: 350
        },

    },
    volumeSeries: null,

    cellCards: [
        {
            name: "Total Spend",
            key: "total_spend",
        },
        {
            name: "OTP (Final)",
            key: "avg_otp",
        },
        {
            name: "OTD (Final)",
            key: "avg_otd",
        },
        {
            name: "Del Plan Date",
            key: "avg_otd_plan",
        },
    ],

    tableRecords: [],

    isLoading: true,
    };
},
};
</script>

<style scoped>
.cellCard {
    background-color: transparent !important;
}
.row {
    margin: 0px !important;
    padding: 6px !important;
}
.col {
    padding-left: 6px !important;
    padding-right: 6px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.carrierName {
    padding-left: 78px !important;
}
</style>
